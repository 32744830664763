import {Radio} from "antd";
import {useState} from "react";

/**
  * The data type definition of each `item` of the array {@link RadioGroupPropsOptions}.
  * @typedef {Object} RadioGroupPropsOptionsItem
  * @property {String} label The `label` of each `Radio` element that is present in the `Radio.Group` react component.
  * @property {Number|String|JSONStringify} value The 'value' of each `Radio` element that is present in
  * the `Radio.Group` react component.
  */

/**
  * The data type definition for the `options` property of a {@link RadioGroupProps} object.
  * @typedef {Array<RadioGroupPropsOptionsItem>} RadioGroupPropsOptions
  */

/**
  * The data type definition of argument passed to {@link RadioGroup} react component.
  * @typedef {Object} RadioGroupProps
  * @property {String} defaultValue The `defaultValue` property of a `Radio.Group` component.
  * @property {RadioGroupPropsOptions} options The `options` property of a `Radio.Group` component.
  * @property {Function} setLastControlEvent The function passed to {@link RadioGroup} that updates the
  * `lastControlEvent` state of the react component that contains the map.
  * @property {String} controlName The name of the control.
  */

/**
  * Creates a `Radio.Group` react component to be used by custom map controls. This component visualizes
  * several options from which the user can select one. After a selection is made, the component updates the state
  * `lastControlEvent` in the react component that contains the map and this way the user interaction is managed.
  * @param {RadioGroupProps} props The argument passed to the {@link RadioGroup} component.
  * @return {Radio.Group} A `Radio.Group` react component.
*/
const RadioGroup = (props) => {

  const {
    options,
    defaultValue,
    setLastControlEvent,
    controlName
  } = props;

  const [value, setValue] = useState(defaultValue);

  const handleChange = (e) => {

    const value = e.target.value;

    setValue(value);

    if (setLastControlEvent !== null) setLastControlEvent(
        {controlName: controlName, data: value});
  };

  return (
    <Radio.Group
      style={{margin: '5px 5px 5px 5px'}}
      onChange={handleChange}
      value={value}
      options={options}
    />
  )
};

export {RadioGroup};


