import {PATH_ACTION_TYPES} from "../typesAction";


const initialState = {
    paths: [],
    current: null,
    loading: false,
    error: null
}

const pathReducer = (state = initialState, action) => {
    switch (action.type) {
        case PATH_ACTION_TYPES.LOAD_PATH:
            return {
                ...state, loading: true
            };
        case PATH_ACTION_TYPES.CLEAR_LOAD_PATH:
            return {
                ...state, loading: false
            };
        case PATH_ACTION_TYPES.ERROR_PATH:
            return {
                ...state, error: action.payload, loading: false, current: null
            };
        case PATH_ACTION_TYPES.CLEAR_PATH_ERRORS:
            return {
                ...state,
                error: null, loading: false
            };
        case PATH_ACTION_TYPES.LIST_PATH:
            return {
                ...state, paths: action.payload, loading: false, current: null
            };
        case PATH_ACTION_TYPES.ADD_PATH:
            return {
                ...state,
                loading: false
            };
        case PATH_ACTION_TYPES.SHOW_PATH:
            return {
                ...state,
                current: action.payload,
                loading: false
            };
        case PATH_ACTION_TYPES.EDIT_PATH:
            return {
                ...state,
                current: action.payload,
                loading: false
            };
        case PATH_ACTION_TYPES.DELETE_PATH:
            const updatedPaths = state.paths.paths.filter(path => action.payload !== path.id);
            const updatedCount = state.paths.count - 1;
            return {
                ...state, paths: {...state.paths, paths: updatedPaths, count: updatedCount}, loading: false
            };
        case PATH_ACTION_TYPES.ACTIVATE_PATH:
            return {
                ...state, paths: {...state.paths}, loading: false
            };
        default:
            return state;
    }

};

export default pathReducer;