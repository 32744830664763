import MapboxGLMapInstance2 from './MapboxGLMapInstance2';
import {getPopupData} from "../../../utilData";
import { selectColorsSimple } from './selectColors';

/**
  * Customization of {@link MapboxGLMapInstanceChoropleth2#map|mapboxgl.Map class' instance}
  as a Choropleth map.
  * @extends MapboxGLMapInstance2
  */

class MapboxGLMapInstanceChoropleth2 extends MapboxGLMapInstance2{

    /**
      * GeoJSON geometry object of type MultiPolygon.
      * @typedef {Object} GeoJSONGeometryMultiPolygon
      * @example
        * {
        *	 "type": "MultiPolygon",
        *	 "coordinates": [
        *		 [
        *			 [
        *				 [
        *					 -68.939942644,
        *					 12.14897302
        *				 ],
        *				 [
        *					 -68.942490942,
        *					 12.149150592
        *				 ],
        *				 [
        *					 -68.942367699,
        *					 12.150637861
        *				 ],
        *				 [
        *					 -68.942916291,
        *					 12.150895422
        *				 ],
        *				 [
        *					 -68.943093606,
        *					 12.152322984
        *				 ],
        *				 [
        *					 -68.941439426,
        *					 12.152273216
        *				 ],
        *				 [
        *					 -68.941456331,
        *					 12.152780089
        *				 ],
        *				 [
        *					 -68.941194703,
        *					 12.152763486
        *				 ],
        *				 [
        *					 -68.939908551,
        *					 12.152489982
        *				 ],
        *				 [
        *					 -68.939125815,
        *					 12.15178639
        *				 ],
        *				 [
        *					 -68.938944298,
        *					 12.151306146
        *				 ],
        *				 [
        *					 -68.939942482,
        *					 12.150245089
        *				 ],
        *				 [
        *					 -68.939942644,
        *					 12.14897302
        *				 ]
        *			 ]
        *		 ]
        *	 ]
        * }
      * @see Find detailed information on {@link https://www.rfc-editor.org/rfc/rfc7946#section-3.1.7|MultiPolygon Geometry GeoJSON object}.
      */

    /**
      * JSONStringify is a string data type that represents the return value of function `JSON.stringify()`.
      * @typedef {String} JSONStringify
      */
    /**
      * DataRecordsGeoJSON data type specification.
      *
      * @typedef {Object} DataRecordsGeoJSON
      * @property {JSONStringify} value The `JSON.stringify()` value of an object of {@link GeoJSONGeometryMultiPolygon}
      * type.
      * @property {String} type "jsonb"
      * @property {Boolean} null "false"
      * @example
        *
        * const dataRecordsGeoJSON = {
        *   "value": "{\"type\": \"MultiPolygon\", \"coordinates\": [[[[-68.939942644, 12.14897302], [-68.942490942, 12.149150592], [-68.942367699, 12.150637861], [-68.942916291, 12.150895422], [-68.943093606, 12.152322984], [-68.941439426, 12.152273216], [-68.941456331, 12.152780089], [-68.941194703, 12.152763486], [-68.939908551, 12.152489982], [-68.939125815, 12.15178639], [-68.938944298, 12.151306146], [-68.939942482, 12.150245089], [-68.939942644, 12.14897302]]]]}",
        *   "type": "jsonb",
        *   "null": false
        * };
      */

    /**
      * Data sent by the server that correspond to a
      * {@link MapboxGLMapInstanceChoropleth2#ConfigEditDataSeries|`dataSeries` item}
      * for Choropleth map.
      *
      * It must contain at least one  `variableAlias`'s property.
      *
      * @typedef {Object} DataRecords
      * @property {String} code Geographical region code.
      * @property {String} name Geographical region name.
      * @property {DataRecordsGeoJSON} geojson Object that represents the geographical region's geometry of type {@link GeoJSONGeometryMultiPolygon}.
      * @property {Number} variableAlias1 Alias for variable 1.
      * @property {Number} variableAlias2 Alias for variable 2.
      * @property {Number} variableAliasN Alias for other possible variable.
      * @example
        * {
        * 	"code": "9995305048",
        * 	"name": "EENDRACHT",
        * 	"geojson": {
        * 		"value": "{\"type\": \"MultiPolygon\", \"coordinates\": [[[[-68.939942644, 12.14897302], [-68.942490942, 12.149150592], [-68.942367699, 12.150637861], [-68.942916291, 12.150895422], [-68.943093606, 12.152322984], [-68.941439426, 12.152273216], [-68.941456331, 12.152780089], [-68.941194703, 12.152763486], [-68.939908551, 12.152489982], [-68.939125815, 12.15178639], [-68.938944298, 12.151306146], [-68.939942482, 12.150245089], [-68.939942644, 12.14897302]]]]}",
        * 		"type": "jsonb",
        * 		"null": false
        * 	}
        * 	"Cont.w/Larvae Indice": 0,
        * 	"Cont.w/water indice": 0.11,
        * 	"Cont. Treated indice": 0.09,
        * }
      */

    /**
      *
      * @typedef {Object} DataSeriesVariable
      * @property {String} function
      * @property {String} variables
      * @property {String} alias
      */

    /**
      *
      * @typedef {Object} DataSeriesCondition
      * @property {String} field
      * @property {Array<String>} operator
      * @property {String} valueType
      */

    /**
      *
      * @typedef {Object} PopupVariable
      * @property {String} popupVariable The normalized name of variable.
      * @property {String} popupVariableAlias The alias of variable.
      * @property {String} popupVariableFunction The function applied to variable.
      * @example
        *{
        *	"popupVariable": "joghsqgtip_3704132170",
        *	"popupVariableAlias": "Cont. w/water Indice",
        *	"popupVariableFunction": "index_one_var"
        *}
      */

    /**
      *
      * @typedef {Object} DataSeriesPopup
      * @property {String} title
      * @property {Array<PopupVariable>} popupVariables
      * @example
        *{
        *	"title": "Neighbourhood Data",
        *	"popupVariables": [
        *		{
        *			"popupVariable": "zlnxhbszms_7226725094",
        *			"popupVariableAlias": "Cont. Indice",
        *			"popupVariableFunction": "index_one_var"
        *		},
        *		{
        *			"popupVariable": "joghsqgtip_3704132170",
        *			"popupVariableAlias": "Cont. w/water Indice",
        *			"popupVariableFunction": "index_one_var"
        *		},
        *		{
        *			"popupVariable": "oqofmcerdr_4564981581",
        *			"popupVariableAlias": "Cont.w/Larvae indice",
        *			"popupVariableFunction": "index_one_var"
        *		},
        *		{
        *			"popupVariable": "mmkoavsoly_9219334559",
        *			"popupVariableAlias": "Cont. treated Indice",
        *			"popupVariableFunction": "index_one_var"
        *		}
        *	]
        *}
      */

    /**
      * Represents the configuration of a given data series.
      * @typedef {Object} ConfigEditDataSeries
      * @property {Number} dataZource
      * @property {Array<DataSeriesVariable>} variables
      * @property {Array<String>} filterBy
      * @property {Array<DataSeriesCondition>} conditions
      * @property {DataSeriesPopup} popup
      * @example
        *{
        *	"popup": {
        *		"title": "Neighbourhood Data",
        *		"popupVariables": [
        *			{
        *				"popupVariable": "zlnxhbszms_7226725094",
        *				"popupVariableAlias": "Cont. Indice",
        *				"popupVariableFunction": "index_one_var"
        *			},
        *			{
        *				"popupVariable": "joghsqgtip_3704132170",
        *				"popupVariableAlias": "Cont. w/water Indice",
        *				"popupVariableFunction": "index_one_var"
        *			},
        *			{
        *				"popupVariable": "oqofmcerdr_4564981581",
        *				"popupVariableAlias": "Cont.w/Larvae indice",
        *				"popupVariableFunction": "index_one_var"
        *			},
        *			{
        *				"popupVariable": "mmkoavsoly_9219334559",
        *				"popupVariableAlias": "Cont. treated Indice",
        *				"popupVariableFunction": "index_one_var"
        *			}
        *		]
        *	},
        *	"filterBy": [
        *		"karutnyixo_9588928693"
        *	],
        *	"variables": [
        *		{
        *			"alias": "Cont.w/Larvae Indice",
        *			"function": "index_one_var",
        *			"variables": "oqofmcerdr_4564981581"
        *		},
        *		{
        *			"alias": "Cont.w/water indice",
        *			"function": "index_one_var",
        *			"variables": "joghsqgtip_3704132170"
        *		},
        *		{
        *			"alias": "Cont. Treated indice",
        *			"function": "index_one_var",
        *			"variables": "mmkoavsoly_9219334559"
        *		}
        *	],
        *	"conditions": [
        *		{
        *			"field": "karutnyixo_9588928693",
        *			"operator": [
        *				"IN"
        *			],
        *			"valueType": "select"
        *		}
        *	],
        *	"dataZource": 1
        *}
      */

    /**
      *
      * @typedef {Object} ConfigEditMap
      * @property {String} borderColor
      * @property {Number} borderWidth
      * @property {Boolean} bordersIncluded
      * @property {Array<JSONStringify>} colorSchemes
      * @property {String} colorSchemesMode
      * @property {JSONStringify} defaultColorScheme
      * @property {String} defaultLayer
      * @property {String} defaultStyle It takes the value associated to the key `value` of {@link MAPBOX_STYLES} object.
      * @property {Number} geometryAccuracy
      * @property {String} legendTitle
      * @property {Boolean} showBorders
      * @property {Boolean} showToUser
      * @property {Array<String>} styles
      * @example
        *{
        *	"styles": [
        *		"satellite-streets"
        *	],
        *	"showToUser": true,
        *	"borderColor": "#f8e71c",
        *	"borderWidth": 2,
        *	"legendTitle": "Indice",
        *	"showBorders": true,
        *	"colorSchemes": [
        *		"{\"natureOfData\":\"sequential\",\"colorScheme\":{\"label\":\"YellowOrangeRed\",\"name\":\"9-class-YlOrRd\",\"scale\":[\"#ffffcc\",\"#ffeda0\",\"#fed976\",\"#feb24c\",\"#fd8d3c\",\"#fc4e2a\",\"#e31a1c\",\"#bd0026\",\"#800026\"],\"url\":\"https://colorbrewer2.org/#type=sequential&scheme=YlOrRd&n=9\",\"img\":\"9-class-YlOrRd.svg\"}}"
        *	],
        *	"defaultLayer": "Cont.w/Larvae Indice",
        *	"defaultStyle": "satellite-streets",
        *	"bordersIncluded": true,
        *	"colorSchemesMode": "auto",
        *	"geometryAccuracy": 0.0001,
        *	"defaultColorScheme": "{\"natureOfData\":\"sequential\",\"colorScheme\":{\"label\":\"YellowOrangeRed\",\"name\":\"9-class-YlOrRd\",\"scale\":[\"#ffffcc\",\"#ffeda0\",\"#fed976\",\"#feb24c\",\"#fd8d3c\",\"#fc4e2a\",\"#e31a1c\",\"#bd0026\",\"#800026\"],\"url\":\"https://colorbrewer2.org/#type=sequential&scheme=YlOrRd&n=9\",\"img\":\"9-class-YlOrRd.svg\"}}"
        *}
      */

    /**
      *
      * @typedef {Object} ConfigEditLocations
      * @property {} 
      */

    /**
      *
      * @typedef {Object} ConfigEdit
      * @property {ConfigEditMap} map
      * @property {ConfigEditLocations} locations
      * @property {Array<ConfigEditDataSeries>} dataSeries
      * @example
        *{
        *    "map": {
        *        "styles": [
        *            "satellite-streets"
        *        ],
        *        "showToUser": true,
        *        "borderColor": "#f8e71c",
        *        "borderWidth": 2,
        *        "legendTitle": "Indice",
        *        "showBorders": true,
        *        "colorSchemes": [
        *            "{\"natureOfData\":\"sequential\",\"colorScheme\":{\"label\":\"YellowOrangeRed\",\"name\":\"9-class-YlOrRd\",\"scale\":[\"#ffffcc\",\"#ffeda0\",\"#fed976\",\"#feb24c\",\"#fd8d3c\",\"#fc4e2a\",\"#e31a1c\",\"#bd0026\",\"#800026\"],\"url\":\"https://colorbrewer2.org/#type=sequential&scheme=YlOrRd&n=9\",\"img\":\"9-class-YlOrRd.svg\"}}"
        *        ],
        *        "defaultLayer": "Cont.w/Larvae Indice",
        *        "defaultStyle": "satellite-streets",
        *        "bordersIncluded": true,
        *        "colorSchemesMode": "auto",
        *        "geometryAccuracy": 0.0001,
        *        "defaultColorScheme": "{\"natureOfData\":\"sequential\",\"colorScheme\":{\"label\":\"YellowOrangeRed\",\"name\":\"9-class-YlOrRd\",\"scale\":[\"#ffffcc\",\"#ffeda0\",\"#fed976\",\"#feb24c\",\"#fd8d3c\",\"#fc4e2a\",\"#e31a1c\",\"#bd0026\",\"#800026\"],\"url\":\"https://colorbrewer2.org/#type=sequential&scheme=YlOrRd&n=9\",\"img\":\"9-class-YlOrRd.svg\"}}"
        *    },
        *    "locations": {
        *        "geoRegions_idLevel": {
        *            "idLevel": 1,
        *            "regionCode": "999530"
        *        },
        *        "geoRegions_levelUp": "999",
        *        "geoRegions_regions": [
        *            "9995305"
        *        ],
        *        "geoRegions_selectModeRegions": "selection",
        *        "subGeoRegions_subRegions_idLevels": [
        *            "{\"regionCode\":\"9995305\",\"idLevel\":2}"
        *        ]
        *    },
        *    "dataSeries": [
        *        {
        *            "popup": {
        *                "title": "Neighbourhood Data",
        *                "popupVariables": [
        *                    {
        *                        "popupVariable": "zlnxhbszms_7226725094",
        *                        "popupVariableAlias": "Cont. Indice",
        *                        "popupVariableFunction": "index_one_var"
        *                    },
        *                    {
        *                        "popupVariable": "joghsqgtip_3704132170",
        *                        "popupVariableAlias": "Cont. w/water Indice",
        *                        "popupVariableFunction": "index_one_var"
        *                    },
        *                    {
        *                        "popupVariable": "oqofmcerdr_4564981581",
        *                        "popupVariableAlias": "Cont.w/Larvae indice",
        *                        "popupVariableFunction": "index_one_var"
        *                    },
        *                    {
        *                        "popupVariable": "mmkoavsoly_9219334559",
        *                        "popupVariableAlias": "Cont. treated Indice",
        *                        "popupVariableFunction": "index_one_var"
        *                    }
        *                ]
        *            },
        *            "filterBy": [
        *                "karutnyixo_9588928693"
        *            ],
        *            "variables": [
        *                {
        *                    "alias": "Cont.w/Larvae Indice",
        *                    "function": "index_one_var",
        *                    "variables": "oqofmcerdr_4564981581"
        *                },
        *                {
        *                    "alias": "Cont.w/water indice",
        *                    "function": "index_one_var",
        *                    "variables": "joghsqgtip_3704132170"
        *                },
        *                {
        *                    "alias": "Cont. Treated indice",
        *                    "function": "index_one_var",
        *                    "variables": "mmkoavsoly_9219334559"
        *                }
        *            ],
        *            "conditions": [
        *                {
        *                    "field": "karutnyixo_9588928693",
        *                    "operator": [
        *                        "IN"
        *                    ],
        *                    "valueType": "select"
        *                }
        *            ],
        *            "dataZource": 1
        *        }
        *    ]
        *}
        *
      */

    /**
      *
      * @typedef {Object} ConfigView
      * @property {} 
      */

    /**
      *
      * @typedef {Object} DataRegions
      * @property {} 
      */

    /**
      * Represents the response of backend that contains information for plotting analysis.
      * @typedef {Object} RawData
      * @property {String} title The map's title.
      * @property {Array<DataRecords>} data An array whose items contain the {@link DataRecords} that correspond to each {@link ConfigEditDataSeries}.
      * @property {ConfigEdit} configEdit
      * @property {ConfigView} configView
      * @property {DataRegions} dataRegions
      * @property {Array} errors The errors found by backend when processing the request.
      * @property {Array<String>} query An array whose items represents the `sql query` used by the request for each {@link ConfigEditDataSeries}.
      * @property {String} queryRegions `Sql query` used to get more information from geographical regions. It is used for popups.
      */

    /**
      * Argument used by method {@link MapboxGLMapInstanceChoropleth2#setParams|setParams}.
      *
      * @typedef {Object} ParamsArgChoropleth
      * @property {RawData} rawData See {@link RawData}.
      * @property {Number} mapPlotHeight The `height` of `div` html element that contains the map.
      * @property {Function} setLastControlEvent React `useState` hook passed to set state `lastControlEvent`.
      * @property {useIntl} intl React `useIntl` hook.
      * @property {Number} analysisId Data analysis id.
      * @property {GeoJSON} boundsUser User geographical area.
      * @property {Function} setIsMapLoaded React `useState` hook passed to set state `isMapLoaded`.
      */

    /**
      * Class' constructor.
      * @constructor
      * @param {ParamsArgChoropleth} paramsArg The argument used to set
      * {@link MapboxGLMapInstanceChoropleth2#params|params}.
      */
    constructor (paramsArg) {

        const constArg = {
            intl: paramsArg.intl
        };

        super(constArg);

        this._setArgs(paramsArg);

        this._setParams();

        super.initMap();

        this.map.setMapInstanceData({
            hasPopup: this._hasPopup,
            popup: this.params.popup,
            analysisId: this._args.general.analysisId,
            analysisType: this._args.general.analysisType,
            mapboxgl: this._mapboxgl,
            getPopupDOMContent: this._getPopupDOMContent,
            setValuePopupQuery: this._setValuePopupQuery
        });

         this._registerMapEvents(['load']);

         this._setMessagesList();
    };

    /**
      * Creates a {@link GeoJSON} object to be used to set the `data` property of the {@link GeoJsonSource} object.
      *
      * @param {Array.<ConfigEditDataSeries>} this._args.argsGeoJsonSourceData.dataSeries The value of the
      * `dataSeries` property of {@link ConfigEdit} object.
      * @param {Array.<DataRecords>} this._args.argsGeoJsonSourceData.dataRecords The value of the `data` property
      * of {@link RawData} object.
      * @private
    */
    _setArgsGeoJsonSourceData() {
        //setting sourceData ----------------------
        let features = [];
        this._args.argsGeoJsonSourceData.dataSeries.forEach((seriesItem, seriesIndex) => {

            const variables = seriesItem.variables;

            const popup = seriesItem?.popup;

            //popup info
            this.params.popup.data.push(
                popup ? popup : null
            );

            const seriesItemDataRecords = this._args.argsGeoJsonSourceData.dataRecords[seriesIndex];

            variables.forEach(variable => {

                const variableAlias = variable.alias;
                const isRequiredTypeInteger = variable?.requiredType === 'integer';

                seriesItemDataRecords.forEach(dataRecord => {

                    const geometry = dataRecord['geojson']?.value ? JSON.parse(dataRecord['geojson'].value) : null;

                    const properties = {
                        variableAlias: variableAlias,
                        value: isRequiredTypeInteger
                            ? Math.round(dataRecord[variableAlias]) //adjust data type according to requiredType
                            : dataRecord[variableAlias],
                        popupDataIndex: seriesIndex,
                        dataId: dataRecord['code']
                    };

                    const feature = this._turf.feature(geometry, properties);

                    features.push(feature);

                });
            });
        });

        this._args.geoJsonSource = {

            data: this._turf.featureCollection(features)

        };

    };

    /**
        * Recursive function to format an array of numerical values into an array of strings representing the
        numerical values as fixed-point numbers with a calculated number of decimal places. The number of
        decimal places is the minimum number of decimal places that makes all formatted numbers different from
        each other.
        * Describing the logic of this function. The recursive process begins with the parameter `toFixed` = 0.
        The recursive process stops when the length of the array of formatted values is equal to the length of
        the array of the unique formatted values and when the numerical value of the minimum of the formatted values
        is not greater than the numerical value of the minimum of the `values` parameter.
        * @param {Array<Number>} valuesRaw The numeric values that are going to be formatted.
        * @param {Number} toFixed An integer value. The number of decimal places. Initial
        value is `0`. Its value is increased by `1` in each step of the recursive process.
        * @param {Boolean} nextDigitRequired A logical value to stop the recursive process.
        * @return {Array<String>} The array of formatted values.
        * @private
    */
    _formatValuesRecursive(valuesRaw, toFixed = 0 , nextDigitRequired = true) {
        if (!nextDigitRequired) {
            return valuesRaw.map(value => Number(value).toFixed(toFixed));
        } else {
            const valuesCloned = JSON.parse(JSON.stringify(valuesRaw));

            const valuesFixedTo = valuesCloned.map(value => Number(value).toFixed(toFixed));
            const valuesFixedToUnique = [...new Set(valuesFixedTo)];

            const valueMin = Math.min(...valuesRaw);
            const valuesFixedMinNumerical = Math.min(...valuesFixedTo.map(value => Number.parseFloat(value)));

            let nextDigitRequiredNew;
            if (valuesFixedTo.length === valuesFixedToUnique.length) {
                if (valueMin < valuesFixedMinNumerical) {
                    nextDigitRequiredNew = true
                } else {
                    nextDigitRequiredNew = false
                }
            } else {
                nextDigitRequiredNew = true
            }

            if (nextDigitRequiredNew) {
                return this._formatValuesRecursive(valuesRaw, toFixed+1, true)
            } else {
                 return this._formatValuesRecursive(valuesRaw, toFixed, false)
             }
        }
    };


    /**
      * Set `this._args.variableAlias.all`.
      * @param {ConfigEditDataSeries} dataSeries
      * @private
    */
    _setArgsVariableAliasAll() {

        let variablesAliases = [];

        this._args.argsGeoJsonSourceData.dataSeries.forEach(seriesItem => {

                const variables = seriesItem.variables;

                variables.forEach(variable => variablesAliases.push(variable.alias));
        });

        this._args.variableAlias.all = variablesAliases;

    };

    /**
      * Set `this._args.variableAlias.allWithDataSeriesIndexesAndTypes`.
      * @param {ConfigEditDataSeries} dataSeries
      * @private
    */
    _setArgsVariableAliasAllWithDataSeriesIndexesAndTypes() {

        let variablesAliasesWithDataSeriesIndexesAndTypes = [];

        this._args.argsGeoJsonSourceData.dataSeries.forEach((seriesItem, seriesItemIndex) => {

                const variables = seriesItem.variables;

                variables.forEach(variable => {

                    const variableAliasWithDataSeriesIndexAndType = {
                        variableAlias: variable.alias,
                        dataSeriesIndex: seriesItemIndex,
                        requiredType: variable?.requiredType
                    };

                    variablesAliasesWithDataSeriesIndexesAndTypes.push(variableAliasWithDataSeriesIndexAndType);
                });
        });

        this._args.variableAlias.allWithDataSeriesIndexesAndTypes = variablesAliasesWithDataSeriesIndexesAndTypes;
    };

    /**
      *
      * @param {}
      * @return {}
    */
    _setArgsVariableAliasNullCases() {

        this._args.variableAlias.nullCases = [];

        this._args.variableAlias.all.forEach(variableAlias => {
            const allNull = this._args.geoJsonSource.data.features
                            .filter(feature => feature.properties.variableAlias === variableAlias)
                            .every(feature => feature.properties.value === null);
            const someNull = this._args.geoJsonSource.data.features
                            .filter(feature => feature.properties.variableAlias === variableAlias)
                            .some(feature => feature.properties.value === null);
            if (allNull) {
                this._args.variableAlias.nullCases.push({variableAlias: variableAlias, nullCase: 'allNull'});
            } else if (someNull) {
                this._args.variableAlias.nullCases.push({variableAlias: variableAlias, nullCase: 'someNull'});
            } else {
                this._args.variableAlias.nullCases.push({variableAlias: variableAlias, nullCase: 'notNull'});
            }
        });
    };    

    /**
      * Set `this._args.variableAlias.scale.string` and `this._args.variableAlias.scale.numeric` for current values
      * of `this._args.geoJsonSource.data`, `this._args.variableAlias.current` and `this._args.colors.current`. If
      * the value assigned to `this._args.variableAlias.scale.string` and `this._args.variableAlias.scale.numeric` is
      * `[]` it means that there was some trouble with the process of setting its values.
      *
      * @param {GeoJSON} this._args.geoJsonSource.data The {@link GeoJSON} object that contains the data of the
      current {@link GeoJSONSource|map's style source}.
      * @param {String} this._args.variableAlias.current The value of the current variable alias whose data is showed
      * on map.
      * @param {Array<String>} this._args.colors.current The value of the current color scale used to show data
      * on map.
      *@private
    */
    _setArgsCurrentVariableAliasScale() {        

        //`values` is used to calculate `valueMin` and `valueMax`
        const values = this._args.geoJsonSource.data.features
            .filter(feature => {
                return (
                    feature.properties.variableAlias === this._args.variableAlias.current &&
                    feature.properties.value !== null
                )
            })
            .map(feature => feature.properties.value);

        const valueMin = Math.min(...values);
        const valueMax = Math.max(...values);

        //checking if is necessary to update variable `this._args.colors.current`
        //it is necessary if `this._args.variableAlias.current` has associated a `requiredType` = 'integer' and
        //the max value of 'values' is lesser than the number of current color scale ( `this._args.colors.current.length`)

        const isRequiredTypeAnInteger = this._args.variableAlias.allWithDataSeriesIndexesAndTypes.find(variable => {
            return (variable.variableAlias === this._args.variableAlias.current) && 
            (variable.requiredType === 'integer');
        });

        const integerValuesRange = Math.abs(valueMax - valueMin);

        if (isRequiredTypeAnInteger && (integerValuesRange < this._args.colors.current?.length)) {
            //update this._args.colors.current
            const colors = this._args.colors.current;
            this._args.colors.current = selectColorsSimple(colors, integerValuesRange);
        }

        let scaleString = [];

        //scaleNumeric
        let scaleNumeric = [];
        if (!isNaN(valueMin) && !isNaN(valueMax) &&
            ![-Infinity, Infinity].includes(valueMin) && ![-Infinity, Infinity].includes(valueMax)) {
            const numberOfColors = this._args.colors.current.length;
            const numberOfScaleSteps = numberOfColors;
            const scaleStep = (valueMax - valueMin) / numberOfScaleSteps;

            if (scaleStep === 0) {

                scaleNumeric = [valueMin];

                scaleString = [Number(scaleNumeric[0]).toFixed()];

            } else {

                for (let i = 0; i < numberOfScaleSteps; i++) {
                    const scaleValue = valueMin + i * scaleStep;
                    scaleNumeric.push(scaleValue);
                };

                scaleString = this._formatValuesRecursive(scaleNumeric);
            }

        }

        this._args.variableAlias.scale.string = scaleString;

        /**
          * Takes `string1` and `string2` and returns another string depending on the similarity of the first characters
          * in both strings.
          *
          * @param {String} string1 First string. Its length is lesser or equal to length of `string2`.
          * @param {String} string2 Second string.
          * @return {String} If `string1` is contained in `string2` then `string1` is returned. Otherwise a new
          * string is returned that contains the first characters of `string2` that are identical to the first
          * characters of `string1` plus the following character of `string2`.
        */
        const getStringScaleFirstItemFixed = (string1, string2) => {

            /**
              * Recursive function.
              * Gets the number of characters that are identical in the two strings arguments, starting from the left
              * or `index = 0`.
              *
              * @param {String} string1 First string. Its length is lesser or equal to length of `string2`.
              * @param {String} string2 Second string.
              * @return {Number} The number of characters that are identical in the two strings, from the left.
            */
            const getNumberOfFirstIdenticalCharactersRecursively = (string1, string2, index = 0) => {

                if (index === string1.length) {

                    const identicalNumChars = index;

                    return identicalNumChars;
                };

                if (string1[index] === string2[index]) {

                    return getNumberOfFirstIdenticalCharactersRecursively(string1, string2, index + 1);

                } else {

                    const identicalNumChars = index;

                    return identicalNumChars;

                }
            };

            let identicalNumOfChars = getNumberOfFirstIdenticalCharactersRecursively(string1, string2);

            const requiredCharsNumber = string1.length === identicalNumOfChars
                ? identicalNumOfChars
                : identicalNumOfChars + 1;

            return string2.slice(0,requiredCharsNumber);
        };

        if (scaleString.length > 0) {

            if (scaleString.length !== 1) {

                /*
                  Note:
                  The following condition is made to avoid that the minimum value of legend scale will not be
                  greater than the minimum value of data.
                  This way the minimum value of data is always included and painted in map.
                */
                if (parseFloat(scaleString[0]) > scaleNumeric[0]) {

                    scaleString[0] = getStringScaleFirstItemFixed(scaleString[0], scaleNumeric[0].toString());

                };

            };

            this._args.variableAlias.scale.numeric = scaleString.map(stringValue => parseFloat(stringValue));

        } else {

            this._args.variableAlias.scale.numeric = [];

        };

    };

    /**
      * Sets `this._args.variableAlias` object.
      *
    */
    _setArgsVariableAlias() {

        this._setArgsVariableAliasAll();

        this._setArgsVariableAliasAllWithDataSeriesIndexesAndTypes();

        this._setArgsCurrentVariableAliasScale();

        this._setArgsVariableAliasNullCases();

    };

    /**
      * Sets `this._args.fillLayer.filter`.
      * @param {String} args.layerControl.currentVariableAlias
      * @private
    */
    _setArgsFillLayerFilter() {
        this._args.fillLayer.filter = [
                'all',
                ["!=", ["get","value"], ["literal", null]],
                ["==", ["get","variableAlias"], this._args.variableAlias.current]
            ];
    };

    /**
      * Sets `this._args.fillLayer.paint.fillColor`. If `this._args.variableAlias.scale.numeric`=== `null`,
      then `this._args.fillLayer.paint.fillColor = this._GRAY_WITH_OPACITY_0`.
      * @param {Array<Number>} this._args.variableAlias.scale.numeric
      * @param {Array<String>} this._args.colors.current
      * @private
    */
    _setArgsFillLayerPaintFillColor() {

        const scaleNumeric = this._args.variableAlias.scale.numeric;

        if (scaleNumeric?.length === 0) {
            this._args.fillLayer.paint.fillColor = this._GRAY_WITH_OPACITY_0;
            return
        }

        const scaleNumericLength = scaleNumeric.length;

        const colors = this._args.colors.current;

        let fillColor = ["case"];

        for (let i = scaleNumericLength-1; i >= 0 ; i--) {
            fillColor.push([">=", ["get","value"], scaleNumeric[i]]);
            fillColor.push(colors[i]);
        };

        fillColor.push(this._GRAY_WITH_OPACITY_0);

        this._args.fillLayer.paint.fillColor = fillColor;

    };

    /**
      * Sets `this._args.fillLayer`
      *
    */
    _setArgsFillLayer() {

        this._args.fillLayer = {
            filter: ["boolean", false],
            paint: {
                fillColor: ["literal", this._GRAY_WITH_OPACITY_0]
            }
        };

        this._setArgsFillLayerFilter();

        this._setArgsFillLayerPaintFillColor();
    };

    /**
          * Sets `this._args.legendControl.variables`.
          * @param {Array<String>} this._args.variableAlias.scale.string If its length is `0` or if it has any value
          equal to `''`, then `this._args.legendControl.variables = []`.
          * @private
        */
    _setArgsLegendControlVariables() {

        const scaleString = this._args.variableAlias.scale.string;

        if (scaleString.length === 0 || scaleString.some(value => value === '')) {
            this._args.legendControl.variables = [];
            return;
        }

        if (scaleString.length === 1) {
            this._args.legendControl.variables = scaleString;
            return;
        }

        let variables = [];

        let separator = " - ";
        let leftBracket = "[";
        let rightBracketClosed = "]";
        let rightBracketOpen = ")";
        const scaleStringLength = scaleString.length;
        for (let i = 0; i < scaleStringLength ; i++) {

            const variableItem = [
                leftBracket,
                scaleString[i],
                i+1 === scaleStringLength ? " + " : separator,
                i+1 === scaleStringLength ? "" : scaleString[i+1],
                i+1 === scaleStringLength ? rightBracketClosed : rightBracketOpen
            ].join("");

            variables.push(variableItem);

        };

        this._args.legendControl.variables = variables;

    };

    /**
      * This data type represents the `variableAlias` and `index` of the {@link ConfigEditDataSeries|dataSeries} item
      * that contains it.
      *
      * @typedef {Object} VariableAliasesWithDataSeriesIndexesItem
      * @property {String} variableAlias The value of variable alias.
      * @property {Number} dataSeriesIndex The index of {@link ConfigEditDataSeries|dataSeries} item that contains
      * the `variableAlias`.
      */

    /**
      * Set `this.params.popup.currentIndex`. See {@link MapboxGLMapInstance2#params|params}.
      *
      * The pair of values `variableAlias` and `index` of each {@link ConfigEditDataSeries} item that contains it
      * were previously stored in `this._args.variableAlias.allWithDataSeriesIndexesAndTypes`. Then, filtering it by current
      * variableAlias (`this._args.variableAlias.current`), the current `dataSeriesIndex` is obtained.
      *
      * @param {String} this._args.variableAlias.current Current variable alias.
      * @param {Array<VariableAliasesWithDataSeriesIndexesItem>} this._args.variableAlias.allWithDataSeriesIndexesAndTypes
      Variables aliases and the indexes of {@link ConfigEditDataSeries} items to which they belong.
      * @private
    */
    _setParamsPopupCurrentIndex() {
        const currentVariableAlias = this._args.variableAlias.current;
        const variablesAliasesAndIndexes = this._args.variableAlias.allWithDataSeriesIndexesAndTypes;
        const currentDataSeriesIndex = variablesAliasesAndIndexes
            .find(item => item.variableAlias === currentVariableAlias).dataSeriesIndex;

        this.params.popup.currentIndex = currentDataSeriesIndex
    };


    /**
      * The value of the `callback` property of a {@link ParamsEvent} object, that has `name = 'click'`.
      *
      * @param {MouseEvent} e Argument of function.
      * See {@link https://docs.mapbox.com/mapbox-gl-js/api/events/#mapmouseevent|MapMouseEvent} for more information on
      * this type of data.
      * @private
    */

    _handleClick(e) {
        const map = this;
        /*
            data passed to map from MapboxGLMapInstanceChoropleth2 instance. The reason to do that is that the 'this'
            that is available in the closure of the callback _handleClick is the 'map' itself and not the one
            corresponding to the MapboxGLMapInstanceChoropleth2 instance.
        */
        const {
            hasPopup,
            popup,
            analysisId,
            mapboxgl,
            getPopupDOMContent: getPopupDOMContent2,
            setValuePopupQuery
        } = map.getMapInstanceData();

        if (!hasPopup(popup)) return;

        const coordinates = [e.lngLat.lng, e.lngLat.lat];
        const dataId = e.features[0].properties.dataId;
        const popupDataIndex = e.features[0].properties.popupDataIndex;

        const popupData = popup.data[popup.currentIndex];
        const popupDataJSON = JSON.stringify(popupData);

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        //getting data from API
        getPopupData(analysisId, dataId, popupDataIndex)().then(data => {
            if (data) {
                const actualData = data.data[0][0];

                //showing popup query
                let textToInsert = [
                    "--********************************",
                    "--Popup query",
                    "--********************************",
                    data.query[0]
                ].join("\n");

                const editorDelta = { //inserting a Delta as value to show text with format
                    "ops": [
                        { "insert": textToInsert }
                    ]
                };
                setValuePopupQuery(editorDelta)

                if (data.errors.length > 0) {                    
                    alert(['Unexpected error. Please, contact application\'s admin!. The error is:', 
                    data.errors[0]].join(' '));
                } else {
                    new mapboxgl.Popup({ maxWidth: '400px', closeOnClick: true })
                        .setLngLat(coordinates)
                        .setDOMContent(getPopupDOMContent2(popupDataJSON, actualData))
                        .addTo(map);
                }                
            };
        });
    };


    /**
      * Spreads `rawData` argument into instance properties.
      * @param {RawData} [rawData] The data sent by backend server.
    */
    _spreadRawData(rawData) {

        this.spread = {};

        const {
            title,
            data,
            errors,
            configEdit
        } = rawData;
        this.spread.mapTitle = title;
        this.spread.dataRecords = data;
        this.spread.errors = errors;

        this.spread.dataSeries = configEdit.dataSeries;
        const {
            borderColor,
            borderWidth,
            bordersIncluded,
            showBorders,
            showToUser,
            geometryAccuracy,
            colorSchemesMode,
            defaultLayer,
            defaultStyle,
            styles,
            legendTitle,
        } = configEdit.map;

        this.spread.borderColor = borderColor;
        this.spread.borderWidth = borderWidth;
        this.spread.bordersIncluded = bordersIncluded;
        this.spread.showBorders = showBorders;
        this.spread.showToUser = showToUser;
        this.spread.geometryAccuracy = geometryAccuracy;
        this.spread.colorSchemesMode = colorSchemesMode;
        this.spread.defaultLayer = defaultLayer;
        this.spread.defaultStyleValue = defaultStyle;
        this.spread.stylesValues = styles;
        this.spread.legendTitle = legendTitle;

        if (colorSchemesMode === 'auto') {
            this.spread.defaultColorScheme = configEdit.map.defaultColorScheme;
            this.spread.colorSchemes = configEdit.map.colorSchemes;
        };

        if (colorSchemesMode === 'manual') {
            this.spread.variablesAndColors = configEdit.map.variablesAndColors;
        };
    };

    /**
      * Sets `this._args.colors`.
      *
      * @param {String} this.spread.colorSchemesMode
      * @param {} this.spread.defaultColorScheme Used when color scheme mode is 'auto'.
      * @param {} this.spread.colorSchemes Used when color scheme mode is 'auto'.
      * @param {} this.spread.variablesAndColors Used when color scheme mode is 'manual'.
    */
    _setArgsColors() {

        this._args.colors = {
            scheme: {
                mode: this.spread.colorSchemesMode
            }
        };

        if (this._args.colors.scheme.mode === 'auto') {
            this._args.colors = {
                scheme: {
                    ...this._args.colors.scheme,
                    current: this.spread.defaultColorScheme,
                    schemes: this.spread.colorSchemes
                },
                current: JSON.parse(this.spread.defaultColorScheme).colorScheme.scale,
                default: JSON.parse(this.spread.defaultColorScheme).colorScheme.scale,
                updated: JSON.parse(this.spread.defaultColorScheme).colorScheme.scale
            };

        };

        if (this._args.colors.scheme.mode === 'manual') {
            const currentVariableColorNatureOfData = this.spread.variablesAndColors
                 .find(vc => vc.variableAlias === this._args.variableAlias.current).variableColor;
            this._args.colors.current = JSON.parse(currentVariableColorNatureOfData).colorScheme.scale;
        };

    };


    /**
      * Set {@link MapboxGLMapInstanceChoropleth2#_args|_args} for Choropleth map.
      * @param {ParamsArgChoropleth} paramsArg The argument used to set the `params` member of the class.
      * @private
    */
    _setArgs(paramsArg) {

        //spreading paramsArg
        const {
            mapPlotHeight,
            rawData,
            setLastControlEvent,
            analysisId,
            analysisType,
            boundsUser,
            setIsMapLoaded,
            setValuePopupQuery
        } = paramsArg;

        this._spreadRawData(rawData);

        this._setValuePopupQuery = setValuePopupQuery;

        //grouping already spread variables
        this._args.border = {
            color: this.spread.borderColor,
            width: this.spread.borderWidth,
            isIncluded: this.spread.bordersIncluded,
            isShowBorders: this.spread.showBorders,
            isShowedToUser: this.spread.showToUser,
            accuracy: this.spread.geometryAccuracy
        };

        this._args.argsGeoJsonSourceData = {
            dataSeries: this.spread.dataSeries,
            dataRecords: this.spread.dataRecords
        };

        //sets this._args.geoJsonSource.data
        this._setArgsGeoJsonSourceData();

        this._args.variableAlias = {
            current: this.spread.defaultLayer,
            all: [],
            scale: {
                string: [],
                numeric: []
            },
            allWithDataSeriesIndexesAndTypes: [],
            nullCases: []
        };

        this._setArgsColors();

        this._args.style = {
            defaultValue: this.spread.defaultStyleValue,
            stylesValues: this.spread.stylesValues
        };

        this._args.legendControl = {
            title: this.spread.legendTitle,
            variables: [],
            colors: this._args.colors.current,
            maxHeight: 0.8*mapPlotHeight
        };

        this._setArgsVariableAlias();

        this._args.layersControl = {
            radioGroupProps: {
                options: this._args.variableAlias.all.map(variableAlias => {
                    return {label: variableAlias, value: variableAlias};
                }),
                defaultValue: this._args.variableAlias.current,
                setLastControlEvent: setLastControlEvent,
                controlName: 'layersControl'
            },
            maxHeight: 0.8*mapPlotHeight
        };

        this._args.stylesControl = {

            radioGroupProps: {
                options: this._MAPBOX_STYLES
                    .filter(style => this._args.style.stylesValues.includes(style.value))
                    .map(style => {
                        return {label: style.label, value: style.value}
                    }),
                defaultValue: this._args.style.defaultValue,
                setLastControlEvent: setLastControlEvent,
                controlName: 'stylesControl'
            },

            maxHeight: 0.6*mapPlotHeight
        };

        this._args.colorSchemesControl = (() => {
            if (this._args.colors.scheme.mode === 'auto') {
                return (
                    {
                        radioGroupProps: {
                            options: this._args.colors.scheme.schemes.map(schemeJSON => {
                                const scheme = JSON.parse(schemeJSON);
                                const option = {
                                    label: scheme.colorScheme.label,
                                    value: JSON.stringify(scheme.colorScheme.scale)
                                };

                                return option;
                            }),
                            defaultValue: JSON.stringify(this._args.colors.default),
                            setLastControlEvent: setLastControlEvent,
                            controlName: 'colorSchemesControl'
                        },
                        maxHeight: 0.6*mapPlotHeight
                    }
                )
            }

        })();

        this._setArgsFillLayer();

        this._setArgsLegendControlVariables();

        this._setParamsPopupCurrentIndex();

        this._args.eventOnMouseenter = {
            layerId: 'fillLayer'
        };

        this._args.eventOnMouseleave= {
            layerId: 'fillLayer'
        };

        /**
          * EventOnClick data type definition.
          * @typedef {Object} EventOnClick
          * @property {String} layerId
          * @property {Function} callback
          */
        /**
          * Parameters for setting {@link ParamsEvent} with `name = 'click'`.
          * @type {EventOnClick}
          */
        this._args.eventOnClick = {
            layerId: 'fillLayer',
            callback: this._handleClick
        };

        this._args.bounds = {
            user: boundsUser
        };

        this._args.paramsSetIsMapLoaded = setIsMapLoaded;

        this._args.general = {
            mapTitle: this.spread.mapTitle,
            mapPlotHeight: mapPlotHeight,
            analysisId: analysisId,
            analysisType: analysisType
        };

    };

    /**
      * Set {@link MapboxGLMapInstance2#params}' `style.sources` property. Must be overridden in children classes.
      * @private
      */
    _setParamsStyleSources() {

        //setting style's sources
        this.params.style.sources[0] = this._createGeoJsonSource(
                'choroplethSource',
                this._args.geoJsonSource.data
        );

    };

    /**
      * Returns the 'fillLayer' object.
      *
      * Uses as arguments `this.params.style.sources[0].id`, this._args.fillLayer.filter and
      * `this._args.fillLayer.paint.fillColor`.
      *
      * @return {StyleLayer}
    */
    _getFillLayer() {
        return (
            {
                'id': 'fillLayer',
                'type': 'fill',
                'source': this.params.style.sources[0].id,
                'filter': this._args.fillLayer.filter,
                'layout': {
                    'visibility': 'visible'
                },
                'paint': {
                    'fill-color': this._args.fillLayer.paint.fillColor
                }
            }
        );
    };

    _getBordersLayer() {
        return (
            {
                'id': 'borderLayer',
                'type': 'line',
                'source': this.params.style.sources[0].id,
                'filter': true,
                'layout': {
                    'visibility': 'visible'
                },
                'paint': {
                    'line-color': this._args.border.color,
                    'line-width': this._args.border.width,
                }
            }
        );
    };

    /**
      * Set {@link MapboxGLMapInstance2#params}' `style.layers` property. Must be overridden in children classes.
      * @private
      */
    _setParamsStyleLayers() {

        this.params.style.layers = [this._getFillLayer(), this._getBordersLayer()];

    };

    /**
      * Set `messagesList`
      * @param {} 
      * @return {}
      * @private
    */
    _setMessagesList() {

        //clean messages list
        this.params.messagesList = [];

        //case error in response to request
        const isThereNotNullErrors = this.spread.errors.some(error => error !== null)?.length > 0;
        if (isThereNotNullErrors) {
            this.spread.errors.forEach(error => {
                if (error !== null) {
                    const title = this._intl.formatMessage({id: "Error.unexpectedError.from.server"});
                    const TheErrorMessageIs = error?.message
                        ? this._intl.formatMessage(
                            {id: "Error.unexpectedError.the.errorMessage.is"},
                            {errorMessage: error.message})
                        : '';
                    const content = this._intl.formatMessage({id: "Error.contact.admin"},
                        {TheErrorMessageIs: TheErrorMessageIs});
                    const msg = {title: title, content: content};
                    this.params.messagesList.push(msg);
                }
            });

            return;
        }

        //variableAlias null cases

        const getMsg = (nullCasesCaseName) => {
            const idInit = "MapboxGLMapInstanceChoropleth2.setMessagesList.nullCases.";
            let variablesAliases = nullCasesCaseName.map(nullCase => nullCase.variableAlias).join('", "');
            variablesAliases = "[".concat('"', variablesAliases,'"',"]");
            const nullCase = nullCasesCaseName[0].nullCase; //taking the first item, all has the same value for nullCase
            const formatMessageId = idInit.concat(nullCase);
            const title = this._intl.formatMessage({id:formatMessageId.concat(".title")});
            const content = this._intl.formatMessage({id:formatMessageId.concat(".content")},
                                               {variablesAliases: variablesAliases});
            const msg = {title: title, content: content};

            return msg;
        };

        ['allNull','someNull'].forEach(caseName => {
            const nullCasesCaseName = this._args.variableAlias.nullCases
                .filter(nullCase => nullCase.nullCase === caseName);
            if (nullCasesCaseName.length !== 0) {
                this.params.messagesList.push(getMsg(nullCasesCaseName));
            };
        });

    };

    /**
      * Set `control` of the item of array `this.params.controls` with `name = 'legendControl'`.
      * @param {Object} this._args.legendControl
      * @private
    */
    _setLegendControl() {

        let legendControl = null;

        const variables = this._args.legendControl.variables;

        const colors = this._args.legendControl.colors;
        const legendTitle = this._args.legendControl.title;
        const maxHeight = this._args.legendControl.maxHeight;

        legendControl = new this._LegendControl(variables, colors, legendTitle, maxHeight);

        this._setParamsControlByNameAndControl('legendControl', legendControl);

    };


    /**
      * Set `control` of the item of array `this.params.controls` with `name = 'activeLayerControl'`.
      * @param {String} this._args.variableAlias.current
      * @private
    */
    _setActiveLayerLabelControl() {

        const label = this._intl.formatMessage({id: "activeLayerLabelControl.label"});

        const activeLayerLabel = this._args.variableAlias.current;

        const activeLayerLabelControl = new this._ActiveLayerLabelControl(label, activeLayerLabel);

        this._setParamsControlByNameAndControl('activeLayerLabelControl', activeLayerLabelControl);

    };

    /**
      * Set {@link MapboxGLMapInstanceChoropleth2#params}' `style.controls` property.
      * @private
      */
    _setParamsControls() {

        super._setParamsControls();

        let controlsNames = ['layersControl','stylesControl'];
        if (this._args.colors.scheme.mode === 'auto') controlsNames.push('colorSchemesControl');
        this._setParamsControlsByName(controlsNames);

        this._setLegendControl();
        
        this._setActiveLayerLabelControl();

    };

    /**
     * Method used to set params for a Choropleth map.
     *
     * @private
     */
    _setParams() {

        super.setParams();

        //setting style's sources
        this._setParamsStyleSources();

        //setting style's layer
        this._setParamsStyleLayers();

        //setting controls
        this._setParamsControls();

        //setting events
        super._setParamsEvents()

        super._setParamsBounds();

    };


    /**
      * The handler for the event that is fired when a change is made in an instance of
      * {@link MapboxGLMapInstance2#_CustomMapboxControlByRadio|_CustomMapboxControlByRadio} class that has
      * `controlName = 'layersControl'`.
      * @param {String} newVariableAlias The new variable alias.
    */
    handleLayersControlChange(newVariableAlias) {

        //update variable alias
        this._args.variableAlias.current = newVariableAlias;
        
        //update current color        
        if (this._args.colors.scheme.mode === 'manual') {
            const currentVariableColorNatureOfData = this.spread.variablesAndColors
                .find(vc => vc.variableAlias === this._args.variableAlias.current).variableColor;
            this._args.colors.current = JSON.parse(currentVariableColorNatureOfData).colorScheme.scale;
        } else {
            this._args.colors.current = this._args.colors.updated;
        };

        this._setArgsCurrentVariableAliasScale();

        //update fillLayer
        //update fillLayer filter
        this._setArgsFillLayerFilter();
        this.map.setFilter('fillLayer', this._args.fillLayer.filter);

        //update fillLayer paint.fill-color
        this._setArgsFillLayerPaintFillColor();
        this.map.setPaintProperty('fillLayer', 'fill-color', this._args.fillLayer.paint.fillColor);

        //update controls
        //update legendControl
        this._setArgsLegendControlVariables();
        const legendControl = this._getControlByName('legendControl');
        this._args.legendControl.colors = this._args.colors.current;
        legendControl.update(this._args.legendControl.variables, this._args.legendControl.colors);

        //update activeLayerLabelControl
        const activeLayerLabelControl = this._getControlByName('activeLayerLabelControl');
        activeLayerLabelControl.update(this._args.variableAlias.current);

        //update popup
        this._setParamsPopupCurrentIndex();
    };

    /**
      * The handler for the event that is fired when a change is made in an instance of
      * {@link MapboxGLMapInstance2#_CustomMapboxControlByRadio|_CustomMapboxControlByRadio} class that has
      * `controlName = 'stylesControl'`.
      * @param {String} newStyleValue The new style value. It must be any of the values of property `value` of any of
      * the items of {@link MapboxGLMapInstance2#_MAPBOX_STYLES|MAPBOX_STYLES} constant.
    */
    handleStylesControlChange(newStyleValue) {

        //update fillLayer. It is required by the event 'styledata'.
        const styleIndex = this._getStyleLayerIndexByName('fillLayer');
        this.params.style.layers[styleIndex] = this._getFillLayer();

        const newUrl = this._MAPBOX_STYLES.find(style => style.value === newStyleValue).url;
        this.map.setStyle(newUrl);
    };

    /**
      * The handler for the event that is fired when a change is made in an instance of
      * {@link MapboxGLMapInstance2#_CustomMapboxControlByRadio|_CustomMapboxControlByRadio} class that has
      * `controlName = 'colorSchemesControl'`.
      * @param {String} newColorScaleJSON The new color scale value. It must be any of the values of property `scale`
      * of any of the {@link COLOR_SCHEMES_ITEM} items of {@link MapboxGLMapInstance2#_COLOR_SCHEMES|COLOR_SCHEMES}
      * constant.
    */
    handleColorSchemesControlChange(newColorScaleJSON) {
        
        this._args.colors.updated= JSON.parse(newColorScaleJSON);
        this._args.colors.current = JSON.parse(newColorScaleJSON);

        this._setArgsCurrentVariableAliasScale();

        //update fillLayer paint.fill-color
        this._setArgsFillLayerPaintFillColor();
        this.map.setPaintProperty('fillLayer', 'fill-color', this._args.fillLayer.paint.fillColor);

        //update legendControl
        this._args.legendControl.colors = this._args.colors.current;
        const legendControl = this._getControlByName('legendControl');
        legendControl.update(this._args.legendControl.variables, this._args.legendControl.colors);

    };

    /**
      * Handles the event that is fired when filters are applied and new data is received from
      * backend.
      * @param {Array<MapboxGLMapInstanceChoropleth2#DataRecords>} dataRecords The new data records sent from backend.
   */
    handleApplyFiltersEvent(dataRecords) {

        //updating args
        this._args.argsGeoJsonSourceData.dataRecords = dataRecords;

        this._setArgsGeoJsonSourceData();

        this._setArgsCurrentVariableAliasScale();
        this._setArgsVariableAliasNullCases();

        //update source
        this._setParamsStyleSources();
        this.map.getSource('choroplethSource').setData(this._args.geoJsonSource.data);

        //update fillLayer
        //update filter
        this._setArgsFillLayerFilter();
        this.map.setFilter('fillLayer', this._args.fillLayer.filter);

        //update paint.fill-color
        this._setArgsFillLayerPaintFillColor();
        this.map.setPaintProperty('fillLayer', 'fill-color', this._args.fillLayer.paint.fillColor);

        //update legendControl
        this._setArgsLegendControlVariables();
        let legendControl = this._getControlByName('legendControl');
        legendControl.update(this._args.legendControl.variables, this._args.legendControl.colors);

        //setting map bounds
        super._setParamsBounds();
        this.map.fitBounds(this.params.bounds, {padding: 20, linear: true});

        //setting messagesList
        this._setMessagesList();
    };

};

export default MapboxGLMapInstanceChoropleth2;