import {PROJECT_ACTION_TYPES, SURVEY_FORM_ACTION_TYPES} from "../../typesAction";


const initialState = {
    surveyForms: [],
    projects: [],
    current: null,
    loading: false,
    error: null
}

const surveyFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SURVEY_FORM_ACTION_TYPES.LOAD_SURVEY_FORM:
            return {
                ...state, loading: true
            };
        case SURVEY_FORM_ACTION_TYPES.CLEAR_LOAD_SURVEY_FORM:
            return {
                ...state, loading: false
            };
        case SURVEY_FORM_ACTION_TYPES.ERROR_SURVEY_FORM:
            return {
                ...state, error: action.payload, loading: false
            };
        case SURVEY_FORM_ACTION_TYPES.CLEAR_SURVEY_FORM_ERRORS:
            return {
                ...state,
                error: null, loading: false
            };
        case SURVEY_FORM_ACTION_TYPES.LIST_SURVEY_FORM:
            return {
                ...state, surveyForms: action.payload, loading: false
            };
        case SURVEY_FORM_ACTION_TYPES.ADD_SURVEY_FORM:
            return {
                ...state,
                current: action.payload,
                loading: false
            };
        case PROJECT_ACTION_TYPES.SHOW_SURVEY_FORM:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case PROJECT_ACTION_TYPES.EDIT_SURVEY_FORM:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case SURVEY_FORM_ACTION_TYPES.DELETE_SURVEY_FORM:
            const updatedSurveyForms = state.surveyForms.forms.filter(surveyForm =>
                action.payload !== surveyForm.id);
            const updatedCount = state.surveyForms.count - 1;
            return {
                ...state,
                surveyForms: {
                    ...state.surveyForms,
                    forms: updatedSurveyForms,
                    count: updatedCount
                },
                loading: false
            };
        default:
            return state;
    }

};

export default surveyFormReducer;