import {createSelector} from "reselect";

const selectedDataAnalysis = state => state.dataAnalysis;

export const selectLoading = createSelector(
    [selectedDataAnalysis],
    (dataAnalysis) => dataAnalysis.loading
);

export const selectError = createSelector(
    [selectedDataAnalysis],
    (dataAnalysis) => dataAnalysis.error
);

export const selectDataAnalysis = createSelector(
    [selectedDataAnalysis],
    (dataAnalysis) => dataAnalysis.dataAnalysis
);

export const selectCurrentDataAnalysis = createSelector(
    [selectedDataAnalysis],
    (dataAnalysis) => dataAnalysis.current
);

export const selectView = createSelector(
    [selectedDataAnalysis],
    (dataAnalysis) => dataAnalysis.view
);

export const selectReactFormData = createSelector(
    [selectedDataAnalysis],
    (dataAnalysis) => dataAnalysis.reactFormData
);
