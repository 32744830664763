import axiosInstance from "../../../utils/axiosInstance";

export const listApiFormByNameNormalized = (nameNormalized, pagination, filters = {}, sorter) => async dispatch => {
    try {
        // Prepare all query parameters
        let queryParameters = {}
        Object.keys(filters).map(key => (
            queryParameters[key] = filters[key] == null ? filters[key] : filters[key][0])
        );
        if (sorter !== undefined) {
            queryParameters['sort'] = sorter['field'];
            queryParameters['order'] = sorter['order'] === 'descend' ? 'desc' : 'asc'
        }
        if (pagination !== undefined) {
            queryParameters['offset'] = (pagination.current - 1) * pagination.pageSize;
            queryParameters['max'] = pagination.pageSize
        }
        queryParameters['nameNormalized'] = nameNormalized;
        queryParameters['forConfiguration'] = true;

        // Spread the parameters
        const params = {
            ...queryParameters
        };

        // Send request and get data
        const {data} = await axiosInstance(dispatch).get(`/form`, {params});

        return data;
    } catch (err) {
        console.log(err);
    }
};


export const getApiForm = (id, max) => async () => {
    try {
        // Prepare all query parameters
        let queryParameters = {}
        queryParameters['max'] = max;
        // Spread the parameters
        const params = {
            ...queryParameters
        };

        // Send request
        const {data} = await axiosInstance().get(`/form/${id}/data`, {params});

        return data;
    } catch (err) {
        console.log(err);
    }
};

export const editApiFormData = (form_id, data_id, form, setApiFormUpdated) => async () => {
    try {
        // Send request and get data
        const entityUrl = 'form'
        const urlArg = `/${entityUrl}/${form_id}/data/${data_id}`;
        await axiosInstance().patch(urlArg, form);

        if (setApiFormUpdated) setApiFormUpdated(true);
    } catch (err) {
        const error = await err.response.data;
        console.log(error)
    }
};

export const getRegionsByCode = async (idLevel, levelup, regionsCodes) => {
    try {
        //formData
        const formData = new FormData();
        regionsCodes.forEach(regionCode => formData.append('codes', regionCode));

        // Send request
        const {data} = await axiosInstance().post(`/geolevel/get_regions_by_code/${idLevel}/${levelup}`, formData);
        return data;
    } catch (err) {
        console.log(err)
    }
};


export const getRegionsWithGeojson = async (idLevel, levelup) => {
    try {
        // Send request
        const {data} = await axiosInstance().get(`/geolevel/get_regions_with_geojson/${idLevel}/${levelup}`);
        return data;
    } catch (err) {
        console.log(err);
    }
};

export const getRegionsWithGeojsonByCode = async (idLevel, levelup, regionsCodes) => {
    try {
        //formData
        const formData = new FormData();
        regionsCodes.forEach(regionCode => formData.append('codes', regionCode));

        // Send request
        const {data} = await axiosInstance().post(`/geolevel/get_regions_with_geojson/${idLevel}/${levelup}`, formData);
        return data;
    } catch (err) {
        console.log(err);
    }
};


export const getUserRegionsWithGeojsonByIdLevel = async (idLevel) => {
    try {
        // Send request and get data
        const {data} = await axiosInstance().get(`/geolevel/user_regions_with_geojson/${idLevel}`);
        return data;
    } catch (err) {
        const error = await err.response.data;
        console.log(error);
    }
};




