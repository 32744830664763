import "../../utils/dataAnalysis/utils/helpersForPopup.css"

//popup DOM element
export const getPopupDOMContentForPointsOfInterest = (feature, distance, distanceMiles) => {
    const popupContainerElement = document.createElement('div');
    popupContainerElement.classList.add('custom-popup-divContainerElement');

    //displaying title
    const titleElement = get_titleElement(feature.properties.name);
    popupContainerElement.appendChild(titleElement);

    //line
    const lineElement = document.createElement('hr');
    lineElement.classList.add('custom-popup-lineElement');
    popupContainerElement.appendChild(lineElement);

    //distance
    if (distance){
        const distanceElement = get_pElement('Distance', distance.toFixed(2) + 'km / '+ distanceMiles.toFixed(2)+ 'mi');
        popupContainerElement.appendChild(distanceElement);
    }

    //description
    const isDescription = feature.properties?.description !== null && feature.properties?.description !== undefined;
    if (isDescription) {
        const descriptionElement = get_pElement('Description', feature.properties.description);
        popupContainerElement.appendChild(descriptionElement);
    }

    return popupContainerElement
};

const get_titleElement = (title) => {
    const pElement = document.createElement('p');
    pElement.classList.add('custom-popup-titleElement');

    const spanElementAlias = document.createElement('span');
    spanElementAlias.classList.add('custom-popup-title');
    spanElementAlias.innerText = title;
    pElement.appendChild(spanElementAlias);

    return pElement
};

const get_pElement = (variableAlias, variableValue) => {
    const pElement = document.createElement('p');
    pElement.classList.add('custom-popup-pElement');

    const spanElementAlias = document.createElement('span');
    spanElementAlias.classList.add('custom-popup-alias');
    spanElementAlias.innerText = variableAlias === '' ? variableAlias : variableAlias+ ': ';
    pElement.appendChild(spanElementAlias);

    const spanElementValue = document.createElement('span');
    spanElementValue.classList.add('custom-popup-value');
    spanElementValue.innerText = variableValue;
    pElement.appendChild(spanElementValue);

    return pElement
};

