import {Button, Progress, Table, InputNumber} from 'antd';
import { useIntl } from "react-intl";
import { green, /*red*/ } from '@ant-design/colors';
import { useEffect } from 'react';

const ImportXlsx = (props) => {

    const {
        inputXlsxFileRef,
        disabledInputXlsxFile,
        importIconXlsx,
        importReadyXlsx,
        checkPermissions,
        importMessageXlsx,
        progressImportXlsx,
        hideProgress,
        dataSourceImportXlsxErrors,
        xlsxFile,
        setDataSourceImportXlsxErrors,
        setXlsxFile,
        setImportMessageXlsx,
        setImportReadyXlsx,
        setImportXlsxPhase,
        setChunkValue,
        chunkValue
    } = props;

    const intl = useIntl();

    useEffect(function ue_registeringInputXlsxFileListener() {

        if (disabledInputXlsxFile) return;

        //registering event listener
        //'click'
        inputXlsxFileRef.current.addEventListener('click', () => {            

            //initializing input file element 
            if (inputXlsxFileRef.current?.files) {
                const dataTransfer = new DataTransfer();
                inputXlsxFileRef.current.files = dataTransfer?.files;               
            };

            //closing table with errors
            setDataSourceImportXlsxErrors([]);
        });

        //'change'
        inputXlsxFileRef.current.addEventListener('change', () => {

            if (inputXlsxFileRef.current?.files?.length === 0 ||
                !inputXlsxFileRef.current?.files?.[0]) return;

            setXlsxFile(JSON.parse(JSON.stringify(inputXlsxFileRef.current.files[0].name)));
            setImportMessageXlsx(intl.formatMessage({ id: "label.request-import-reading-xlsx" }));
            setImportReadyXlsx(false);
            setImportXlsxPhase({ phase: 'inputXlsxFileRef.event.change' });
        });

        //eslint-disable-next-line
    }, [disabledInputXlsxFile]); 


    const handleChunkChange = (value) => {
        setChunkValue(value);
    };

    return (
        <>
            <span id={'span-import-xlsx'}>
                <input
                    ref={inputXlsxFileRef}
                    type='file'
                    accept={".xlsx"}
                    hidden={true}
                    disabled={disabledInputXlsxFile}
                />
                <Button type="primary" size={"large"}
                    style={{
                        marginBottom: 16,
                        marginLeft: 16,
                        backgroundColor: "darkgoldenrod"
                    }}
                    icon={importIconXlsx} loading={!importReadyXlsx}
                    onClick={() => inputXlsxFileRef.current.click()}
                    hidden={!checkPermissions('ROLE_ADMIN')}
                    disabled={disabledInputXlsxFile}
                >
                    {importMessageXlsx}
                </Button>
                <InputNumber
                    style={{marginLeft: '10px', width: '25ch'}}
                    placeholder= {intl.formatMessage({id: "ImportXlsx.input.placeholder"})}
                    onChange={handleChunkChange}
                    value={chunkValue}
                />
            </span>
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}
            >
                <Progress
                    percent={progressImportXlsx.percent}
                    strokeColor={green[5]}
                    /*success={
                        {
                            percent: progressImportXlsx.percentNoSuccess,
                            strokeColor: red[6]
                        }
                    }*/
                    format={(percent, successPercent) => {
                        return (
                            <>
                                <span style={{color: green[5]}}>{percent - successPercent} %</span>
                                {/*
                                    successPercent > 0
                                        ? <span style={{ color: red[6] }}>{successPercent} %</span>
                                        : null
                                */}
                            </>
                        )
                    }}
                    size={"small"}
                    hidden={hideProgress}                                        
                    type={'circle'}
                />
            </div>
            {
                dataSourceImportXlsxErrors?.length > 0
                    ? <>
                        <Table
                            columns={[
                                {
                                    title: <p style={{ fontWeight: 'bold' }}>
                                        {intl.formatMessage({ id: "label.SurveyFormsData.Table.importXlsx.id" })}
                                    </p>,
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                    width: '20%',
                                    fixed: true,
                                    sorter: (a, b) => a === b ? 0 : a < b ? -1 : 1
                                },
                                {
                                    title: <p style={{ fontWeight: 'bold' }}>
                                        {intl.formatMessage({ id: "label.SurveyFormsData.Table.importXlsx.errors" })}
                                    </p>,
                                    dataIndex: 'errors',
                                    key: 'errors',
                                    align: 'center',
                                    width: '80%'
                                }
                            ]}
                            dataSource={dataSourceImportXlsxErrors}
                            showHeader
                            scroll={{ y: '65vh', x: '90vw' }}
                            title={() => intl.formatMessage(
                                { id: "label.SurveyFormsData.Table.importXlsx.title" },
                                { xlsxFile: xlsxFile })
                            }
                        />
                        <Button
                            type="primary" size={"large"}
                            onClick={() => setDataSourceImportXlsxErrors([])}
                            style={{
                                marginTop: 16,
                                marginBottom: 30
                            }}
                        >
                            {'Close Report of errors!'}
                        </Button>
                    </>
                    : null
            }
        </>
    );
};

export default ImportXlsx;