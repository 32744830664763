import {createSelector} from "reselect";

const selectProject = state => state.project;

export const selectLoading = createSelector(
    [selectProject],
    (project) => project.loading
);

export const selectError = createSelector(
    [selectProject],
    (project) => project.error
);

export const selectProjects = createSelector(
    [selectProject],
    (project) => project.projects
);

export const selectCurrentProject = createSelector(
    [selectProject],
    (project) => project.current
);