import {Breadcrumb, Layout, message, Table,
    Popconfirm, Button, Tooltip} from "antd";
import {HomeOutlined, DeleteTwoTone, EditTwoTone,
    EyeTwoTone, FileUnknownTwoTone} from "@ant-design/icons";
import { BiCode } from "react-icons/bi";
import {FormattedMessage, useIntl} from "react-intl";
import columnSearchProps from "../columnSearchProps";
import {connect} from "react-redux";
import {useEffect, useState} from "react";

import {selectLoading, selectError, selectReports} from "../../redux/report/reportSelector";
import {listReports, deleteReport, clearReportError} from '../../redux/report/reportActions';
import {Link} from "react-router-dom";

const {Content, Footer} = Layout;

const Reports = (props) => {

    //local const
    //-----------
    const intl = useIntl();
    const {reports, count, max, offset} = props.reports;
    const {pathname} = props.location;
    const [config, setConfig] = useState(pathname === '/configuration/reports');
    const [filteredInfo, setFilteredInfo] = useState({});
    useEffect(() => {
        setConfig(pathname === '/configuration/reports');
        setFilteredInfo({});
        //eslint-disable-next-line
    }, [pathname]);


    const columns = [
        {
            title: intl.formatMessage({id: 'label.report.id'}),
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '10ch',
            fixed: true,
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['ascend', 'descend'],
            ...columnSearchProps('id')
        },
        {
            title: intl.formatMessage({id: 'label.report.actions'}),
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            width: '15ch',
            fixed: true,
            render: (_, record) =>
                count >= 1 ? <>
                    {config ? (
                        record.reportFilesStatus.count === 0 ? (
                            <>
                                <DeleteTwoTone twoToneColor="gray"/>
                                <Link to={`/configuration/reports/${record.id}`}>
                                    <EditTwoTone
                                        twoToneColor="#52c41a"
                                        title={intl.formatMessage({id: "reports.edit.title"})}
                                    />
                                </Link>
                            </>
                        ) : (
                            <>
                            <Popconfirm title={intl.formatMessage({id: 'msg.confirm-delete'})}
                                        onConfirm={() => handleDelete(record.id)}>
                                <DeleteTwoTone
                                    twoToneColor="red"
                                    title={intl.formatMessage({id: "reports.delete.title"})}
                                />
                            </Popconfirm>
                            <Link to={`/configuration/reports/${record.id}`}>
                                <EditTwoTone
                                    twoToneColor="#52c41a"
                                    title={intl.formatMessage({id: "reports.edit.title"})}
                                />
                            </Link>
                            </>
                        )
                        ) : null}

                    {/*action: view report*/}
                    {record.reportFilesStatus.existAll ? (
                        !config && record.subscription === 'yes'
                            ? null
                            : <Link
                                to={
                                    config
                                        ? {pathname: `/configuration/reports/view/${record.id}`}
                                        : {pathname: `/reports/view/${record.id}`}
                                }
                            >
                                <EyeTwoTone
                                    twoToneColor="#52c41a"
                                    title={intl.formatMessage({id: "reports.view.title"})}
                                />
                            </Link>
                    ) : (
                        <Tooltip title={config ? intl.formatMessage({id:'label.report.missing.reportFile'}) :
                            intl.formatMessage({id:'label.report.missing.reportFile.cannot-view-contact-admin'})
                        }>
                            <EyeTwoTone twoToneColor="gray"/>
                        </Tooltip>
                    )}
                    {/*action: edit report file*/}
                    {config ? (
                        RegExp('[hH][tT][mM][lL]{0,1}','g').test(record.reportFilesStatus.reportExt) ? (
                            <BiCode style={{opacity: 0.0}}/>
                        ) : (
                            record.reportFilesStatus.reportExists ? (
                                <Link //check that reportFile exists and the associated too.
                                    to={`/configuration/reports/code/${record.id}`}
                                >
                                    <BiCode
                                        title={intl.formatMessage({id: "reports.code.edit.title"})}
                                    />
                                </Link>
                            ) : (
                                <Tooltip title={intl.formatMessage({id:'label.report.missing.reportFile.type.report'})}>
                                    <BiCode style={{color: 'gray'}}/>
                                </Tooltip>
                            )
                        )
                    ) : null}
                    {/*Action edit html*/}
                    {config && record.subscription === 'yes'
                        ? (
                            <Link
                                to={{pathname: `/configuration/reports/output/edit/${record.id}`}}
                            >
                                <EditTwoTone
                                    twoToneColor="orange"
                                    title={intl.formatMessage({id: "reports.output.edit.title"})}
                                />
                            </Link>
                        )
                        : null
                    }
                    {record.subscription === 'yes' && record.editor !== null
                        ? (
                            <Link
                                to={config
                                    ? {pathname: `/configuration/reports/output/view/${record.id}`}
                                    : {pathname: `/reports/output/view/${record.id}`}
                                }
                            >
                                <EyeTwoTone
                                    twoToneColor="orange"
                                    title={intl.formatMessage({id: "reports.output.view.title"})}
                                />
                            </Link>
                        )
                        : null
                    }
                </> : null
        },
        {
            title: intl.formatMessage({id: 'label.report.name'}),
            dataIndex: 'name',
            key: 'name',
            width: '15ch',
            fixed: false,
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('name')
        },
        {
            title: intl.formatMessage({id: 'label.report.description'}),
            dataIndex: 'description',
            key: 'description',
            width: '20ch',
            fixed: false,
            sorter: (a, b) => a.description - b.description,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('description')
        },
        {
            title: intl.formatMessage({id: 'label.report.area'}),
            dataIndex: 'area',
            key: 'area',
            width: '13ch',
            fixed: false,
            sorter: (a, b) => a.area - b.area,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('area')
        },
        {
            title: intl.formatMessage({id: 'label.report.status'}),
            dataIndex: 'status',
            key: 'status',
            width: '13ch',
            fixed: false,
            sorter: (a, b) => a.status - b.status,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            filteredValue: filteredInfo.status || null,
            onFilter: (value, record) => record.status.includes(value),
            filters: [
                {
                    text: intl.formatMessage({id: 'Report.status.draft'}),
                    value: 'draft'
                },
                {
                    text: intl.formatMessage({id: 'Report.status.published'}),
                    value: 'published'
                }
            ],
            render: (text) => {
                return text === 'draft'
                    ? intl.formatMessage({id: "Report.status.draft"})
                    : intl.formatMessage({id: "Report.status.published"})
            }
        },
        {
            title: intl.formatMessage({id: 'label.report.reportFiles.count'}),
            dataIndex: ['reportFilesStatus','count'],
            key: 'reportFilesStatus',
            width: '9ch',
            fixed: false,
            render: (_,record) =>
                record.reportFilesStatus.existAll ? (
                    <span>{record.reportFilesStatus.count}</span>
                ) : (
                    <Tooltip
                        title={config ? intl.formatMessage({id:'label.report.missing.reportFile'}):
                            intl.formatMessage({id:'label.report.missing.reportFile.contact-admin'})}>
                        <FileUnknownTwoTone twoToneColor={'red'} />
                    </Tooltip>
                )
        },
        {
            title: intl.formatMessage({id: 'label.report.reportParameters.count'}),
            dataIndex: ['reportParametersStatus','count'],
            key: 'reportParameters',
            width: '15ch',
            fixed: false
        },
        {
            title: intl.formatMessage({id: 'label.report.type'}),
            dataIndex: 'type',
            key: 'type',
            width: '12ch',
            fixed: false,
            sorter: (a, b) => a.type - b.type,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            filteredValue: filteredInfo.type || null,
            onFilter: (value, record) => record.type.includes(value),
            filters: [
                {
                    text: intl.formatMessage({id: 'label.report.type.customized'}),
                    value: 'customized'
                },
                {
                    text: intl.formatMessage({id: 'label.report.type.standard'}),
                    value: 'standard'
                }
            ],
            render: (text) => {
                switch(text) {
                    case "standard":
                        return intl.formatMessage({id: 'label.report.type.standard'});
                    case "customized":
                        return intl.formatMessage({id: 'label.report.type.customized'});
                    default:
                        return intl.formatMessage({id: 'label.report.type.missing'});
                }
            }
        },
        {
            title: intl.formatMessage({id: 'label.report.output'}),
            dataIndex: 'output',
            key: 'output',
            width: '13ch',
            fixed: false,
            sorter: (a, b) => a.output - b.output,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            filteredValue: filteredInfo.output || null,
            onFilter: (value, record) => record.output.includes(value),
            filters: [
                {
                    text: intl.formatMessage({id: 'label.report.output.html'}),
                    value: 'html'
                },
                {
                    text: intl.formatMessage({id: 'label.report.output.pdf'}),
                    value: 'pdf'
                }
            ],
        },
        {
            title: intl.formatMessage({id: 'label.report.access'}),
            dataIndex: 'access',
            key: 'access',
            width: '14ch',
            fixed: false,
            sorter: (a, b) => a.access - b.access,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            filteredValue: filteredInfo.access || null,
            onFilter: (value, record) => record.access.includes(value),
            filters: [
                {
                    text: intl.formatMessage({id: 'label.report.access.private'}),
                    value: 'private'
                },
                {
                    text: intl.formatMessage({id: 'label.report.access.public'}),
                    value: 'public'
                }
            ],
            render: (text) => {
                switch(text) {
                    case "private":
                        return intl.formatMessage({id: 'label.report.access.private'});
                    case "public":
                        return intl.formatMessage({id: 'label.report.access.public'});
                    default:
                        return intl.formatMessage({id: 'label.report.access.missing'});
                }
            }
        },
        {
            title: intl.formatMessage({id: 'label.report.subscription'}),
            dataIndex: 'subscription',
            key: 'subscription',
            width: '18ch',
            fixed: false,
            sorter: (a, b) => a.subscription - b.subscription,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            filteredValue: filteredInfo.subscription || null,
            onFilter: (value, record) => record.subscription.includes(value),
            filters: [
                {
                    text: intl.formatMessage({id: 'label.report.subscription.no'}),
                    value: 'no'
                },
                {
                    text: intl.formatMessage({id: 'label.report.subscription.yes'}),
                    value: 'yes'
                }
            ],
            render: (text) => {
                return text === 'yes'
                    ? intl.formatMessage({id: 'label.report.subscription.yes'})
                    : intl.formatMessage({id: 'label.report.subscription.no'});
            }
        }
    ];

    //Effects
    //---------
    useEffect(() => { //para cargar datos

        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearReportError());
            };
            error();
        } else {
            const pagination = undefined;
            const filters = config ? {} : {status: ['published'], view: ['view']};
            props.listReports(props.history, pagination, filters);
        }
        // eslint-disable-next-line
    }, [props.error, config]);

    //Handlers
    const handleChange = (pagination, filters, sorter) => {
        if (!config) {
            filters.status = ['published'];
            filters.view = ['view'];
        };

        props.listReports(props.history, pagination, filters, sorter);
        setFilteredInfo(filters);
    };

    const handleDelete = (id) => {
        props.deleteReport(props.history, id);
    };

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    {config ? (
                        <Breadcrumb.Item>
                            <span><FormattedMessage id="menu.configuration" defaultMessage="Configuration"/></span>
                        </Breadcrumb.Item>
                    ) : null
                    }
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.reports" defaultMessage="Reports"/></span>
                    </Breadcrumb.Item>
                    {!config ? (
                        <Breadcrumb.Item>
                            <span><FormattedMessage id="menu.reports.view" defaultMessage="View"/></span>
                        </Breadcrumb.Item>
                    ) : null
                    }
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    {config
                        ?   (
                                <>
                                    <Link to='/configuration/reports/new'><Button type="primary" style={{marginBottom: 16,
                                    marginRight: 10}}>
                                        <FormattedMessage id="label.report.add-report" defaultMessage="Add a Report"/>
                                    </Button></Link>
                                    <Link to='/configuration/reports/reportFiles'><Button type="primary" style={{marginBottom:
                                    16, marginRight: 10}}>
                                        <FormattedMessage id="label.reportFile.reportFiles" defaultMessage="Report Files"/>
                                    </Button></Link>
                                    <Link to='/configuration/reports/reportParameters'><Button type="primary"
                                    style={{marginBottom: 16, marginRight: 10}}>
                                        <FormattedMessage id="label.reportParameter.reportParameters" defaultMessage="Report Parameters"/>
                                    </Button></Link>
                                </>
                            )
                        : null
                    }
                    <Table
                        bordered={true}
                        loading={props.loading}
                        rowKey={record => record.id}
                        responsive={true}
                        pagination={{
                            current: offset / max + 1,
                            showLessItems: true, pageSizeOptions: [10, 20, 50, 100],
                            pageSize: max,
                            defaultPageSize: max, total: count, showSizeChanger: true,
                            // showTotal: ((total, range) => `${range[0]}-${range[1]} of ${total}`)
                            showTotal: ((total, range) => intl.formatMessage({
                                id: 'label.range-total'
                            }, {one: range[0], two: range[1], total: total}))
                        }}
                        size={'default'}
                        showHeader
                        columns={config
                            ? columns
                            : columns.filter(column => !['status'].includes(column.dataIndex))
                        }
                        dataSource={reports}
                        scroll={{y: '65vh', x: '100vw'}}
                        onChange={handleChange}
                    />
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    reports: selectReports(state)
});

export default connect(mapStateToProps,
    {listReports, deleteReport, clearReportError})(Reports);