import { Button, Input, Select, Checkbox, InputNumber, DatePicker, Radio, TimePicker, Switch } from 'antd';
import { LeftOutlined, RightOutlined, MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {MapIcon, VirtualIcon} from "../../utils/icons/CustomIcons"
import "./mobilePreview.css";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { v4 as uuid } from "uuid";

const PreviewForm = ({ form, formItems, formItemsBlock }) => {
    const [mobile, setMobile] = useState(true);
    var dataFormItemsBlock = [];
    dataFormItemsBlock.push(formItemsBlock);
    let [formItemsBlock2, setFormItemsBlock2] = useState(dataFormItemsBlock);
    const intl = useIntl();
    const getHelpPreview = (item) => {
        const getCheckboxes = (options) => {
            const values = [];
            if (options) {
                if (!options.hasOwnProperty("values")) {
                    options["values"] = options;
                }
            } else {
                options = {};
                options["values"] = values;
            }
            if (options.values) {
                options.values.forEach((option, index) => {
                    values.push(<Checkbox key={option}>{option}</Checkbox>);
                }, item)
            }
            return values;
        }
        const getRadioButtons = (options) => {
            const values = [];
            if (options) {
                if (!options.hasOwnProperty("values")) {
                    options["values"] = options;
                }
            } else {
                options = {};
                options["values"] = values;
            }
            if (options.values) {
                options.values.forEach((option, index) => {
                    values.push(<Radio key={option} value={index}>{option}</Radio>);
                })
            }
            return values;
        }
        const getSelectOptions = (options) => {
            const values = [];
            if (options) {
                if (!options.hasOwnProperty("values")) {
                    options["values"] = options;
                }
            } else {
                options = {};
                options["values"] = values;
            }
            if (options.values) {
                options.values.forEach((option, index) => {
                    values.push(<Select.Option key={option} value={index}>{option}</Select.Option>);
                }, item)
            }
            return values;
        }

        let preview;
        const type = item.type;
        switch (type) {
            case 1: preview = <h2 className={item.fontSize}>{item.name}</h2>; break;
            case 2: preview = <div><div className="itemLabel">{item.name}:</div><Input id={item.id} className="input" placeholder={item.name} /></div>; break;
            case 3: preview = <div><div className="itemLabel">{item.name}:</div><Input.TextArea id={item.id} className="textArea" rows={4} placeholder={item.name} /></div>; break;
            case 4: preview = <div><div className="itemLabel">{item.name}:</div><InputNumber id={item.id} className="input" min={0} max={10} placeholder={item.name} step={1} precision={0} /></div>; break;
            case 5: preview = <div><div className="itemLabel">{item.name}:</div><InputNumber id={item.id} className="input" min={0} max={10} step={0.1} precision={item.decimal ? item.decimal : 2} placeholder={item.name} /></div>; break;
            case 6: preview = <div><div className="itemLabel">{item.name}:</div><DatePicker id={item.id} className="input" placeholder={intl.formatMessage({ id: 'label.PLACEHOLDERSELECT' })} suffixIcon={< RightOutlined />} /></div>; break;
            case 7: preview = <div><div className="itemLabel">{item.name}:</div><DatePicker id={item.id} showTime className="input" placeholder={intl.formatMessage({ id: 'label.PLACEHOLDERSELECT' })} suffixIcon={< RightOutlined />} /></div>; break;
            case 8: preview = <div><div className="itemLabel">{item.name}:</div><TimePicker id={item.id} className="input" placeholder={intl.formatMessage({ id: 'label.PLACEHOLDERSELECT' })} suffixIcon={< RightOutlined />} /></div>; break;
            case 9: preview = <div className="simpleCheckbox"><label>{item.name}:</label><Switch id={item.id} defaultChecked={item.value !== undefined && item.value !== "" ? item.value : false} >{item.name}</Switch></div>; break;
            case 10: preview = <><div className="checkbox">{item.name}</div>{getCheckboxes(item.optionValues)}</>; break;
            case 11: preview = <><div className="checkbox">{item.name}</div><Radio.Group id={item.id} >{getRadioButtons(item.optionValues)}</Radio.Group></>; break;
            case 12: preview = <div className="customSelect"><div className="itemLabel">{item.name}:</div><Select id={item.id} className="input" placeholder={intl.formatMessage({ id: 'label.PLACEHOLDERSELECT' })} suffixIcon={< RightOutlined />}>{getSelectOptions(item.optionValues)}</Select></div>; break;
            case 13: preview = <MapIcon />; break;
            case 14: preview = <div><Button block className="ant-btn-primary">{item.name}</Button></div>; break;
            case 15: preview = <div><Button block className="ant-btn-primary">{item.name}</Button></div>; break;
            case 16: preview = <div><Button block className="ant-btn-primary">{item.name}</Button></div>; break;
            case 18: preview = <VirtualIcon />; break;
            case 19: preview = <div><div className="itemLabel">{item.name}:</div><Input id={item.id} className="input" placeholder={item.name} defaultValue={uuid()} /></div>; break;
            default: break;
        }
        return (<div className="item">{preview}</div>);
    }
    const handleReverseChange = () => {
        setMobile(!mobile);
    }
    const clickAddQB = () => {
        //TODO: Meter bien en el arrayList el objeto formItemsBlock
        setFormItemsBlock2([...formItemsBlock2, formItemsBlock2.push(formItemsBlock)]);
    }
    const clickRemoveQB = (element) => {
        var indexRem = element.target.parentElement.id !== "" ? element.target.parentElement.id : element.target.parentElement.parentElement.id;

        var list = formItemsBlock2.filter((item, index) => {
            return index.toString() !== indexRem;
        });
        
        dataFormItemsBlock = dataFormItemsBlock.filter((item, index) => {
            return index.toString() !== indexRem;
        })       

        setFormItemsBlock2(list);
    }

    function insertQBElement(qBlockNumber) {
        function generateFormFields(formFields) {
            formFields.forEach((element, index) => {
                var newUUID = element.uuid.split("*")[0];
                element.uuid = newUUID + '*QB' + qBlockNumber;
                element.id = qBlockNumber + '*' + index;
                element.value = element.value !== undefined ? element.value : "";
            });
            return formFields;
        }
        
        var formItemsBlockNews = generateFormFields(formItemsBlock);
        return formItemsBlockNews.map((itemBlock, index) => (
            <div id={itemBlock.uuid} key={itemBlock.uuid}>
                {getHelpPreview(itemBlock)}
            </div>
        ));
    }

    function getFormItemsFromBlock(item, formItemsBlock) {
        return formItemsBlock.map((qBlockNumber, index) => (
            <div className="bloque" id={"QB"+index} key={index+item.uuid}>
                <div className="bloque-title">
                    <div>QB:<span className="bloque-index">{index+1}</span> {item.name} <MinusCircleOutlined  id={index} onClick={clickRemoveQB}/></div>
                </div>
                <div className="elements">
                    {insertQBElement(index)}
                </div>
            </div>
        ), item);
    }

    return (
        <>
            <div className="devicePreviewButtons">
                <span>{intl.formatMessage({ id: 'label.tablet' })}</span> <Switch size="large" checked={mobile} onChange={handleReverseChange} /> <span>{intl.formatMessage({ id: 'label.mobile' })}</span>
            </div>
            <div className={mobile ? 'devicePreview smartphone' : 'devicePreview tablet'}>
                <div className="content">
                    <h1><LeftOutlined />Form: {form.getFieldValue("name")}</h1>
                    <div className="formItems">
                        {formItems.map((item, index) => (
                            <React.Fragment key={index + '-' + item.name}>
                                <div id={item.uuid}>
                                {item.typeName !== 'QUESTION_BLOCK' && (
                                    getHelpPreview(item)
                                    )}
                                </div>
                                {item.typeName === 'QUESTION_BLOCK' && (
                                    <div>
                                        <div className="bloques">
											{getFormItemsFromBlock(item, formItemsBlock2)} 
                                        </div>
                                        <Button block className="ant-btn-primary" onClick={clickAddQB}><PlusCircleOutlined />Add {item.name}</Button>
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PreviewForm;