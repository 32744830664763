import {REPORT_ACTION_TYPES} from "../typesAction";


const initialState = {
    reports: [],
    current: null,
    loading: false,
    error: null,
    view: null,
    code: null,
    reportOutput: null
};

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case REPORT_ACTION_TYPES.LOAD_REPORT:
            return {
                ...state, loading: true
            };
        case REPORT_ACTION_TYPES.CLEAR_LOAD_REPORT:
            return {
                ...state, loading: false
            };
        case REPORT_ACTION_TYPES.ERROR_REPORT:
            return {
                ...state, error: action.payload, loading: false, current: null
            };
        case REPORT_ACTION_TYPES.CLEAR_REPORT_ERRORS:
            return {
                ...state,
                error: null, loading: false
            };
        case REPORT_ACTION_TYPES.LIST_REPORT:
            return {
                ...state, reports: action.payload, loading: false,
                current: null, view: null, code: null
            };
        case REPORT_ACTION_TYPES.ADD_REPORT:
            return {
                ...state,
                loading: false
            };
        case REPORT_ACTION_TYPES.SHOW_REPORT:
            return {
                ...state,
                current: action.payload, loading: false
            };
        case REPORT_ACTION_TYPES.EDIT_REPORT:
            return {
                ...state,
                current: action.payload, loading: false
            };
        case REPORT_ACTION_TYPES.DELETE_REPORT:
            const updatedReports = state.reports.reports.filter(report => action.payload !== report.id);
            const updatedCount = state.reports.count - 1;
            return {
                ...state, reports: {...state.reports, reports: updatedReports, count: updatedCount}, loading: false
            };
        case REPORT_ACTION_TYPES.VIEW_REPORT:
            return {
                ...state,
                view: action.payload, loading: false
            };
        case REPORT_ACTION_TYPES.SHOW_REPORT_CODE:
            return {
                ...state,
                code: action.payload, loading: false
            };
        case REPORT_ACTION_TYPES.EDIT_REPORT_CODE:
            return {
                ...state,
                code: action.payload, loading: false
            };
        case REPORT_ACTION_TYPES.GET_REPORT_OUTPUT:
            return {
                ...state,
                reportOutput: action.payload, loading: false
            };
        case REPORT_ACTION_TYPES.UPDATE_EDITED_REPORT_OUTPUT:
            return {
                ...state,
                current: null, reportOutput: null, loading: false
            };
        default:
            return state;
    }
};

export default reportReducer;