import {AUTH_ACTION_TYPES, USER_ACTION_TYPES} from "../typesAction";
import setAuthToken from "../../utils/setAuthToken";
import axiosInstance from "../../utils/axiosInstance";

export const login = (history, formData) => async dispatch => {
    try {
        const res = await axiosInstance(history, dispatch).post('/login', formData);
        await setAuthToken(res.data.accessToken);
        dispatch({
            type: AUTH_ACTION_TYPES.LOGIN_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        console.log(err);
    }
};

export const requestResetPassword = (history, formData) => async dispatch => {
    try {
        let {data} = await axiosInstance(history, dispatch).post('/forgot_password', formData);
        return data
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: AUTH_ACTION_TYPES.AUTH_ERROR,
            payload: error
        });
    }
};

export const registerUser = (history, formData, projectCode) => async dispatch => {
    try {
        return await axiosInstance(history, dispatch).post(`/create_user?projectCode=${projectCode}`, formData);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: AUTH_ACTION_TYPES.AUTH_ERROR,
            payload: error
        });
    }
};

export const registerNewUser = (history, formData) => async dispatch => {
    try {
        return await axiosInstance(history, dispatch).post(`/create_new_user`, formData);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: AUTH_ACTION_TYPES.AUTH_ERROR,
            payload: error
        });
    }
};


export const resetPassword = (history, formData) => async dispatch => {
    try {
        let {data} = await axiosInstance(history, dispatch).post('/reset_password', formData);
        return data
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: AUTH_ACTION_TYPES.AUTH_ERROR,
            payload: error
        });
    }
};

export const clearLoginError = () => {
    return {type: AUTH_ACTION_TYPES.CLEAR_AUTH_ERRORS}
};

export const logout = (history) => dispatch => {
    try {
        axiosInstance(history).post('/logout');

        dispatch({
            type: AUTH_ACTION_TYPES.LOGOUT
        });

        dispatch({
            type: USER_ACTION_TYPES.CLEAR_CURRENT_USER
        });
        localStorage.removeItem("token");

    } catch (err) {
        console.log(err);
        dispatch({
            type: AUTH_ACTION_TYPES.LOGOUT
        });

        dispatch({
            type: USER_ACTION_TYPES.CLEAR_CURRENT_USER
        });
    }
};