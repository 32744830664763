export const MAPBOX_STYLES = [
    //https://docs.mapbox.com/api/maps/styles/
    {label: 'Streets', value: 'streets', url: "mapbox://styles/mapbox/streets-v11"},
    {label: 'Outdoors', value: 'outdoors', url: "mapbox://styles/mapbox/outdoors-v11"},
    {label: 'Light', value: 'light', url: "mapbox://styles/mapbox/light-v10"},
    {label: 'Dark', value: 'dark', url: "mapbox://styles/mapbox/dark-v10"},
    {label: 'Satellite', value: 'satellite', url: "mapbox://styles/mapbox/satellite-v9"},
    {label: 'Satellite streets', value: 'satellite-streets', url: "mapbox://styles/mapbox/satellite-streets-v11"},
    {label: 'Navigation day', value: 'navigation-day', url: "mapbox://styles/mapbox/navigation-day-v1"},
    {label: 'Navigation night', value: 'navigation-night', url: "mapbox://styles/mapbox/navigation-night-v1"},
];
