import * as turf from '@turf/turf';
import {message} from "antd";

export const getBordersSourceData = (dataRegionsRecords, intl) => {

        //setting features
        let bordersSourceData;
        let features = [];
        let undefinedOrNullRecordGeojson = [];
        let unknownRecordGeoJson = [];
        dataRegionsRecords.forEach(record => {
            if (record['geojson']) {
                const isGeom = ['type','coordinates'].every(key => Object.keys(record['geojson']).includes(key))
                    let geometry;
                    if (isGeom) {
                        //userGeoArea case
                        geometry = record['geojson']
                    } else if (record['geojson']?.value) {
                        //geoRegions case
                        geometry = record['geojson']?.value ? JSON.parse(record['geojson'].value) : null;
                    } else if (record['geojson']?.geometry) {
                        geometry = record['geojson'].geometry
                    } else {
                        unknownRecordGeoJson.push(record['geojson']);
                    }

                    if (geometry !== null) {
                        //Note: when argument dataRegionsRecords is equivalent to
                        //userGeoArea the record's keys are: name, code and levelup
                        //but when it is to
                        //geoRegions the record's keys are: name, regionCode, regionCodeLevelUp
                        const properties = {
                            "name": record['name'],
                            "regionCode": record['code'] ? record['code'] :record['regionCode'],
                            "regionCodeLevelUp": record['levelup'] ? record['levelup'] : record['regionCodeLevelUp']
                        };

                        features.push(turf.feature(geometry, properties))
                    }
            } else {
                undefinedOrNullRecordGeojson.push(record);
            }
        });

        //TODO: add messagesList logic
        if (undefinedOrNullRecordGeojson.length > 0) {
            message.warning(intl.formatMessage({id: "getBordersSourceData.undefinedOrNullRecordGeojson.msg"}));
            console.log(intl.formatMessage({id: "getBordersSourceData.undefinedOrNullRecordGeojson"},
            {undefinedOrNullRecordGeojson: undefinedOrNullRecordGeojson}));
        };

        if (unknownRecordGeoJson.length > 0) {
            message.error(intl.formatMessage({id: "getBordersSourceData.unknownRecordGeoJson.msg"}));
            console.log(intl.formatMessage({id: "getBordersSourceData.unknownRecordGeoJson"},
                {unknownRecordGeoJson: unknownRecordGeoJson}))
        }
        //bordersSourceData
        bordersSourceData = turf.featureCollection(features);

        return bordersSourceData
    };