import {Form, Input, Radio, Select, message, Breadcrumb, Layout, Card, Button} from 'antd';
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {
    addDataSourceConfiguration,
    getDataSourceConfiguration,
    editDataSourceConfiguration,
    clearDataSourceConfigurationError,
    clearLoadingApiForms,
    clearApiFormsError,
    listApiForms

} from "../../redux/dataSourceConfiguration/dataSourceConfigurationActions";
import {HomeOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {
    selectError,
    selectLoading,
    selectCurrentDataSourceConfiguration,
    selectErrorApiForms,
    selectLoadingApiForms,
    selectApiForms
} from "../../redux/dataSourceConfiguration/dataSourceConfigurationSelector";

import FormItemsApiOpenWeather from "./utils/FormItemsApiOpenWeather";

import moment from "moment";

import {editApiFormData} from "./utils/helpersForDataSourceConfigurations";

const {Content, Footer} = Layout;


const DataSourceConfigurationForm = (props) => {

    const intl = useIntl();
    const [dataSourceConfigurationCreated, setDataSourceConfigurationCreated] = useState(false);
    const [edit, setEdit] = useState(props.location.pathname !== '/configuration/datasources/new');
    const [form] = Form.useForm();
    const [optionsForms, setOptionsForms] = useState(null);
    const [dataSourceFormNameNormalized, setDataSourceFormNameNormalized] = useState(null);
    const [dataSourceFormId, setDataSourceFormId] = useState(null);
    const [changedFields, setChangedFields] = useState(null);

    const timeFormat = 'HH:mm';
    const onFinish = (values) => {

        if (values.configuration.startTime) {
            values.configuration.startTime = values.configuration.startTime.format(timeFormat)
        }

        //patch updated weatherStations to database
        if (values.configuration.updatedWeatherStations && values.configuration.updatedWeatherStations.length > 0) {
            const updatedWeatherStations = values.configuration.updatedWeatherStations.map(updatedStation => {
                return JSON.parse(updatedStation)
            });

            updatedWeatherStations.forEach(updatedStationObj => {
                const {form_id, data_id, form} = updatedStationObj;
                editApiFormData(form_id, data_id, form)()
            });
            //reset to initial values "updatedWeatherStations"
            values.configuration.updatedWeatherStations = [];
        }

        if (edit) {
            props.editDataSourceConfiguration(props.history, props.match.params.id, values, setDataSourceConfigurationCreated);
        } else {
            props.addDataSourceConfiguration(props.history, values, setDataSourceConfigurationCreated)
            .then(() => setDataSourceFormNameNormalized(null));
        }
    };

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    useEffect(() => {
      if (props.errorApiForms) {
          const error = () => {
              if (typeof props.errorApiForms == "string") {
                  message.error(props.errorApiForms).then(props.clearApiFormsError());
              } else {
                  props.errorApiForms.errors.map(errorMessage =>
                      (message.error(errorMessage.message).then(props.clearApiFormsError())));
              }

          };
          error();
      }

      if (props.errorApiForms === null && props.apiForms.length === 0) {
        props.listApiForms(props.history)
      }
        //eslint-disable-next-line
    }, [props.errorApiForms]);

    //setOptionsForms
    useEffect(() => {
      if (props.apiForms.count > 0 && optionsForms === null) {
        props.clearLoadingApiForms();
        setOptionsForms(
            props.apiForms.forms.map(apiForm => {
                 return {
                   label: apiForm.name,
                   value: apiForm.id
                 }
               }
           )
       )
      }

      //eslint-disable-next-line
    }, [props.apiForms]);

    //load props.dataSourceConfiguration and set fields values (form.setFieldsValue)
    useEffect(() => {

        if (props.error) {
            const error = () => {
                if (typeof props.error == "string") {
                    message.error(props.error).then(props.clearDataSourceConfigurationError());
                } else {
                    if (Array.isArray(props.error?.errors)) {
                        props.error.errors.map(errorMessage =>
                            (message.error(errorMessage.message).then(props.clearDataSourceConfigurationError())));
                    } else {
                        message.error(props.error.message).then(props.clearDataSourceConfigurationError());
                    }
                }
            };
            error();
        }

        if (edit && !dataSourceConfigurationCreated && props.error === null) {
            props.getDataSourceConfiguration(props.history, props.match.params.id).then((data) => {
                if (data) {
                    if (data.configuration.startTime) {
                        data.configuration.startTime = moment(data.configuration.startTime, timeFormat)
                    }
                    form.setFieldsValue({
                        'name': data.name,
                        'description': data.description,
                        'active': data.active,
                        'configuration': data.configuration
                    });
                } else {
                    setEdit(false);
                }
            })
        }

        if (dataSourceConfigurationCreated) {
            if (edit) {
                message.info(intl.formatMessage({id: "label.dataSourceConfiguration-updated"}));
            } else {
                form.resetFields();
                message.info(intl.formatMessage({id: "label.dataSourceConfiguration-created"}));
            }
            setDataSourceConfigurationCreated(false);
        }
        // eslint-disable-next-line
    }, [props.error, dataSourceConfigurationCreated, edit, props.apiForms])

    //setDataSourceFormNameNormalized, setDataSourceFormId
    useEffect(() => {
        if (edit && props.apiForms?.forms?.length > 0 && props.dataSourceConfiguration !== null) {
            const formId = props.dataSourceConfiguration.configuration.formId;
            setDataSourceFormNameNormalized(() => {
                return props.apiForms.forms.find(apiForm => apiForm.id === formId).nameNormalized
            });
            setDataSourceFormId(formId)
        }

        //eslint-disable-next-line
    }, [edit, props.apiForms, props.dataSourceConfiguration])

    //update setChangedFields, setDataSourceFormId, setDataSourceFormNameNormalized
    const onFieldsChange = (changedFields) => {
            setChangedFields(changedFields);

            if (changedFields.length === 1) {
                if (changedFields[0].name.includes('formId')) {
                    setDataSourceFormId(changedFields[0].value);
                    setDataSourceFormNameNormalized(() => {
                        return props.apiForms.forms.find(apiForm => apiForm.id === changedFields[0].value).nameNormalized
                    })
                }
            }
        };

    //jsx
    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.dataSourceConfigurations" defaultMessage="DataSourceConfigurations"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>{edit ? (<FormattedMessage
                            id="label.edit" defaultMessage="Edit"/>) : (
                            <FormattedMessage id="label.new" defaultMessage="New"/>)}</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Card type="inner"
                          title={edit ? <FormattedMessage id="label.edit-dataSourceConfiguration" defaultMessage="Edit DataSourceConfiguration"/> :
                              <FormattedMessage id="label.create-dataSourceConfiguration" defaultMessage="Create a DataSourceConfiguration"/>}
                          extra={<Link to='/configuration/datasources'><FormattedMessage id="label.return"
                                                                        defaultMessage="Return"/></Link>}>
                        <Form layout="horizontal" name="dataSourceConfiguration_form" size="large"
                              labelCol={{span: 3,}} wrapperCol={{span: 14,}}
                              initialValues={{active: true}} form={form}
                              onFinish={onFinish} onFinishFailed={onFinishFailed}
                              onFieldsChange={onFieldsChange}>
                            <Form.Item
                                name="name"
                                label={intl.formatMessage({id: 'label.name'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item name="description" label={intl.formatMessage({id: 'label.description'})}
                                       rules={[{
                                           required: true,
                                           message: intl.formatMessage({id: 'msg.input-required'})
                                       }]}>
                                <Input.TextArea/>
                            </Form.Item>
                            <Form.Item name="active" label={intl.formatMessage({id: 'label.active'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                                >
                                <Radio.Group>
                                    <Radio value={true}><FormattedMessage id="label.yes" defaultMessage="Yes"/></Radio>
                                    <Radio value={false}><FormattedMessage id="label.no" defaultMessage="No"/></Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                name={["configuration","formId"]}
                                label={intl.formatMessage({id: "label.DataSourceConfigurationForm.configuration.formId"})}
                                rules={[{
                                   required: true,
                                   message: intl.formatMessage({id: 'msg.input-required'})
                                }]}>
                                    <Select options={optionsForms}/>
                            </Form.Item>
                            {
                                (() => {
                                    switch (dataSourceFormNameNormalized) {
                                        case 'api_open_weather_data':
                                            return (
                                                <FormItemsApiOpenWeather
                                                    form={form}
                                                    dataSourceFormId={dataSourceFormId}
                                                    edit={edit}
                                                    changedFields={changedFields}
                                                    dataSourceConfiguration={props.dataSourceConfiguration}
                                                />
                                            );
                                        default:
                                            return null
                                    }
                                })()
                            }
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={props.loading}>
                                    {edit ? (<FormattedMessage id="label.update" defaultMessage="Update"/>) : (
                                        <FormattedMessage id="label.submit" defaultMessage="Submit"/>)}

                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    dataSourceConfiguration: selectCurrentDataSourceConfiguration(state),
    loadingApiForms: selectLoadingApiForms(state),
    errorApiForms: selectErrorApiForms(state),
    apiForms: selectApiForms(state)
});

export default connect(mapStateToProps, {
    addDataSourceConfiguration,
    getDataSourceConfiguration,
    editDataSourceConfiguration,
    clearDataSourceConfigurationError,
    clearLoadingApiForms,
    clearApiFormsError,
    listApiForms
})(DataSourceConfigurationForm);
