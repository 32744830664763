/*
Color schemes based in data from https://colorbrewer2.org
VA-Team
 */

export const COLOR_SCHEMES = [
    {
        'natureOfData': 'sequential',
        'colorSchemes': [
            {
                label: 'YellowOrangeRed',
                name: '9-class-YlOrRd',
                scale: ['#ffffcc','#ffeda0','#fed976','#feb24c',
                    '#fd8d3c','#fc4e2a','#e31a1c','#bd0026',
                    '#800026'],
                url: 'https://colorbrewer2.org/#type=sequential&scheme=YlOrRd&n=9',
                img: '9-class-YlOrRd.svg'
            },
            {
                label: 'YellowOrangeBrown',
                name: '9-class-YlOrBr',
                scale: ['#ffffe5','#fff7bc','#fee391','#fec44f',
                    '#fe9929','#ec7014','#cc4c02','#993404',
                    '#662506'],
                url: 'https://colorbrewer2.org/#type=sequential&scheme=YlOrBr&n=9',
                img: '9-class-YlOrBr.svg'
            },
            {
                label: 'YellowGreenBlue',
                name: '9-class-YlGnBu',
                scale: ['#ffffd9','#edf8b1','#c7e9b4','#7fcdbb',
                    '#41b6c4','#1d91c0','#225ea8','#253494',
                    '#081d58'],
                url: 'https://colorbrewer2.org/#type=sequential&scheme=YlGnBu&n=9',
                img: '9-class-YlGnBu.svg'
            },
            {
                label: 'YellowGreen',
                name: '9-class-YlGn',
                scale: ['#ffffe5','#f7fcb9','#d9f0a3','#addd8e',
                    '#78c679','#41ab5d','#238443','#006837',
                    '#004529'],
                url: 'https://colorbrewer2.org/#type=sequential&scheme=YlGn&n=9',
                img: '9-class-YlGn.svg'
            },
            {
                label: 'RedPurple',
                name: '9-class-RdPu',
                scale: ['#fff7f3','#fde0dd','#fcc5c0','#fa9fb5',
                    '#f768a1','#dd3497','#ae017e','#7a0177',
                    '#49006a'],
                url: 'https://colorbrewer2.org/#type=sequential&scheme=RdPu&n=9',
                img: '9-class-RdPu.svg'
            },
            {
                label: 'PurpleRed',
                name: '9-class-PuRd',
                scale: ['#f7f4f9','#e7e1ef','#d4b9da','#c994c7',
                    '#df65b0','#e7298a','#ce1256','#980043',
                    '#67001f'],
                url: 'https://colorbrewer2.org/#type=sequential&scheme=PuRd&n=9',
                img: '9-class-PuRd.svg'
            },
            {
                label: 'PurpleBlueGreen',
                name: '9-class-PuBuGn',
                scale: ['#fff7fb','#ece2f0','#d0d1e6','#a6bddb',
                    '#67a9cf','#3690c0','#02818a','#016c59',
                    '#014636'],
                url: 'https://colorbrewer2.org/#type=sequential&scheme=PuBuGn&n=9',
                img: '9-class-PuBuGn.svg'
            },
            {
                label: 'OrangeRed',
                name: '9-class-OrRd',
                scale: ['#fff7ec','#fee8c8','#fdd49e','#fdbb84',
                    '#fc8d59','#ef6548','#d7301f','#b30000',
                    '#7f0000'],
                url: 'https://colorbrewer2.org/#type=sequential&scheme=OrRd&n=9',
                img: '9-class-OrRd.svg'
            },
            {
                label: 'Greys',
                name: '9-class-Greys',
                scale: ['#ffffff','#f0f0f0','#d9d9d9','#bdbdbd',
                    '#969696','#737373','#525252','#252525',
                    '#000000'],
                url: 'https://colorbrewer2.org/?type=sequential&scheme=Greys&n=9',
                img: '9-class-Greys.svg'
            },
            {
                label: 'Reds',
                name: '9-class-Reds',
                scale: ['#fff5f0','#fee0d2','#fcbba1','#fc9272',
                    '#fb6a4a','#ef3b2c','#cb181d','#a50f15',
                    '#67000d'],
                url: 'https://colorbrewer2.org/#type=sequential&scheme=Reds&n=9',
                img: '9-class-Reds.svg'
            },
            {
                label: 'Oranges',
                name: '9-class-Oranges',
                scale: ['#fff5eb','#fee6ce','#fdd0a2','#fdae6b',
                    '#fd8d3c','#f16913','#d94801','#a63603',
                    '#7f2704'],
                url: 'https://colorbrewer2.org/?type=sequential&scheme=Oranges&n=9',
                img: '9-class-Oranges.svg'
            },
            {
                label: 'Purples',
                name: '9-class-Purples',
                scale: ['#fcfbfd','#efedf5','#dadaeb','#bcbddc',
                    '#9e9ac8','#807dba','#6a51a3','#54278f',
                    '#3f007d'],
                url: 'https://colorbrewer2.org/?type=sequential&scheme=Purples&n=9',
                img: '9-class-Purples.svg'
            },
            {
                label: 'Greens',
                name: '9-class-Greens',
                scale: ['#f7fcf5','#e5f5e0','#c7e9c0','#a1d99b',
                    '#74c476','#41ab5d','#238b45','#006d2c',
                    '#00441b'],
                url: '',
                img: '9-class-https://colorbrewer2.org/?type=sequential&scheme=Greens&n=9Greens.svg'
            },
            {
                label: 'Blues',
                name: '9-class-Blues',
                scale: ['#f7fbff','#deebf7','#c6dbef','#9ecae1',
                    '#6baed6','#4292c6','#2171b5','#08519c',
                    '#08306b'],
                url: 'https://colorbrewer2.org/?type=sequential&scheme=Blues&n=9',
                img: '9-class-Blues.svg'
            }
        ]
    },
    {
        'natureOfData': 'diverging',
        'colorSchemes': [
            {
                label: 'BrownBlueGreen',
                name: '9-class-BrBG',
                scale: ['#8c510a','#bf812d','#dfc27d','#f6e8c3',
                    '#f5f5f5','#c7eae5','#80cdc1','#35978f',
                    '#01665e'],
                url: 'https://colorbrewer2.org/#type=diverging&scheme=BrBG&n=9',
                img: '9-class-BrBG.svg'
            },
            {
                label: 'PinkYellowGreen',
                name: '9-class-PiYG',
                scale: ['#c51b7d','#de77ae','#f1b6da','#fde0ef',
                    '#f7f7f7','#e6f5d0','#b8e186','#7fbc41',
                    '#4d9221'],
                url: 'https://colorbrewer2.org/?type=diverging&scheme=PiYG&n=9',
                img: '9-class-PiYG.svg'
            },
            {
                label: 'PinkRedGreen',
                name: '9-class-PRGn',
                scale: ['#762a83','#9970ab','#c2a5cf','#e7d4e8',
                    '#f7f7f7','#d9f0d3','#a6dba0','#5aae61',
                    '#1b7837'],
                url: 'https://colorbrewer2.org/?type=diverging&scheme=PRGn&n=9',
                img: '9-class-PRGn.svg'
            },
            {
                label: 'PurpleOrange',
                name: '9-class-PuOr',
                scale: ['#b35806','#e08214','#fdb863','#fee0b6',
                    '#f7f7f7','#d8daeb','#b2abd2','#8073ac',
                    '#542788'],
                url: 'https://colorbrewer2.org/?type=diverging&scheme=PuOr&n=9',
                img: '9-class-PuOr.svg'
            },
            {
                label: 'RedBlue',
                name: '9-class-RdBu',
                scale: ['#b2182b','#d6604d','#f4a582','#fddbc7',
                    '#f7f7f7','#d1e5f0','#92c5de','#4393c3',
                    '#2166ac'],
                url: 'https://colorbrewer2.org/?type=diverging&scheme=RdBu&n=9',
                img: '9-class-RdBu.svg'
            },
            {
                label: 'RedGreys',
                name: '9-class-RdGy',
                scale: ['#b2182b','#d6604d','#f4a582','#fddbc7','#ffffff','#e0e0e0','#bababa','#878787','#4d4d4d'],
                url: 'https://colorbrewer2.org/?type=diverging&scheme=RdGy&n=9',
                img: '9-class-RdGy.svg'
            },
            {
                label: 'RedYellowBlue',
                name: '9-class-RdYlBu',
                scale: ['#d73027','#f46d43','#fdae61','#fee090',
                    '#ffffbf','#e0f3f8','#abd9e9','#74add1',
                    '#4575b4'],
                url: 'https://colorbrewer2.org/?type=diverging&scheme=RdYlBu&n=9',
                img: '9-class-RdYlBu.svg'
            },
            {
                label: 'RedYellowGreen',
                name: '9-class-RdYlGn',
                scale: ['#d73027','#f46d43','#fdae61','#fee08b',
                    '#ffffbf','#d9ef8b','#a6d96a','#66bd63',
                    '#1a9850'],
                url: 'https://colorbrewer2.org/?type=diverging&scheme=RdYlGn&n=9',
                img: '9-class-RdYlGn.svg'
            },
            {
                label: 'Spectral',
                name: '9-class-Spectral',
                scale: ['#d53e4f','#f46d43','#fdae61','#fee08b',
                    '#ffffbf','#e6f598','#abdda4','#66c2a5',
                    '#3288bd'],
                url: 'https://colorbrewer2.org/?type=diverging&scheme=Spectral&n=9',
                img: '9-class-Spectral.svg'
            }
        ]
    },
    {
        'natureOfData': 'qualitative',
        'colorSchemes': [
            {
                label: 'Paired-9',
                name: '9-class-Paired',
                scale: ['#a6cee3','#1f78b4','#b2df8a','#33a02c',
                    '#fb9a99','#e31a1c','#fdbf6f','#ff7f00',
                    '#cab2d6'],
                url: 'https://colorbrewer2.org/?type=qualitative&scheme=Paired&n=9',
                img: '9-class-Paired.svg'
            },
            {
                label: 'Paired-12',
                name: '12-class-Paired',
                scale: ['#a6cee3','#1f78b4','#b2df8a','#33a02c',
                    '#fb9a99','#e31a1c','#fdbf6f','#ff7f00',
                    '#cab2d6','#6a3d9a','#ffff99','#b15928'],
                url: 'https://colorbrewer2.org/?type=qualitative&scheme=Paired&n=12',
                img: '12-class-Paired'
            },
            {
                label: 'Pastel1',
                name: '9-class-Pastel1',
                scale: ['#fbb4ae','#b3cde3','#ccebc5','#decbe4',
                    '#fed9a6','#ffffcc','#e5d8bd','#fddaec',
                    '#f2f2f2'],
                url: 'https://colorbrewer2.org/?type=qualitative&scheme=Pastel1&n=9',
                img: '9-class-Pastel1.svg'
            },
            {
                label: 'Set1',
                name: '9-class-Set1',
                scale: ['#e41a1c','#377eb8','#4daf4a','#984ea3',
                    '#ff7f00','#ffff33','#a65628','#f781bf',
                    '#999999'],
                url: 'https://colorbrewer2.org/?type=qualitative&scheme=Set1&n=9',
                img: '9-class-Set1'
            },
            {
                label: 'Set3',
                name: '9-class-Set3',
                scale: ['#8dd3c7','#ffffb3','#bebada','#fb8072',
                    '#80b1d3','#fdb462','#b3de69','#fccde5',
                    '#d9d9d9'],
                url: 'https://colorbrewer2.org/#type=qualitative&scheme=Set3&n=9',
                img: '9-class-Set3'
            },
            {
                label: 'Custom1',
                name: '7-class-Custom',
                scale: ["#f9f905","#65F045",  "#FF4CFF", "#18e4df",
                    "#F37022","#3ddf16","#ffa500","#a902a9"],
                url: null,
                img: '7-class-Custom1'
            },
            {
                label: 'Custom2',
                name: '7-class-Custom2',
                scale: ["#800000","#ffa84f", "#ff0000", "#8ecb6d",
                    "#eb4e2c","#F3F3F3","#0059A9","#A9A9AD"],
                url: null,
                img: '7-class-Custom2'
            },
            {
                label: 'Custom-2Colors',
                name: '7-class-Custom2',
                scale: ["#f9f905","#ffa84f"],
                url: null,
                img: '7-class-Custom2'
            }
        ]
    }
];
