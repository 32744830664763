import { Layout } from "antd";
import { useIntl } from "react-intl";

const NotFound = (props) => {
  const intl = useIntl();
  const { Content, Footer } = Layout;

  const urlDetails = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }`;

  const pathName = props?.location?.state?.from;
  const actualPathName = pathName ? pathName : props.location.pathname;

  const fullUrl = `${urlDetails}${actualPathName}`;

  return (
    <Layout className="site-layout">
      <Content style={{ margin: "0 16px" }}>
        <h1>{intl.formatMessage({ id: "NotFound.message.code" })}</h1>
        <p>{intl.formatMessage({ id: "NotFound.message.description" })}</p>
        <p>
          <a href={fullUrl}>{fullUrl}</a>
        </p>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        ©{new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};

export default NotFound;
