import * as d3 from 'd3';

export const selectColors = (colors, m) => {
    if (!colors || colors?.length === 0 || !m) return [];

    // Generar los colores de la escala cuantitativa en aumento de intensidad
    const n = colors.length;
    const scale = d3.scaleLinear()
        .domain([0, n - 1])
        .range([0, 1]);
    const colorScale = d3.scaleSequential()
        .domain([0, 1])
        .interpolator(d3.interpolateRgbBasis(colors));
    const colorArray = new Array(n);
    for (let i = 0; i < n; ++i) {
        colorArray[i] = colorScale(scale(i));
    }

    // Seleccionar los M colores con mayor contraste
    let selectedColors = new Array(m);
    let maxContrast = -1;
    for (let i = 0; i < n; ++i) {
        for (let j = i + 1; j < n; ++j) {
            const contrast = getContrast(colorArray[i], colorArray[j]);
            if (contrast > maxContrast) {
                maxContrast = contrast;
                selectedColors[0] = colorArray[i];
                selectedColors[1] = colorArray[j];
            }
        }
    }
    for (let i = 2; i < m; ++i) {
        maxContrast = -1;
        for (let j = 0; j < n; ++j) {
            if (!selectedColors.includes(colorArray[j])) {
                const contrast = getMinContrast(colorArray[j], selectedColors);
                if (contrast > maxContrast) {
                    maxContrast = contrast;
                    selectedColors[i] = colorArray[j];
                }
            }
        }
    }

    return selectedColors;
};

const getContrast = (color1, color2) => {
    const l1 = getLuminance(color1);
    const l2 = getLuminance(color2);
    return (l1 + 0.05) / (l2 + 0.05);
};

const getMinContrast = (color, colors) => {
    let minContrast = Number.MAX_VALUE;
    for (let i = 0; i < colors.length; ++i) {
        const contrast = getContrast(color, colors[i]);
        if (contrast < minContrast) {
            minContrast = contrast;
        }
    }
    return minContrast;
};

const getLuminance = (color) => {
    const rgbColor = d3.rgb(color);
    return rgbColor.r * 0.2126 + rgbColor.g * 0.7152 + rgbColor.b * 0.0722;
};


/**
 * 
 * @param {Array<Object<index,color>>} colors 
 * @param {BigInt} M 
 * @returns {Array<String>}
 */
export const getMaximumDistanceColors = (colors, M) => {
    if (!colors || colors?.length === 0 || !M) return [];

    // Sort the colors by index in ascending order.
    colors.sort((a, b) => a.index - b.index);

    // Create an array to store the maximum distance colors.
    const maximumDistanceColors = [];

    // Iterate over the colors and add the colors with the maximum distance to the maximumDistanceColors array.
    for (let i = 0; i < M; i++) {
        // Find the color with the maximum distance to the colors that have already been added to the maximumDistanceColors array.
        let maxDistanceColor = colors[i];
        for (let j = i + 1; j < colors.length; j++) {
            if (Math.abs(maxDistanceColor?.index - colors[j].index) > 
            Math.abs(maxDistanceColor?.index - maximumDistanceColors[maximumDistanceColors.length - 1]?.index)) {
                maxDistanceColor = colors[j];
            }
        }

        // Add the color with the maximum distance to the maximumDistanceColors array.
        maximumDistanceColors.push(maxDistanceColor);
    }

    // Return the array of maximum distance colors.
    return maximumDistanceColors;
};


export const selectColorsSimple = (colors, m) => {
    if (!colors || colors?.length === 0 || !m) return [];

    const numberColors = colors.length;

    if (m >= numberColors) return colors;

    const quotient = Math.floor(numberColors/m);

    let selectedColors = [];

    for (let i = 0; i < m; i++) {
        selectedColors.push(colors[i*quotient])
    }

    return selectedColors;
};
