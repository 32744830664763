import {FORM_DATA_ACTION_TYPES} from "../../typesAction";


const initialState = {
    formData: [],
    current: null,
    loading: false,
    error: null,
    formConfiguration: null
}

const formDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case FORM_DATA_ACTION_TYPES.LOAD_FORM_DATA:
            return {
                ...state, loading: true
            }
        case FORM_DATA_ACTION_TYPES.CLEAR_LOAD_FORM_DATA:
            return {
                ...state, loading: false
            }
        case FORM_DATA_ACTION_TYPES.ERROR_FORM_DATA:
            return {
                ...state, error: action.payload, loading: false, current: null
            }
        case FORM_DATA_ACTION_TYPES.CLEAR_FORM_DATA_ERRORS:
            return {
                ...state,
                error: null, loading: false
            }
        case FORM_DATA_ACTION_TYPES.LIST_FORM_DATA:
            return {
                ...state, formData: action.payload, loading: false, current: null
            }
        case FORM_DATA_ACTION_TYPES.ADD_FORM_DATA:
            return {
                ...state,
                loading: false
            }
        case FORM_DATA_ACTION_TYPES.SHOW_FORM_DATA:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case FORM_DATA_ACTION_TYPES.EDIT_FORM_DATA:
            return {
                ...state,
                loading: false
            }
        case FORM_DATA_ACTION_TYPES.DELETE_FORM_DATA:
            const updatedFormData = state.formData.data.filter(formData => action.payload !== formData.id)
            const updatedCount = state.formData.count - 1
            return {
                ...state, formData: {...state.formData, data: updatedFormData, count: updatedCount}, loading: false
            }
        case FORM_DATA_ACTION_TYPES.GET_FORM_CONFIGURATION:
            return {
                ...state,
                formConfiguration: action.payload,
            }
        default:
            return state;
    }

};

export default formDataReducer;