import {POINTS_OF_INTEREST_ACTION_TYPES} from "../typesAction";


const initialState = {
    pointsOfInterests: [],
    current: null,
    loading: false,
    error: null
}

const pointsOfInterestReducer = (state = initialState, action) => {
    switch (action.type) {
        case POINTS_OF_INTEREST_ACTION_TYPES.LOAD_POINTS_OF_INTEREST:
            return {
                ...state, loading: true
            }
        case POINTS_OF_INTEREST_ACTION_TYPES.CLEAR_LOAD_POINTS_OF_INTEREST:
            return {
                ...state, loading: false
            }
        case POINTS_OF_INTEREST_ACTION_TYPES.ERROR_POINTS_OF_INTEREST:
            return {
                ...state, error: action.payload, loading: false, current: null
            }
        case POINTS_OF_INTEREST_ACTION_TYPES.CLEAR_POINTS_OF_INTEREST_ERRORS:
            return {
                ...state,
                error: null, loading: false
            }
        case POINTS_OF_INTEREST_ACTION_TYPES.LIST_POINTS_OF_INTEREST:
            return {
                ...state, pointsOfInterests: action.payload, loading: false, current: null
            }
        case POINTS_OF_INTEREST_ACTION_TYPES.ADD_POINTS_OF_INTEREST:
            return {
                ...state,
                loading: false
            }
        case POINTS_OF_INTEREST_ACTION_TYPES.SHOW_POINTS_OF_INTEREST:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case POINTS_OF_INTEREST_ACTION_TYPES.EDIT_POINTS_OF_INTEREST:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case POINTS_OF_INTEREST_ACTION_TYPES.DELETE_POINTS_OF_INTEREST:

            return {
                ...state, loading: false
            }
        default:
            return state;
    }

};

export default pointsOfInterestReducer;