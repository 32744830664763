import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import * as turf from '@turf/turf';

import {scrollToAnchor} from "../../../utils/dataAnalysis/utils/utils";

import {MAPBOX_STYLES} from "../../../utils/dataAnalysis/Plots/CustomMapboxControls/StylesControl/MAPBOX_STYLES";
import StylesControlByRadioVersion1p0
    from "../../../utils/dataAnalysis/Plots/CustomMapboxControls/StylesControl/StylesControlByRadioVersion1p0";
import LegendControl from "../../../utils/dataAnalysis/Plots/CustomMapboxControls/LegendControl/LegendControl";

import {getBordersSourceData} from "../../../utils/dataAnalysis/utils/getBordersSourceData";

import {ShowMessages} from "../../../utils/dataAnalysis/CustomMessages/ShowMessages";

import {getApiForm, getRegionsWithGeojson, listApiFormByNameNormalized} from './helpersForDataSourceConfigurations';

import {useDispatch} from "react-redux";
import {getUserGeoArea} from "../../../utils/utilData";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

const PlotPointMapWeatherStations = (props) => {

    const intl = useIntl();
    const dispatch = useDispatch();

    const {
        form,
        edit,
        locationsObjectChanged,
        setActiveWeatherStations
    } = props;

    const [isLoadingStationsMap, setIsLoadingStationsMap] = useState(true);

    //setting states --------------
    //messages to be shown to user
    const [messagesList, setMessagesList] = useState([]);
    //end setting states ------------------

    //the map  ---------------------------------------
    const mapRef= useRef(null); //shortcut to ref current

    /**
    * dRef is a mutable object that contains the data structure that is used to initialize the map
    * and is also used to manage the changes of map's states during user interaction.
    */
    const buildMapDataObj = async () => {

        let messagesList = [];

        const getStationsRecords = async () => {
            //get stationsRecords
            const {forms} = await listApiFormByNameNormalized('api_weather_stations')();
            const formId = forms[0].id;
            const {count} = await getApiForm(formId)();
            const {data} = await getApiForm(formId, count)();
            return {stationsRecords: data, stationsFormId: formId}
        };
        //stationsRecords - stores all the records from table 'api_weather_stations'
        const {stationsRecords, stationsFormId} = await getStationsRecords();

        //stationsSourceData
        const buildStationsSourceData = (stationsRecords) => {

            let stationsSourceData;
            let stationsSourceDataFeatures = [];

            stationsRecords.forEach(station => {
                const {
                  altitude,
                  code,
                  code_iso,
                  country,
                  id,
                  latitude,
                  longitude,
                  name,
                  station_id,
                  station_name,
                  vgeolevel,
                  status
                } = station;

                const geometry = {
                                     "type": "Point",
                                     "coordinates": [longitude, latitude]
                };

                const properties = {
                   "altitude": altitude,
                   "code": code,
                   "code_iso": code_iso,
                   "country": country,
                   "id": id,
                   "name": name,
                   "station_id": station_id,
                   "station_name": station_name,
                   "vgeolevel": vgeolevel,
                   "status": JSON.parse(status.value)[0],
                   "coordinates": geometry.coordinates
                 };
                
                stationsSourceDataFeatures.push(turf.feature(geometry, properties))
            });
            
            stationsSourceData = turf.featureCollection(stationsSourceDataFeatures);

            return stationsSourceData
        };

        const stationsSourceData = buildStationsSourceData(stationsRecords);

        //userGeoArea
        const userGeoArea = await getUserGeoArea(props.history)(dispatch);

        //locations
        const geoRegions_idLevelObj = form.getFieldValue(['configuration', 'locations', 'geoRegions_idLevel']);
        const geoRegions_levelUp = form.getFieldValue(['configuration','locations','geoRegions_levelUp']);
        const geoRegions_regions = form.getFieldValue(['configuration','locations','geoRegions_regions']);
        
        //dataRegionsRecords
        let dataRegionsRecords = null;        
        if (geoRegions_idLevelObj?.idLevel !== undefined) {
            const {geoRegions} = await getRegionsWithGeojson(
                geoRegions_idLevelObj.idLevel, geoRegions_levelUp);
            dataRegionsRecords = geoRegions;
        }

        //messagesList
        if (!geoRegions_regions || geoRegions_regions?.length === 0) {
            const title = intl.formatMessage({id: 'PlotPointMapWeatherStations.no.geoRegions_regions.title'});
            const content = intl.formatMessage({id: 'PlotPointMapWeatherStations.no.geoRegions_regions.content'});
            messagesList.push({title: title, content: content})
        }

        //bordersSourceData
        const bordersSourceData = dataRegionsRecords !== null && dataRegionsRecords?.length !== 0
            ? getBordersSourceData(dataRegionsRecords, intl)
            : turf.featureCollection([]);

        //bbox
        const getBbox = (geoRegions_regions, stationsSourceData, bordersSourceData, userGeoArea, turf) => {
            let bbox_;
            if (!geoRegions_regions) {
                bbox_ = turf.bbox(userGeoArea.geojson)
            } else {
                const selectedBordersSourceData = turf.featureCollection(bordersSourceData.features.filter(feature => {
                    return geoRegions_regions.includes(feature.properties.regionCode)
                }));
                bbox_ = turf.bbox(selectedBordersSourceData)
            }

            return bbox_
        };
        const bbox = getBbox(geoRegions_regions, stationsSourceData, bordersSourceData, userGeoArea, turf);

        const lineColor = '#FFD700';
        const lineWidth = 2;

        const mapPlotHeight = 500;
        //the map
        const mapStyle = MAPBOX_STYLES.find(style => style.value === 'satellite-streets');
        const mapStyles = ['satellite-streets','satellite','streets'];
        const stylesControl = new StylesControlByRadioVersion1p0(mapStyles, mapStyle, null,
            0.6*mapPlotHeight);

        //legendControl
        const legendVariables = [
            intl.formatMessage({id: "FormItemActiveWeatherStationsId.status.enabled"}),
            intl.formatMessage({id: "FormItemActiveWeatherStationsId.status.disabled"})
        ];
        const enabledWeatherStationColor = '#ffff00';
        const disabledWeatherStationColor = '#FF4500';
        const legendColors = [enabledWeatherStationColor, disabledWeatherStationColor]
        const legendTitle = intl.formatMessage({id: "FormItemActiveWeatherStationsId.legendTitle"});
        const legendControl = new LegendControl(legendVariables, legendColors, legendTitle, 100)

        //non custom controls
        const navigationControl = new mapboxgl.NavigationControl();
        const fullScreenControl = new mapboxgl.FullscreenControl();

        //end setting variables ----------------------

        //useful functions
        /*
            Returns an array with the id of visible stations, to be used in layer filter
        */
        const getVisibleStationsIdsForStationsLayerFilter = (geoRegions_regions, bordersSourceData, stationsSourceData) => {

            if (!geoRegions_regions || geoRegions_regions?.length === 0 || !bordersSourceData ||
                bordersSourceData?.features?.length === 0) return [];

            //the visible stations are those whose points are in the selected bordersSourceData polygons
            const selectedFeatures =  bordersSourceData.features.filter(feature =>
                geoRegions_regions.includes(feature.properties.regionCode));
            const selectedBordersSourceData = turf.featureCollection(selectedFeatures);

           const featuresLength  = selectedBordersSourceData.features.length;

           if (featuresLength === 0) return [];

            return turf.pointsWithinPolygon(stationsSourceData, selectedBordersSourceData).features
                .map(feature => feature.properties.station_id)
        };

        const visibleStationsIds = getVisibleStationsIdsForStationsLayerFilter(
            geoRegions_regions, bordersSourceData, stationsSourceData);

        const getGeoRegionsForBordersLayerFilter = (geoRegions_regions) => {
            if (!geoRegions_regions || geoRegions_regions?.length === 0) return [];

            return geoRegions_regions
        };

        const getCircleColor = () => {
            return ['case',
                ['==',["get", "status"], 'enabled'],
                enabledWeatherStationColor,
                disabledWeatherStationColor
            ]
        };

        return( //+-+-+-
            {
                mapTitle: intl.formatMessage({id: "FormItemActiveWeatherStationsId.title"}),
                mapPlotHeight: mapPlotHeight,
                mapInitObj: {
                    container: 'map-container-id',
                    style: mapStyle.url,
                    bbox: bbox
                },
                style: {
                    sources: [
                        {
                            id: 'stationsSource',
                            source: {
                                type: 'geojson',
                                data: stationsSourceData
                            }
                       },
                       {
                            id: 'bordersSource',
                            source: {
                               type: 'geojson',
                               data: bordersSourceData
                            }
                      }
                    ],
                    layers: [
                        {
                            'id': 'stationsLayer',
                            'type': 'circle',
                            'source': 'stationsSource',
                            'filter': ['in',
                                        ['get','station_id'],
                                        ['literal', getVisibleStationsIdsForStationsLayerFilter(
                                            geoRegions_regions, bordersSourceData, stationsSourceData)]
                                      ],
                            'layout': {
                                'visibility': 'visible'
                            },
                            'paint': {
                                'circle-color': getCircleColor()
                            }
                        },
                        {
                            'id': 'bordersLayer',
                            'type': 'line',
                            'source': 'bordersSource',
                            'filter': ['in',
                                        ['get','regionCode'],
                                        ['literal', getGeoRegionsForBordersLayerFilter(
                                            geoRegions_regions)]
                                      ],
                            'layout': {
                                'visibility': 'visible'
                            },
                            'paint': {
                                'line-color': lineColor,
                                'line-width': lineWidth,
                            }
                        }
                    ]
                },
                controls: [
                    {name: 'stylesControl', control: stylesControl, position: 'top-right', custom: true},
                    {name: 'navigationControl', control: navigationControl, position: 'top-right', custom: false},
                    {name: 'fullScreenControl', control: fullScreenControl, position: 'top-right', custom: false},
                    {name: 'legendControl', control: legendControl, position: 'bottom-left', custom: true}
                ],
                messagesList: messagesList,
                getVisibleStationsIdsForStationsLayerFilter: getVisibleStationsIdsForStationsLayerFilter,
                getGeoRegionsForBordersLayerFilter: getGeoRegionsForBordersLayerFilter,
                getCircleColor: getCircleColor,
                popup: {
                    flyToObj: {
                        maxZoom: 22,
                        zoomStep: 4,
                        speed: 0.5,
                        curve: 1.0
                    }
                },
                dataRegionsRecords: dataRegionsRecords,
                stationsRecords: stationsRecords,
                userGeoArea: userGeoArea,
                getBbox: getBbox,
                turf: turf,
                locations: {
                    geoRegions_regions: geoRegions_regions,
                    geoRegions_idLevelObj: geoRegions_idLevelObj,
                    geoRegions_levelUp: geoRegions_levelUp,    
                },
                stationsFormId: stationsFormId,
                visibleStationsIds: visibleStationsIds
            }
        )
    };

    //initializing dRef
    const dRef = useRef();

    //functions to add and remove map's style's sources and layers ---------------
    const addMapStyleSource = (sourceObj, mapRef) => {
        if (!mapRef.current.getSource(sourceObj.id))  mapRef.current.addSource(sourceObj.id, sourceObj.source)
    };

    const addMapStyleLayer = (layerObj, mapRef) => {
        if (!mapRef.current.getLayer(layerObj.id))  mapRef.current.addLayer(layerObj)
    };

    // arraySourcesObjects as defined in dRef.current.style.sources
    const addMapStyleSources = (arraySourcesObjects, mapRef) => {
        arraySourcesObjects?.forEach(sourceObj => addMapStyleSource(sourceObj, mapRef))
    };

    // arrayLayersObjects as defined in dRef.current.style.layers
    const addMapStyleLayers = (arrayLayersObjects, mapRef) => {
        arrayLayersObjects?.forEach(layerObj => addMapStyleLayer(layerObj, mapRef))
    };

    //end functions to add and remove map's style's sources and layers ---------------

    //functions to add and remove map's controls -----------------
    //arrayMapControlsObjects as defined in dRef.current.controls
    const addMapControls = (arrayMapControlsObjects, mapRef) => {
        arrayMapControlsObjects
        .forEach(controlObj => {
            if (controlObj.control !== null && !mapRef.current.hasControl(controlObj.control)) {
                mapRef.current.addControl(controlObj.control, controlObj.position)
            }
        })
    };

    const removeMapControls = (arrayMapControlsObjects, mapRef) => {
        arrayMapControlsObjects
        .forEach(controlObj => {
            if (controlObj.control !== null && mapRef.current.hasControl(controlObj.control)) {
                mapRef.current.removeControl(controlObj.control)
            }
        })
    };
    //end functions to add and remove map's controls -----------------

    //other functions -------------
    const getSourceIndex = (sourceId, dRef) => {
                    return dRef.current.style.sources.findIndex(source =>
                      source.id === sourceId);
                };

    const getSourceData = (sourceId, dRef) => {
        return dRef.current.style.sources[getSourceIndex(sourceId, dRef)].source.data;
    };

    const getControlIndex = (controlName, dRef) => {
        return dRef.current.controls.findIndex(control => control.name === controlName)
    };

    const getControlObj = (controlName, dRef) => {
        return dRef.current.controls[getControlIndex(controlName, dRef)]
    };
    //end other functions ----------

    //formatStationRecordStatus
    const formatStationRecordStatus = (statusObj, value) => {
        return {...statusObj, value: JSON.stringify([value])}
    };

    //getVisibleAndActiveStations
    const getVisibleAndActiveStations = (dRef) => {
        const {stationsRecords, visibleStationsIds} = dRef.current;
        const visibleAndActiveStationsRecords = stationsRecords.filter(station => {
            const isVisible = visibleStationsIds.includes(station.station_id);
            const isEnabled = JSON.parse(station.status.value)[0] === 'enabled'
           return isVisible  && isEnabled
        });

        return {
            data: visibleAndActiveStationsRecords,
            offset: 0,
            max: 10,
            count: visibleAndActiveStationsRecords.length
        }
    };

    //update visibleStationsId
    const updateVisibleStationsId = (dRef) => {
        const geoRegions_regions = dRef.current.locations.geoRegions_regions;
        const bordersSourceData = getSourceData('bordersSource', dRef);
        const stationsSourceData = getSourceData('stationsSource', dRef);
        dRef.current.visibleStationsIds = dRef.current.getVisibleStationsIdsForStationsLayerFilter(
            geoRegions_regions, bordersSourceData, stationsSourceData);
    };

    //showPopup
    const showPopup = (e, popup, mapRef) => {
        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice();

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        const station_name = e.features[0].properties.station_name;
        const station_id = e.features[0].properties.station_id;
        const code = e.features[0].properties.code;
        const code_iso = e.features[0].properties.code_iso;
        const country = e.features[0].properties.country;
        const name = e.features[0].properties.name;

        const properties = [
            {name: 'station_name', value: station_name},
            {name: 'station_id', value: station_id},
            {name: 'code', value: code},
            {name: 'code_iso', value: code_iso},
            {name: 'country', value: country},
            {name: 'name', value: name}
            ];


        //eslint-disable-next-line
        const popupHTML = `
            <div class='popup-div'>
                <h1 class='popup-title'>
                    ${intl.formatMessage({id: "FormItemActiveWeatherStationsId.popup.title"})}
                </h1>
                <hr/>
                ${properties.map(property => {
                    return (`
                        <p class='popup-paragraph'>
                            <span class='popup-label'>
                                ${intl.formatMessage({id: "FormItemActiveWeatherStationsId.popup."+property.name})}:
                            </span>
                            <span class='popup-value'>${property.value}</span>
                        </p>
                    `)
                }).join('')}
            </div>`;

        popup
        .setLngLat(coordinates)
        .setHTML(popupHTML)
        .addTo(mapRef.current);
    };

    //handleOnClick
    const handleOnClick = (toggledStatus, id, dRef, stationId) => {
        //update stationsSourceData
        let index = getSourceData('stationsSource', dRef).features.findIndex(item => item.properties.id === id);
        dRef.current.style.sources[getSourceIndex('stationsSource', dRef)].source.data
            .features[index].properties.status = toggledStatus;

        //update map's stationsSource
        mapRef.current.getSource('stationsSource').setData(getSourceData('stationsSource', dRef));

        //update stationsRecords in dRef
        const indexClickedStation = dRef.current.stationsRecords.findIndex(station => station.id === id);
        const currentStatusObj = dRef.current.stationsRecords[indexClickedStation].status;
        dRef.current.stationsRecords[indexClickedStation].status = formatStationRecordStatus(
            currentStatusObj, toggledStatus);

        //update "updatedWeatherStations" form field, used to update records in database when onFinish form
        // method is executed
        let updatedWeatherStations = [];
        let updatedWeatherStationsArrayOfJSONs = form.getFieldValue(['configuration','updatedWeatherStations']);
        if (updatedWeatherStationsArrayOfJSONs !== undefined && updatedWeatherStationsArrayOfJSONs.length > 0) {
            updatedWeatherStations = updatedWeatherStationsArrayOfJSONs.map(updatedStation => {
                return JSON.parse(updatedStation)
        })}

        const indexUpdated = updatedWeatherStations.findIndex(stationObj => stationObj.id === id);

        if (indexUpdated !== -1) {
            //update already existent station
            updatedWeatherStations[indexUpdated].status = [toggledStatus];
        } else {
            //add new updated station object
            const newUpdatedStationObj = {
                form_id: dRef.current.stationsFormId,
                data_id: id,
                form: dRef.current.stationsRecords[indexClickedStation]
            }
            updatedWeatherStations.push(newUpdatedStationObj)
        }

        //update updatedWeatherStationsArrayOfJSONs
        updatedWeatherStationsArrayOfJSONs = updatedWeatherStations.map(stationObj => {
            return JSON.stringify(stationObj)
        });

        //configuration field
        let configuration = form.getFieldValue(['configuration']);

        //update "updatedWeatherStations" form field
        form.setFieldsValue({
            configuration: {...configuration, updatedWeatherStations: updatedWeatherStationsArrayOfJSONs}
        });

        //update activeWeatherStationsId
        let activeWeatherStationsId = form.getFieldValue(['configuration', 'activeWeatherStationsId']);
        if (activeWeatherStationsId === undefined) activeWeatherStationsId = [];
        if (activeWeatherStationsId?.length > 0) {
            if (activeWeatherStationsId.includes(stationId)) {
                const index = activeWeatherStationsId.findIndex(activeLocationId => activeLocationId === stationId);
                // if stationId already included (it was enabled) then it must be set disabled now,
                //so it must be removed from activeWeatherStationsId!
                activeWeatherStationsId[index] = 'remove';
                activeWeatherStationsId = activeWeatherStationsId.filter(activeLocationId => activeLocationId !== 'remove')
            } else {
              //if stationId already not included, then it must be included
              activeWeatherStationsId.push(stationId)
            }
        } else {
            if (toggledStatus === 'enabled') activeWeatherStationsId.push(stationId);
        }
        //update activeWeatherStationsId field
        configuration = form.getFieldValue(['configuration']);
        form.setFieldsValue({
            configuration: {...configuration, activeWeatherStationsId: activeWeatherStationsId}
        })

        //setActiveWeatherStations
        setActiveWeatherStations(getVisibleAndActiveStations(dRef))
    };

    //function to register map's events --------------
    const registerMapEvents = (dRef, mapRef) => {

        //on styledata - add sources and layers
        mapRef.current.on('styledata', () => {
            addMapStyleSources(dRef.current.style.sources, mapRef);
            addMapStyleLayers(dRef.current.style.layers, mapRef);
            //filter layers
            filterLayers(dRef)
        });

        //on click - update stations status
        mapRef.current.on('click', (e) => {
            const features = mapRef.current.queryRenderedFeatures(e.point, {
                layers: ['stationsLayer']
            });

            //managing click on a coordinate that contains a unique point that belongs to 'stationsLayer'
            //in this case a popup is issued
            if (features.length === 1) {

                //toggledStatus
                const status = features[0].properties.status;
                const toggledStatus = status === 'enabled' ? 'disabled' : 'enabled';

                //id
                const id = features[0].properties.id;
                const stationId = features[0].properties.station_id;
                handleOnClick(toggledStatus, id, dRef, stationId);
            }
        });

        //setting popup for mouseenter event
        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            maxWidth: 'none'
        });
        // Change the cursor to a pointer when the mouse is over the layer.
        mapRef.current.on('mouseenter', 'stationsLayer', (e) => {
            mapRef.current.getCanvas().style.cursor = 'pointer';

            const features = mapRef.current.queryRenderedFeatures(e.point, {
                layers: ['stationsLayer']
            });

            //managing click on a coordinate that contains a unique point that belongs to 'stationsLayer'
            //in this case a popup is issued
            if (features.length === 1) {
                showPopup(e, popup, mapRef);
            }

            //managing click on a coordinate that contains several points that belong to 'stationsLayer'
            //in this case a flyTo navigation is activated, so to increase the zoom and center the point
            //of the clicked coordinates.
            //this makes it possible to visually separate close points and then clicking on the wished one.
            if (features.length > 1) {
              const currentZoom = mapRef.current.getZoom();
              let nextZoom = currentZoom + dRef.current.popup.flyToObj.zoomStep;
              nextZoom = nextZoom > dRef.current.popup.flyToObj.maxZoom
                ? dRef.current.popup.flyToObj.maxZoom
                : nextZoom
              mapRef.current.flyTo({
                center: features[0].geometry.coordinates,
                zoom: nextZoom,
                speed: dRef.current.popup.flyToObj.speed,
                curve: dRef.current.popup.flyToObj.curve
              })
            }
        });

        // Change it back to a pointer when it leaves.
        mapRef.current.on('mouseleave', 'stationsLayer', () => {
            mapRef.current.getCanvas().style.cursor = '';
            popup.remove();
        });

        //this event is for allowing the right functioning of map controls on fullScreen mode
        mapRef.current.on('resize', () => {
            const fullscreenElement = window.document.fullscreenElement;
            let isFullscreen;
            if (fullscreenElement === null) {
                isFullscreen = false;
            } else if (fullscreenElement.className === 'map-container mapboxgl-map') {
                isFullscreen = true
            } else {
                isFullscreen = false
            }
            const mapboxglCtrlTopRightCollection = document.getElementsByClassName('mapboxgl-ctrl-top-right');
            if (mapboxglCtrlTopRightCollection?.length > 0) {
                const mapboxglCtrlTopRight = mapboxglCtrlTopRightCollection[0];
                mapboxglCtrlTopRight.style.top = isFullscreen ? '50px' : ''
            };
        });
    };

    //set dRef
    const [isDRef, setIsDRef] = useState(false);
    useEffect(() => {
         if (isDRef) return;

        buildMapDataObj()
        .then(obj => {
            if (!!obj) {
                dRef.current = obj;
                setIsDRef(true)
            }
        });

        //eslint-disable-next-line
    }, [isDRef]);


    //initialize map
    useEffect(function ue_loadMap() {
        if (!isDRef) return;

        //init map
        mapRef.current = new mapboxgl.Map(dRef.current.mapInitObj);
        mapRef.current.fitBounds(dRef.current.mapInitObj.bbox, {padding: 20});

        //map controls
        let arrayMapControlsObjects = [];
        if (edit)  {
            //include all controls
            arrayMapControlsObjects = dRef.current.controls
        } else {
            //exclude legendControl for new dataSourceConfiguration
            arrayMapControlsObjects = dRef.current.controls.filter(control => control.name !== 'legendControl')
        }
        addMapControls(arrayMapControlsObjects, mapRef);

        //map events
        registerMapEvents(dRef, mapRef);

        //state isLoadingStationsMap is set to false
        setIsLoadingStationsMap(false);
        scrollToAnchor('plotAnchor');
        setMessagesList(dRef.current.messagesList);

        //if !edit then update activeWeatherStationsId form field
        if (!edit) {
            const stationsSourceData = getSourceData('stationsSource', dRef);
            let activeWeatherStationsId = stationsSourceData.features.map(feature => {
                if (feature.properties.status === 'enabled') {
                    return feature.properties.station_id
                } else {
                    return null
                }
            }).filter(stationId => stationId !== null);

            const configuration = form.getFieldValue(['configuration']);
            form.setFieldsValue({
                configuration: {...configuration, activeWeatherStationsId: activeWeatherStationsId}
            })
        }

        //setActiveWeatherStations
        setActiveWeatherStations(getVisibleAndActiveStations(dRef))

        // Clean up on unmount
        return () => mapRef.current.remove();

        //eslint-disable-next-line
    }, [isDRef]);

    //handling events ----------------

    //filterLayers function
    const filterLayers = (dRef) => {
        const geoRegions_regions = dRef.current.locations.geoRegions_regions;
        const bordersSourceData = getSourceData('bordersSource', dRef);
        const stationsSourceData = getSourceData('stationsSource', dRef);
        //filter bordersLayer
        mapRef.current.setFilter('bordersLayer', ['in',
                                                   ['get','regionCode'],
                                                   ['literal', dRef.current.getGeoRegionsForBordersLayerFilter(
                                                       geoRegions_regions)]
                                                 ]);

        //filter stationsLayer
        mapRef.current.setFilter('stationsLayer', ['in',
                                                    ['get','station_id'],
                                                    ['literal', dRef.current.getVisibleStationsIdsForStationsLayerFilter(
                                                        geoRegions_regions, bordersSourceData, stationsSourceData)]
                                                  ]);
    };

    useEffect(() => {
        /*
            locationsObjectChanged is an object with keys:
            geoRegions_regions
            geoRegions_idLevelObject
            geoRegions_levelUp

            this object is changed in component FormItemLocations when geoRegions_regions field changes, due
            to user interaction.
        */
        if (!locationsObjectChanged) return;

        dRef.current.messagesList = [];
        setMessagesList([]);

        //spreading locationsObjectChanged
        const {
            geoRegions_regions,
            geoRegions_idLevelObject,
            geoRegions_levelUp
        } = locationsObjectChanged;

        //levelUpChanged
        const levelUpChanged = geoRegions_levelUp !== undefined;

        //updating dRef
        dRef.current.locations.geoRegions_regions = geoRegions_regions;
        dRef.current.locations.geoRegions_levelUp = geoRegions_levelUp;
        dRef.current.locations.geoRegions_idLevelObj = geoRegions_idLevelObject;

        const regionsLength = geoRegions_regions.length;

        if (regionsLength === 0) {
            const title = intl.formatMessage({id: 'PlotPointMapWeatherStations.no.geoRegions_regions.title'});
            const content = intl.formatMessage({id: 'PlotPointMapWeatherStations.no.geoRegions_regions.content'});
            dRef.current.messagesList.push({title: title, content: content})
            if (messagesList.length === 0) setMessagesList(dRef.current.messagesList);

            dRef.current.dataRegionsRecords = null;

            //update bordersSourceData
            const bordersSourceData = turf.featureCollection([]);
            dRef.current.style.sources[getSourceIndex('bordersSource', dRef)].source.data = bordersSourceData;
            //update map's bordersSourceData
            mapRef.current.getSource('bordersSource').setData(bordersSourceData);
            //update map's bounds
            mapRef.current.fitBounds(turf.bbox(dRef.current.userGeoArea.geojson), {padding: 20});

            //filter layers
            filterLayers(dRef)

            //remove legendControl
            removeMapControls([getControlObj('legendControl', dRef)], mapRef);

            //update visibleStationsId
            updateVisibleStationsId(dRef);
            //setActiveWeatherStations
            setActiveWeatherStations(getVisibleAndActiveStations(dRef))
        }

        if (regionsLength > 0 && levelUpChanged) {
            //update dataRegionsRecords, bordersSourceData and filter layers
            getRegionsWithGeojson(geoRegions_idLevelObject.idLevel, geoRegions_levelUp)
            .then(data => {
                dRef.current.dataRegionsRecords = data.geoRegions;
                dRef.current.style.sources[getSourceIndex('bordersSource', dRef)].source.data = getBordersSourceData(
                    data.geoRegions, intl);
                const bordersSourceData = getSourceData('bordersSource', dRef);
                //update map's bordersSourceData
                mapRef.current.getSource('bordersSource').setData(bordersSourceData);
                //update map's bounds
                const selectedBordersSourceData = turf.featureCollection(bordersSourceData.features.filter(feature =>
                    geoRegions_regions.includes(feature.properties.regionCode)));
                mapRef.current.fitBounds(turf.bbox(selectedBordersSourceData), {padding: 20});

                //filter layers
                filterLayers(dRef)

                //add legendControl
                addMapControls([getControlObj('legendControl', dRef)], mapRef);

                //update visibleStationsId
                updateVisibleStationsId(dRef);
                //setActiveWeatherStations
                setActiveWeatherStations(getVisibleAndActiveStations(dRef))
            });
        }

        if (regionsLength > 0 && !levelUpChanged) {

            const bordersSourceData = getSourceData('bordersSource', dRef);
            const selectedBordersSourceData = turf.featureCollection(bordersSourceData.features.filter(feature =>
                geoRegions_regions.includes(feature.properties.regionCode)));
            //update map's bounds
            mapRef.current.fitBounds(turf.bbox(selectedBordersSourceData), {padding: 20});

            //filter layers
            filterLayers(dRef)

            //update visibleStationsId
            updateVisibleStationsId(dRef);
            //setActiveWeatherStations
            setActiveWeatherStations(getVisibleAndActiveStations(dRef))
        }

        //eslint-disable-next-line
    }, [locationsObjectChanged]);

    //end handling events ----------------

    //jsx
    return (
        <div id={'plotAnchor'} >
            <div>
                <h1 style={{float: 'left'}}>{dRef.current?.mapTitle}</h1>
                <ShowMessages isDataPlotted={!isLoadingStationsMap} messagesList={messagesList} />
            </div>
            <div
                className="map-container" id='map-container-id'
                style={{height: '500px', clear: 'both'}}
            ></div>
        </div>
    );
};

export default PlotPointMapWeatherStations;