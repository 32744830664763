import {selectAppLoading, selectCurrentUser} from "../../redux/user/userSelector";
import {selectIsAuthenticated} from "../../redux/auth/authSelector";
import {connect} from "react-redux";
import {Redirect, Route} from "react-router-dom";
import React from 'react';

const PrivateRoute = ({component: Component, ...rest}) => {
    const isAuthenticated = rest.isAuthenticated;
    const loading = rest.loading;
    const currentUser = rest.currentUser;
    const requiredPermission = rest.permission;
    return (
        <Route {...rest}
               render={props =>
                   (!isAuthenticated && !loading) || (requiredPermission && currentUser && currentUser?.permissions?.find((permission) => permission === requiredPermission) == null) ? (
                       <Redirect to='/login'/>
                   ) : (
                       <Component isLoading={loading} {...props}/>
                   )
               }/>
    );
};

const mapStateToProps = state => ({
    currentUser: selectCurrentUser(state),
    isAuthenticated: selectIsAuthenticated(state),
    loading: selectAppLoading(state)
});

export default connect(mapStateToProps)(PrivateRoute);
