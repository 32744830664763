import React, { useState, useEffect } from 'react';
import { useIntl } from "react-intl";
import { Select, message, Modal } from "antd";
import { connect } from "react-redux";

import {
    setLoading,
    clearLoading,
    clearReportError,
    getReportOutput
} from "../../../redux/report/reportActions";

import {
    selectError,
    selectLoading,
    selectReportOutput,
    selectCurrentReport
} from "../../../redux/report/reportSelector";

const ReportOutputHtmlContent = (props) => {

    const intl = useIntl();
    const htmlContentTitle = intl.formatMessage({id: "label.report.ReportOutputHtmlContent.iframe"});
    const fileExtension = 'html';
    const [optionsHtmlFilesNames, setOptionsHtmlFilesNames] = useState([]);
    const [htmlFilesNamesValue, setHtmlFilesNamesValue] = useState();

    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (props.error) {
            const error = () => {
                if (typeof props.error == "string") {
                    message.error(props.error).then(props.clearReportError());
                } else {
                    if (props.error?.errors) {
                        props.error.errors?.map(errorMessage =>
                            (message.error(errorMessage.message).then(props.clearReportError())));
                    } else {
                        message.error(props.error).then(props.clearReportError());
                    }
                }
            };
            error();
        };

        if (props.error === null && props.reportId) {
            const fileName = undefined;
            props.getReportOutput(props.history, props.reportId, fileExtension, fileName).then( ({output}) => {
                if (output) {
                    const keys = Object.keys(output);
                    if (keys.includes('fileList')) {
                        setOptionsHtmlFilesNames(() => {
                            return output.fileList.map(fileName => {
                                return {label: fileName, value: fileName}
                            });
                        });
                        if (output.fileList.length === 1) {
                            const fileName = output.fileList[0];
                            props.getReportOutput(props.history, props.reportId, fileExtension, fileName).then(() => {});
                        } else {
                            setIsModalVisible(true);
                        }
                    }
                }
            });
        };

        //eslint-disable-next-line
    }, [props.error, props.reportId]);

    const handleSelectHtmlFilesNames = (fileName) => {
        if (fileName && props.reportId) {
            setHtmlFilesNamesValue(fileName);
            props.getReportOutput(props.history, props.reportId, fileExtension, fileName).then(() => {
                setIsModalVisible(false);
            });
        }
    };

    return (
        <>
            {props.reportOutput?.output?.htmlContent
                ? <iframe srcDoc={props.reportOutput.output.htmlContent}
                    style={{height: '95vh', border: 'none'}}
                    title={htmlContentTitle}
                  >
                        {intl.formatMessage({id:"label.report.view.renderingHtml.notCompatibleBrowserForIframe"})}
                  </iframe>
                : null
            }
            <Modal
                title={intl.formatMessage({id: "label.report.ReportOutputHtmlContent.report-output-file.select"})}
                visible={isModalVisible} okType={null}
                onCancel={handleCancel} centered width={420}>
                    <span style={{paddingBottom: 10, paddingLeft: 10}}>
                        <Select
                            id={'selectHtmlFilesNames'}
                            options={optionsHtmlFilesNames}
                            style={{width: '50%'}}
                            onChange={handleSelectHtmlFilesNames}
                            value={htmlFilesNamesValue}
                            allowClear
                            placeholder={intl.formatMessage({id:"label.report.ReportOutputHtmlContent.selectFile"})}
                        />
                    </span>
            </Modal>
        </>
    )
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    reportOutput: selectReportOutput(state),
    reportId: selectCurrentReport(state)?.id
});

export default connect(mapStateToProps, {
    setLoading,
    clearLoading,
    clearReportError,
    getReportOutput})(ReportOutputHtmlContent);



