import {Spin} from 'antd';

const WithSpinner = WrappedComponent => {
    const Spinner = ({isLoading, ...otherProps}) => {
        return isLoading ?
            (
                <div className='spinner-overlay'>
                    <Spin className='spinner-container'/>
                </div>
            ) : (
                <WrappedComponent {...otherProps}/>
            );
    };
    return Spinner;
};

export default WithSpinner;