import {createSelector} from "reselect";

const selectRole = state => state.role;

export const selectLoading = createSelector(
    [selectRole],
    (role) => role.loading
);

export const selectError = createSelector(
    [selectRole],
    (role) => role.error
);

export const selectRoles = createSelector(
    [selectRole],
    (role) => role.roles
);

export const selectCurrentRole = createSelector(
    [selectRole],
    (role) => role.current
);