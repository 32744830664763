import {Breadcrumb, Layout, message, Table, Popconfirm, Button, Modal, Form, Select, Input} from "antd";
import {HomeOutlined, DeleteTwoTone, EditTwoTone} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
import columnSearchProps from "../columnSearchProps";
import {connect, useDispatch} from "react-redux";
import {useEffect, useState} from "react";

import {selectLoading, selectError, selectStaff} from "../../redux/staff/staffSelector";
import {listStaff, deleteStaff, clearStaffError, getStaff, setLoading} from '../../redux/staff/staffActions';
import {Link} from "react-router-dom";
import {selectProjects} from "../../redux/project/projectSelector";
import {inviteStaff, listProjects, notifyStaff} from "../../redux/project/projectActions";
import {selectCurrentUser} from "../../redux/user/userSelector";
import {selectIsAuthenticated} from "../../redux/auth/authSelector";
import {getStaffByProject} from "../../utils/utilData";
import TextArea from "antd/lib/input/TextArea";


const {Content, Footer} = Layout;

const Staff = (props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const staffEmptyList = [{label: intl.formatMessage({id: 'label.all'}), value: 'All'}]
    const {staff, count, max, offset} = props.staff;
    const {projects} = props.projects;
    const [projectFilter, setProjectFilter] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isNotifyModalVisible, setIsNotifyVisible] = useState(false);
    const [notifyLoading, setNotifyLoading] = useState(false);
    const [projectList, setProjectList] = useState([{label: intl.formatMessage({id: 'label.select'}), value: null}])
    const [staffList, setStaffList] = useState(staffEmptyList)
    const [form] = Form.useForm();

    const checkPermissions = (requiredPermission) => {
        return props.isAuthenticated && requiredPermission && props.currentUser && props.currentUser?.permissions?.find((permission) => permission === requiredPermission)
    }

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (values) => {
        inviteStaff(values.project)
    };

    const handleNotifyOk = () => {
        setIsNotifyVisible(false);
    };

    const handleNotifyCancel = () => {
        setIsNotifyVisible(false);
    };

    const onNotifyFinish = (values) => {
        notifyStaff(values.project, values)
    };

    const onNotifyProjectSelected = async (selected) => {
        setNotifyLoading(true);
        let pagination = {}
        pagination['current'] = 1;
        pagination['pageSize'] = 2000;
        let sorter = {}
        sorter['field'] = 'name';
        let filter = {}
        filter['project'] = selected;
        if (selected !== null) {
            const staffRecords = await dispatch(getStaffByProject(props.history, selected))
            const loadedStaff = [...staffEmptyList];
            staffRecords?.map(staff => loadedStaff.push({label: staff.name, value: staff.email}))
            setStaffList(loadedStaff)
        } else {
            setStaffList(staffEmptyList)
        }
        setNotifyLoading(false)
    }

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    const columns = [{
        title: intl.formatMessage({id: 'label.id'}),
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '10%',
        fixed: true,
        sorter: (a, b) => a.id - b.id,
        sortDirections: ['ascend', 'descend'],
    }, {
        title: intl.formatMessage({id: 'label.actions'}),
        dataIndex: 'actions',
        align: 'center',
        width: '10%',
        fixed: true,
        render: (_, record) => count >= 1 ? (<div className="actionsIcons">
            <Popconfirm title={intl.formatMessage({id: 'msg.confirm-delete'})}
                        onConfirm={() => handleDelete(record.id)}>
                <DeleteTwoTone twoToneColor="red" title={intl.formatMessage({id: 'title.label.actions.remove'}) +' '+ intl.formatMessage({id: 'title.label.actions.staff'})} />
            </Popconfirm>
            <Link to={`/staff/${record.id}`}><EditTwoTone title={intl.formatMessage({id: 'title.label.actions.edit'}) +' '+ intl.formatMessage({id: 'title.label.actions.staff'})} /></Link>
        </div>) : 'null',
    }, {
        title: intl.formatMessage({id: 'label.name'}),
        dataIndex: 'name',
        key: 'name',
        width: '25%',
        fixed: false,
        sorter: (a, b) => a.name - b.name,
        sortDirections: ['ascend', 'descend'],
        filterMultiple: false, ...columnSearchProps('name'),
    }, {
        title: intl.formatMessage({id: 'label.email'}),
        dataIndex: 'email',
        key: 'email',
        width: '15%',
        fixed: false,
        sorter: (a, b) => a.email.length - b.email.length,
        sortDirections: ['ascend', 'descend'],
        filterMultiple: false, ...columnSearchProps('email'),
    }, {
        title: intl.formatMessage({id: 'label.confirmed'}),
        dataIndex: 'confirmed',
        key: 'confirmed',
        width: '10%',
        fixed: false,
        sorter: (a, b) => a.confirmed - b.confirmed,
        sortDirections: ['ascend', 'descend'],
        filterMultiple: false,
        filters: [{
            text: intl.formatMessage({id: 'label.yes'}), value: true,
        }, {
            text: intl.formatMessage({id: 'label.no'}), value: false,
        },],
        render: (text) => (text ? intl.formatMessage({id: 'label.yes'}) : intl.formatMessage({id: 'label.no'}))
    }, {
        title: intl.formatMessage({id: 'label.project'}),
        dataIndex: 'project',
        key: 'project',
        width: '30%',
        fixed: false,
        sorter: (a, b) => a.project - b.project,
        sortDirections: ['ascend', 'descend'],
        filters: projectFilter,
        render: (projectId) => (projectId ? projects?.find((project) => project.id === projectId)?.name : ''),
    }];

    useEffect(() => {

        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearStaffError());
            };
            error();
        } else {
            if (props.projects === null || props.projects.length === 0) {
                dispatch(setLoading());
                let pagination = {}
                pagination['current'] = 1;
                pagination['pageSize'] = 9999999;
                let sorter = {}
                sorter['field'] = 'name';
                dispatch(listProjects(props.history, pagination, {}, sorter));
            } else {
                setProjectFilter(projects?.map(project => ({text: project.name, value: project.id})));
                const loadedProjects = [...projectList];
                projects?.map(project => loadedProjects.push({label: project.name, value: project.id}))
                setProjectList(loadedProjects);
                props.listStaff(props.history);
            }

        }
        // eslint-disable-next-line
    }, [props.error, props.projects]);

    const handleChange = (pagination, filters, sorter) => {
        props.listStaff(props.history, pagination, filters, sorter);
    };

    const handleDelete = (id) => {
        props.deleteStaff(props.history, id);
    };

    const inviteStaff = (projectId) => {
        setNotifyLoading(true);
        props.inviteStaff(props.history, projectId).then(() => {
            setNotifyLoading(false);
            message.info(intl.formatMessage({id: 'label.staff-invited'})).then(() => {
                setIsModalVisible(false);
                form.resetFields();
            });
        });
    };

    const notifyStaff = (projectId, configuration) => {
        setNotifyLoading(true);
        props.notifyStaff(props.history, projectId, configuration).then((result) => {
            setNotifyLoading(false);
            if (result) {
                message.info(intl.formatMessage({id: 'label.staff-notified'})).then(() => {
                    setIsNotifyVisible(false);
                    form.resetFields();
                });
            } else {
                setIsNotifyVisible(false);
                form.resetFields();
            }

        });
    };

    return (<Layout className="site-layout">
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '10px 0'}}>
                <Breadcrumb.Item>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span><FormattedMessage id="label.staff" defaultMessage="Staff"/></span>
                </Breadcrumb.Item>

            </Breadcrumb>
            <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                <Link to='/staff/new'><Button type="primary" size={"large"} style={{marginBottom: 16}}>
                    <FormattedMessage id="label.add-staff" defaultMessage="Add Staff"/>
                </Button></Link>
                {checkPermissions('ROLE_PERMISSION_PROJECT_INVITE_STAFF') ? <>
                    <Button style={{marginLeft: 16}} type="danger" size={"large"} loading={notifyLoading}
                            disabled={staff?.length === 0} onClick={() => setIsModalVisible(true)}>
                        <FormattedMessage id="label.invite-staff" defaultMessage="Invite Staff"/>
                    </Button>
                    <Button style={{marginLeft: 16}} type="primary" size={"large"} loading={notifyLoading}
                            disabled={staff?.length === 0} onClick={() => setIsNotifyVisible(true)}>
                        <FormattedMessage id="label.notify-staff" defaultMessage="Notify Staff"/>
                    </Button>
                </> : <></>}


                <Table
                    bordered={true}
                    loading={props.loading}
                    rowKey={record => record.id}
                    responsive={true}
                    pagination={{
                        current: offset / max + 1,
                        showLessItems: true,
                        pageSizeOptions: [10, 20, 50, 100],
                        pageSize: max,
                        defaultPageSize: max,
                        total: count,
                        showSizeChanger: true,
                        showTotal: ((total, range) => intl.formatMessage({
                            id: 'label.range-total'
                        }, {one: range[0], two: range[1], total: total}))
                    }}
                    size={'default'}
                    showHeader
                    columns={columns}
                    dataSource={staff}
                    scroll={{y: '65vh', x: '100vw'}}
                    onChange={handleChange}
                />
            </div>
            <Modal title={intl.formatMessage({id: 'label.invite-staff'})} visible={isModalVisible}
                   onOk={handleOk} onCancel={handleCancel} centered width={720} confirmLoading={notifyLoading}
                   footer={null}>
                <Form layout="horizontal" name="staff_form_project" size="large"
                      labelCol={{span: 3,}} wrapperCol={{span: 14,}}
                      initialValues={{active: true}} form={form}
                      onFinish={onFinish} onFinishFailed={onFinishFailed}>
                    <Form.Item name="project" label={intl.formatMessage({id: 'label.project'})}
                               rules={[{
                                   required: true, message: intl.formatMessage({id: 'msg.input-required'})
                               }]}>
                        <Select options={projectList}>

                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="danger" htmlType="submit" loading={notifyLoading}>
                            <FormattedMessage id="label.invite" defaultMessage="Invite"/>
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title={intl.formatMessage({id: 'label.notify-staff'})} visible={isNotifyModalVisible}
                   onOk={handleNotifyOk} onCancel={handleNotifyCancel} centered width={720}
                   confirmLoading={notifyLoading}
                   footer={null}>
                <Form layout="horizontal" name="staff_form" size="large"
                      labelCol={{span: 4,}} wrapperCol={{span: 14,}}
                      initialValues={{active: true}} form={form}
                      onFinish={onNotifyFinish} onFinishFailed={onFinishFailed}>
                    <Form.Item name="project" label={intl.formatMessage({id: 'label.project'})}
                               rules={[{
                                   required: true, message: intl.formatMessage({id: 'msg.input-required'})
                               }]}>
                        <Select options={projectList} onSelect={onNotifyProjectSelected}>

                        </Select>
                    </Form.Item>
                    <Form.Item name="members" label={intl.formatMessage({id: 'label.staff'})}
                               rules={[{
                                   required: true, message: intl.formatMessage({id: 'msg.input-required'})
                               }]}>

                        <Select options={staffList} allowClear={true} mode={'multiple'} loading={notifyLoading}>

                        </Select>
                    </Form.Item>
                    <Form.Item name="header" label={intl.formatMessage({id: 'label.subject'})}
                               rules={[{
                                   required: true, message: intl.formatMessage({id: 'msg.input-required'})
                               }]}>

                        <Input type={"text"}
                        />
                    </Form.Item>
                    <Form.Item name="message" label={intl.formatMessage({id: 'label.message'})}
                               rules={[{
                                   required: true, message: intl.formatMessage({id: 'msg.input-required'})
                               }]}>

                        <TextArea  allowClear size={"middle"} showCount={true}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="danger" htmlType="submit" loading={notifyLoading}>
                            <FormattedMessage id="label.notify" defaultMessage="Notify"/>
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </Content>
        <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
    </Layout>);
}

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    staff: selectStaff(state),
    projects: selectProjects(state),
    currentUser: selectCurrentUser(state),
    isAuthenticated: selectIsAuthenticated(state)
});

export default connect(mapStateToProps, {
    listStaff,
    deleteStaff,
    clearStaffError,
    getStaff,
    notifyStaff,
    inviteStaff
})(Staff);