import FormItemActiveWeatherStationsId from "./FormItemActiveWeatherStationsId";
import {Form, Input, Select, TreeSelect, TimePicker, InputNumber, Divider} from 'antd';
import {useIntl} from "react-intl";
import {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {getApiForm} from './helpersForDataSourceConfigurations';
import moment from "moment";

const FormItemsApiOpenWeather = (props) => {

    const {
        form,
        dataSourceFormId,
        edit,
        changedFields,
        dataSourceConfiguration
    } = props;

    const intl = useIntl();
    const dispatch = useDispatch();

    const [treeDataWeatherFields, setTreeDataWeatherFields] = useState([]);

    //setTreeDataWeatherFields
    useEffect(() => {
        dispatch(getApiForm(dataSourceFormId, 1))
        .then(data => {
            setTreeDataWeatherFields(() => {
                const columnFormNames = data.dataSchemas[0].columnFormNames;
                const columnNormalizedNames = data.dataSchemas[0].columnNormalizedNames;
                return columnNormalizedNames.map((columnNormalizedName, index) => {
                    if (['id','date_created','last_updated'].includes(columnNormalizedName)) {
                        return null
                    } else {
                        return {
                            label: columnFormNames[index],
                            value: columnNormalizedName
                        }
                    }
                }).filter(item => item !== null)
            })
        })
        //eslint-disable-next-line
    }, []);

    //setting default value for visibleColumnNormalizedNames field,
    //when new dataConfigurations is set
    useEffect(() => {
        if (!edit && treeDataWeatherFields !== null) {
            const configuration = form.getFieldValue('configuration');
            form.setFieldsValue({
                configuration: {...configuration,
                    visibleColumnNormalizedNames: treeDataWeatherFields.map(item => item.value)}
            })
        }

        //eslint-disable-next-line
    }, [edit, treeDataWeatherFields]);

    //jsx
    return (
        <>
            <FormItemActiveWeatherStationsId
                form={form}
                edit={edit}
                changedFields={changedFields}
                dataSourceConfiguration={dataSourceConfiguration}
            />
            <Divider
                orientation={'left'}
            >
                {intl.formatMessage({id: "FormItemsApiOpenWeather.synchronization.parameters"})}
            </Divider>
            <Form.Item
                label={intl.formatMessage({id: "WeatherForm.label.apiUrl"})}
                name={['configuration','apiUrl']}
                initialValue={"https://api.openweathermap.org"}
                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label={intl.formatMessage({id: "WeatherForm.label.apiKey"})}
                name={['configuration','apiKey']}
                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label={intl.formatMessage({id: "WeatherForm.label.units"})}
                name={['configuration','units']}
                initialValue={"metric"}
                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
            >
                <Select
                    options={
                      [
                        {label: intl.formatMessage({id: "WeatherForm.label.units.metric"}), value: "metric"},
                        {label: intl.formatMessage({id: "WeatherForm.label.units.standard"}), value: "standard"},
                        {label: intl.formatMessage({id: "WeatherForm.label.units.imperial"}), value: "imperial"}
                      ]
                    }
                    style={{width: '150px'}}
                  />
            </Form.Item>
            <Form.Item
                label={intl.formatMessage({id: "WeatherForm.label.visibleColumnNormalizedNames"})}
                name={['configuration','visibleColumnNormalizedNames']}
                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
            >
                <TreeSelect
                  treeDefaultExpandAll={true}
                  allowClear={true}
                  multiple={true}
                  treeData={treeDataWeatherFields}
                  maxTagCount={'responsive'}
                  />
            </Form.Item>
            <Form.Item
                label={intl.formatMessage({id: "WeatherForm.label.startTime"})}
                name={['configuration','startTime']}
                initialValue={moment("00:00","HH:mm")}
                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
            >
                <TimePicker
                    format={'HH:mm'}
                />
            </Form.Item>
            <Form.Item
                label={intl.formatMessage({id: "WeatherForm.label.freq"})}
                name={['configuration','updateFrequency']}
                initialValue={1}
                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
            >
                <InputNumber
                    step={1}
                    min={1}
                />
            </Form.Item>
        </>
    )
};

export default FormItemsApiOpenWeather;
