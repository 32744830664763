import {Breadcrumb, Layout} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import {FormattedMessage } from "react-intl";
import {connect } from "react-redux";
import Plots from "./Plots";


const {Content, Footer} = Layout;

const BarPlot = (props) => {

   return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.Analysis" defaultMessage="Analysis"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="label.BarPlot" defaultMessage="Bar Plot"/></span>
                    </Breadcrumb.Item>

                </Breadcrumb>
                <Plots type={'bar'}/>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
}




const mapStateToProps = state => ({

});

export default connect(mapStateToProps, {})(BarPlot);