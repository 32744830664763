import './LegendControl.css';

import ReactDOM from "react-dom";
import React from "react";

/**
  * The instance of this class is used as a custom map control to show a legend for data.
  *
  * @param {Array<String>} variables The intervals of the variable that correspond to the scale of legend.
  * @param {Array<String>}  colors The colors used in the scale.
  * @param {String} title The title of the legend.
  * @param {Number} maxHeight The maximum `height` of the legend in pixels.
  * @return {IControl} A custom map control.
*/
class LegendControl {
    variables;
    colors;
    title;
    maxHeight;
    containerElement;
    rootElement;

    constructor (variables, colors, title, maxHeight){
        this.variables = variables;
        this.colors = colors;
        this.title = title;
        this.maxHeight = maxHeight;

        //container element
        this.containerElement = document.createElement('div');
        this.containerElement.classList.add('mapboxgl-ctrl-legend');
        this.containerElement.id = "containerElement";

        //rootElement
        this.rootElement = document.createElement('div');
        this.rootElement.id = 'rootElement-react-rendering';
        this.rootElement.maxHeight = maxHeight !== 0 ? maxHeight+'px' : null;
        this.rootElement.overflowY = maxHeight !== 0 ? 'auto' : null;

        this.containerElement.appendChild(this.rootElement);
    };

    onAdd(map){
        this.map = map;

        this.containerElement.style.display = this.doDisplayLegend() ? 'block' : 'none';

        //adding legend
        if (this.doDisplayLegend()) {
            //rendering Legend
            ReactDOM.render(
              <Legend
                variables={this.variables}
                colors={this.colors}
                title={this.title}
                />,
              this.rootElement
            );
        }

        return this.containerElement;
    }

    onRemove(){
        this.containerElement.parentNode?.removeChild(this.containerElement);
        this.map = undefined;
    }

    doDisplayLegend(){

        const variablesUnique = [...new Set(this.variables)];
        const isVariablesNoOk = variablesUnique.length === 1 && variablesUnique[0] === '';

        if (
            this.variables === null ||
            this.variables?.length === 0 ||
            this.variables?.includes('NaN') ||
            isVariablesNoOk
        ) return false;

        return true;

    }

    update(variables, colors){

      this.variables = variables;
      this.colors = colors;

      this.containerElement.style.display = this.doDisplayLegend() ? 'block' : 'none';

      //update legend only if conditions are appropriate!
      if (this.doDisplayLegend()) {
        //updating Legend
        ReactDOM.render(
          <Legend
            variables={this.variables}
            colors={this.colors}
            title={this.title}
            />,
          this.rootElement
        );
      }
    }
}

export default LegendControl;


const Legend = (props) => {

    /**
     * props.colors can be Array or Object
     */
    const isArrayColors = Array.isArray(props.colors);

    return (
        <div id={'custom-legendContainer'}>
            <div className={'custom-legend-titleElement'}>
                {props.title}
            </div>
            <div id={'custom-legend-itemsContainer'}>
                {
                    props.variables.map((variable, i) => {
                        let spanColorsElement = <span className={'custom-legend-colorElement'}
                            style={{ backgroundColor: props.colors[i] }}
                        ></span>;

                        
                        if (!isArrayColors) {//if it is not an Array then it must be an Object
                            const numberOfColors = props.colors[variable].length;
                            spanColorsElement = props.colors[variable].map((color, index) => {
                                const spanClassName = numberOfColors === 1
                                    ? 'custom-legend-colorElement'
                                    : index === 0 
                                        ? 'custom-legend-colorElement-scale-ini'
                                        : index < (numberOfColors - 1) 
                                            ? 'custom-legend-colorElement-scale-inside'
                                            : 'custom-legend-colorElement-scale-end'
                                return (
                                    <span key={Math.random().toString(10)} className={spanClassName}
                                        style={{ backgroundColor: color}}></span>
                                );
                            });
                        };

                        return (
                            <div className={'custom-legend-itemElement'} key={i}>                                
                                {spanColorsElement}
                                <span className={'custom-legend-variableElement'}>
                                    {variable}
                                </span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
