import {createSelector} from "reselect";

const selectPermission = state => state.permission;

export const selectLoading = createSelector(
    [selectPermission],
    (permission) => permission.loading
);

export const selectError = createSelector(
    [selectPermission],
    (permission) => permission.error
);

export const selectPermissions = createSelector(
    [selectPermission],
    (permission) => permission.permissions
);