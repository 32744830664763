import {Button, DatePicker, Divider, Form, message, Select} from "antd";
import {FormattedMessage, useIntl} from "react-intl";
import {connect, useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {selectSurveyForms, selectLoading, selectErrorForm} from "../../redux/survey/form/surveyFormSelector";
import {listSurveyForms, clearSurveyFormError} from "../../redux/survey/form/surveyFormActions";
import {DATEFORMAT, getLevelsByForm} from "../../utils/utilData";
import moment from "moment/moment";

const {RangePicker} = DatePicker;


const Filters = (props) => {

    const intl = useIntl();
    const {forms} = props.forms;
    const [geoLevels, setGeoLevels] = useState([]);
    const [selectedForm, setSelectedForm] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {Option} = Select;


    useEffect(() => {

        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearSurveyFormError());
            };
            error();
        } else {
            if (forms === undefined) {
                props.listSurveyForms(props.history);
            }
        }
        // eslint-disable-next-line
    }, [props.error]);


    useEffect(() => {

        if (selectedForm) {
            setLoading(true)
            dispatch(getLevelsByForm(selectedForm)).then(data => {
                    if (data) {
                        if (geoLevels.length === 0) {
                            const loadedRegions = [...geoLevels];
                            data.map((geoRegion) =>
                                loadedRegions.push({
                                    label: geoRegion.name,
                                    value: geoRegion.idlevel + ";" + geoRegion.code,
                                })
                            );
                            setGeoLevels(loadedRegions);
                        }
                    }
                    setLoading(false);
                }
            )
        } else {
            setGeoLevels([])
        }
        // eslint-disable-next-line
    }, [selectedForm]);


    return (
        <>
            <Divider
                orientation="left"
                plain
                orientationMargin="0"
            >
                <FormattedMessage id="label.filters" defaultMessage="Filters"/>
            </Divider>

            <Form
                layout="inline"
                name="project_form"
                size="large"
                initialValues={{active: true}}
                form={form}
                onFinish={props.onFinish}
                onFinishFailed={props.onFinishFailed}
            >

                <Form.Item
                    name="form" key="form"
                    label={intl.formatMessage({id: "label.form"})}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage({id: "msg.input-required"}),
                        },
                    ]}
                >
                    <Select
                        style={{minWidth: "300px"}}
                        showSearch
                        allowClear={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                        }
                        onChange={(value) => {
                            setSelectedForm(value)
                        }}
                    >
                        {forms?.map((f) => {
                            return (
                                <Option key={f.id} value={f.id}>
                                    {f.name}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="dateRange" key="dateRange"
                    label={intl.formatMessage({id: "label.dates"})}
                    rules={[
                        {
                            required: false,
                            message: intl.formatMessage({id: "msg.input-required"}),
                        },
                    ]}
                >
                    <RangePicker format={DATEFORMAT} disabledDate={disabledDate}/>
                </Form.Item>

                <Form.Item
                    name="regionCode" key="regionCode"
                    label={intl.formatMessage({id: "label.region"})}
                    rules={[
                        {
                            required: false,
                            message: intl.formatMessage({id: "msg.input-required"}),
                        },
                    ]}
                >
                    <Select
                        style={{minWidth: "300px"}}
                        showSearch
                        allowClear={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                        }
                        disabled={geoLevels?.length === 0} loading={loading}
                    >
                        {geoLevels.map((region) => {
                            return (
                                <Option key={region.value} value={region.value}>
                                    {region.label}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={props.searchLoad}>
                        <FormattedMessage id="label.search" defaultMessage="Search"/>
                    </Button>
                </Form.Item>
            </Form>
            <Divider/>
        </>

    );
}


function disabledDate(current) {
    // Can not select days after today and today
    return current && current > moment().endOf("day");
}

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectErrorForm(state),
    forms: selectSurveyForms(state)
});

export default connect(mapStateToProps, {listSurveyForms, clearSurveyFormError})(Filters);