//Multisource version of analysis. Helper constants and functions.
//
//Helper constants and functions translated to javascript from groovy code of DataAnalysisQueryService.groovy
//This translation makes code of frontend compatible with code of backend.

export const THIS_FILE = 'multiSourceUtils.js';

export const REGEXES = {
    "VALIDATION" : {
            "frontEndFieldNameValidationRegex": /^(m|qb)[1-9][0-9]*[.].+$/,
            "frontEndFieldNameWithUnderscoreValidationRegex": /^(m|qb)[1-9][0-9]*[_].+$/,
            "frontEndFieldNameModifiedWithPrefixValidationRegex": /^.+[.](m|qb)[1-9][0-9]*[_].+$/,
            "FORMFIELD_COLUMNS_SINGULAR_ValidationRegex" : /^.+[^s]$/,
            "form_m_tableAliasValidationRegex": /^m[1-9][0-9]*$/,
            "form_qb_tableAliasValidationRegex": /^qb[1-9][0-9]*$/
    },
    "EXTRACTION": {
            "columnNameExtractionRegex": /(?<=(m|qb)[1-9][0-9]{0,10}[.]).+/,
            "formIdExtractionRegex": /(?<=(m|qb))[1-9][0-9]{0,10}(?=([_]|[.]).+)/,
            "tableAliasExtractionRegex": /(m|qb)(?=[1-9][0-9]{0,10}([_]|[.]).+)/,
            "frontEndFieldNameExtractionRegex": /(m|qb)[1-9][0-9]*[.].+$/,
            "frontEndFieldNameExtractionFromWithUnderscoreRegex": /^(m|qb)[1-9][0-9]*[_].+$/,
            "frontEndFieldNameExtractionFromModifiedWithPrefixRegex": /(?<=.+[.])(m|qb)[1-9][0-9]*[_].+$/,
            "FORMFIELD_COLUMNS_SINGULAR_ExtractionRegex" : /^(.*)s$/
    }
};

export const validate_form_m_tableAlias = (input) => {
    return REGEXES.VALIDATION.form_m_tableAliasValidationRegex.test(input)
};

export const validate_form_qb_tableAlias = (input) => {
    return REGEXES.VALIDATION.form_qb_tableAliasValidationRegex.test(input)
};

export const validate_FORMFIELD_COLUMNS_SINGULAR = (input) => {
    return REGEXES.VALIDATION.FORMFIELD_COLUMNS_SINGULAR_ValidationRegex.test(input)
};

export const extractSubstring = (str, regex) => {
    // Use the regex to search for a match in the string
    const match = str.match(regex);

    // If a match is found, return the first captured group
    // If no match is found, return null
    return match ? match[0] : null;
}
export const extract_FORMFIELD_COLUMNS_SINGULAR = (input) => {
    return extractSubstring(input, REGEXES.EXTRACTION.FORMFIELD_COLUMNS_SINGULAR_ExtractionRegex)
    
};

export const DATASCHEMA_COLUMNS = [
    "columnTypes",
    "columnFormFieldTypes",
    "dataTypes",
    "columnNames",
    "columnFormNames"
];

export const DATASCHEMA_COLUMNS_SINGULAR = DATASCHEMA_COLUMNS.map(it => extract_FORMFIELD_COLUMNS_SINGULAR(it));


export const FORMFIELD_COLUMNS_SINGULAR = [
        "sqlType"
];

export const FORMFIELD_SQL_TYPES_FOR_DATE = ["DATE", "TIMESTAMP WITHOUT TIME ZONE"];

export const validateFrontEndFieldName = (input) => {
    return REGEXES.VALIDATION.frontEndFieldNameValidationRegex.test(input)
};

export const validateFrontEndFieldNameWithUnderscore = (input) => {
    return REGEXES.VALIDATION.frontEndFieldNameWithUnderscoreValidationRegex.test(input)
};

export const validateFrontEndFieldNameModifiedWithPrefix = (input) => {
    return REGEXES.VALIDATION.frontEndFieldNameModifiedWithPrefixValidationRegex.test(input)
};

export const extractColumnName = (input) => {
    return extractSubstring(input, REGEXES.EXTRACTION.columnNameExtractionRegex)
    
};

export const extractFormId = (input) => {
    return extractSubstring(input, REGEXES.EXTRACTION.formIdExtractionRegex)    
};

export const extractTableAlias = (input) => {
    return extractSubstring(input, REGEXES.EXTRACTION.tableAliasExtractionRegex)    
};

export const extractFrontEndFieldName = (input) => {
    return extractSubstring(input, REGEXES.EXTRACTION.frontEndFieldNameExtractionRegex)    
};

export const extractFrontEndFieldNameFromWithUnderscore = (input) => {  //input like "m5_nubdnbapdd_5187493948"      
    return extractSubstring(input, REGEXES.EXTRACTION.frontEndFieldNameExtractionFromWithUnderscoreRegex)            
};

export const extractFrontEndFieldNameFromModifiedWithPrefix = (input) => {  //input like "ls.m5_nubdnbapdd_5187493948"      
    return extractSubstring(input, REGEXES.EXTRACTION.frontEndFieldNameExtractionFromModifiedWithPrefixRegex)            
};

export const validateFrontEndFieldNameFromCases = (input) => {
    return (
            validateFrontEndFieldName(input) ||
            validateFrontEndFieldNameWithUnderscore(input) ||
            validateFrontEndFieldNameModifiedWithPrefix(input)
    )
};

export const extractFrontEndFieldNameFromCases = (input) => {
    if (validateFrontEndFieldNameFromCases(input)) {
        if(validateFrontEndFieldName(input)) {
            return extractFrontEndFieldName(input)
        } else if (validateFrontEndFieldNameWithUnderscore(input)) {
            return extractFrontEndFieldNameFromWithUnderscore((input))
        } else if (validateFrontEndFieldNameModifiedWithPrefix(input)) {
            return extractFrontEndFieldNameFromModifiedWithPrefix(input)
        }
    } else {
        const errorMsg = [
                "Unexpected error. Please, contact application's admin!",
                "Error details: The value of argument 'input' of function ",
                "'extractFrontEndFieldNameFromCases' ",
                "in file 'multiSourceUtils.js' is ",
                "not valid because it does not match any of the predefined regex patterns.",
                `The value of argument 'input' is ${input}.`
        ].join(' ')

        throw new Error(errorMsg)
    }
};

//throw new error helpers
export const throwCustomErrorInvalidValueOfArgumentOfFunction = (varArg, varArgMap, functionArg, becauseArg, fileArg = THIS_FILE) => {
    const errorMsg = (varArg_, varArgMap_, functionArg_, becauseArg_, fileArg_ = fileArg) => {
        let isStringVarArgMapValue;
        let varArgMapValue;
        if (varArgMap_ && varArgMap_.hasOwnProperty("value")) {
            varArgMapValue = varArgMap_["value"];
            if (typeof varArgMapValue === "string") {
                isStringVarArgMapValue = true;
            } else {
                isStringVarArgMapValue = false;
                varArgMapValue = JSON.stringify(varArgMapValue);
            }
        } else {
            isStringVarArgMapValue = false;
            varArgMapValue = null;
        }

        return [
            "Unexpected error. Please, contact application's admin!",
            `Error details: The value of argument '${varArg_}' of method '${functionArg_}' in file ${fileArg_} is not valid because ${becauseArg_}.`,
            `The ${isStringVarArgMapValue ? 'value' : 'json value'} of variable '${varArg ? varArg : 'varArg'}' is ${isStringVarArgMapValue ? '\'' + varArgMapValue + '\'' : varArgMapValue}.`
        ].join(' ');
    };

    // Validating arguments
    const functionArgCurrentMethod = 'throwCustomErrorInvalidValueOfArgumentOfFunction';
    if (!varArg) {
        throw new Error(errorMsg('varArg', { "value": varArg }, functionArgCurrentMethod, "it is null or undefined", fileArg));
    }
    if (!varArgMap || !varArgMap.hasOwnProperty('value')) {
        throw new Error(errorMsg('varArgMap', { "value": varArgMap }, functionArgCurrentMethod, "it is null or undefined or it does not contain a key \"value\"", fileArg));
    }
    if (!functionArg) {
        throw new Error(errorMsg('functionArg', { "value": functionArg }, functionArgCurrentMethod, "it is null or undefined", fileArg));
    }
    if (!becauseArg) {
        throw new Error(errorMsg('becauseArg', { "value": becauseArg }, functionArgCurrentMethod, "it is null or undefined", fileArg));
    }

    throw new Error(errorMsg(varArg, varArgMap, functionArg, becauseArg, fileArg));
};

export const throwCustomErrorInvalidValueOfVariable = (varArg, varArgMap, functionArg, becauseArg, fileArg = 'myFunction') => {
    let errorMsg;

    // Validating arguments
    if (!varArg) {
        throwCustomErrorInvalidValueOfArgumentOfFunction('varArg', { "value": varArg },
            'throwCustomErrorInvalidValueOfVariable',
            "it is null or undefined",
            fileArg);
    }
    if (!varArgMap || !varArgMap.hasOwnProperty('value')) {
        throwCustomErrorInvalidValueOfArgumentOfFunction('varArgMap', { "value": varArgMap },
            'throwCustomErrorInvalidValueOfVariable',
            "it is null or undefined or it does not contain a key \"value\"",
            fileArg);
    }
    if (!functionArg) {
        throwCustomErrorInvalidValueOfArgumentOfFunction('functionArg', { "value": functionArg },
            'throwCustomErrorInvalidValueOfVariable',
            "it is null or undefined",
            fileArg);
    }
    if (!becauseArg) {
        throwCustomErrorInvalidValueOfArgumentOfFunction('becauseArg', { "value": becauseArg },
            'throwCustomErrorInvalidValueOfVariable',
            "it is null or undefined",
            fileArg);
    }

    let varArgMapValue = varArgMap["value"];
    let isStringVarArgMapValue = true;
    if (typeof varArgMapValue !== "string") {
        isStringVarArgMapValue = false;
        varArgMapValue = JSON.stringify(varArgMapValue);
    }

    errorMsg = [
        "Unexpected error. Please, contact application's admin!",
        `Error details: The value of variable '${varArg}' inside method '${functionArg}' in class ${fileArg} is not valid because ${becauseArg}.`,
        `The ${isStringVarArgMapValue ? 'value' : 'json value'} of variable '${varArg}' is ${isStringVarArgMapValue ? '\'' + varArgMapValue + '\'' : varArgMapValue}.`
    ].join(' ');

    throw new Error(errorMsg);
};


