import {Form, Input, Button, Row, Col, message} from 'antd';
import {connect} from "react-redux";
import {selectError} from "../../redux/auth/authSelector";
import {clearLoginError, resetPassword} from "../../redux/auth/authActions";
import {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

const RecoverPassword = (props) => {

    const intl = useIntl();

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.error) {
            const error = () => {
                if (typeof props.error == "string") {
                    message.error(props.error).then(props.clearLoginError());
                } else if (props.error.hasOwnProperty('message')) {
                    message.error(props.error.message).then(props.clearLoginError());
                } else {
                    props.error.errors.map(errorMessage =>
                        (message.error(errorMessage.message).then(props.clearLoginError())));
                }

            };
            error();
        }
        // eslint-disable-next-line
    }, [props.error]);

    const onFinish = async (values) => {
        setLoading(true);
        let queryToken = props.location?.search;

        values['token'] = queryToken?.replace('?token=', '');
        let response = await props.resetPassword(props.history, values);
        if (response && response?.message) {
            message.success(response?.message);
            form.resetFields();
        }
        setLoading(false);
    };

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    return (
        <Row>
            <Col className={'login-center'}>
                <h1><FormattedMessage id="label.reset-password" defaultMessage="Reset Password"/></h1>
                <Form
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed} form={form}
                >
                    <Form.Item
                        label={<FormattedMessage id="label.password" defaultMessage="Password"/>}
                        name="password"
                        rules={[{
                            required: true,
                            message: intl.formatMessage({id: 'msg.input-required'})
                        }]}
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item
                        label={<FormattedMessage id="label.confirm-password" defaultMessage="Confirm password"/>}
                        name="password2"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({id: 'msg.input-required'}),
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(intl.formatMessage({id: 'label.mismatch-password'})));
                                },
                            }),
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>


                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            <FormattedMessage id="label.update-password" defaultMessage="Update password"/>
                        </Button>
                    </Form.Item>
                </Form></Col>
        </Row>
    );
}

const mapStateToProps = state => ({
    error: selectError(state)
});

export default connect(mapStateToProps, {clearLoginError, resetPassword})(RecoverPassword);