import {createSelector} from "reselect";

const selectAlertContactState = state => state.alertContact;

export const selectLoading = createSelector(
    [selectAlertContactState],
    (alertContact) => alertContact.loading
);

export const selectError = createSelector(
    [selectAlertContactState],
    (alertContact) => alertContact.error
);

export const selectAlertContacts = createSelector(
    [selectAlertContactState],
    (alertContact) => alertContact.alertContacts
);

export const selectCurrentAlertContact = createSelector(
    [selectAlertContactState],
    (alertContact) => alertContact.current
);
