import {Table, Popconfirm, Tooltip} from 'antd';
import {MinusCircleTwoTone} from "@ant-design/icons";
import {useIntl} from "react-intl";
import columnSearchProps from "../../columnSearchProps";
import {useEffect, useState} from 'react';

const ActiveStationsTable = (props) => {

  //activeWeatherStations includes stations that:
  //have status = 'enabled' and also
  //are visible on map, according to selected regions
  const {
    data,
    offset,
    max,
    count
  } = props.activeWeatherStations;

  const intl = useIntl();

  const handleActionDisable = (id) => {
    props.setDisableStationId(id)
  };  

  const columns = [
    {
        title: intl.formatMessage({id: 'label.id'}),
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '5%',
        fixed: true,
        sorter: (a, b) => a.id - b.id,
        sortDirections: ['ascend', 'descend'],
    },
    {
     title: intl.formatMessage({id: 'label.actions'}),
     dataIndex: 'actions',
     align: 'center',
     width: '5%',
     fixed: true,
     render: (_, record) =>
         count >= 1 ? (
                 <Popconfirm
                     title={intl.formatMessage({id: 'msg.confirm-disable'})}
                     onConfirm={() => handleActionDisable(record.id)}>
                     <Tooltip title= {intl.formatMessage({id: 'ActiveStationsTable.action.disable.title'})}
                        placement={'bottom'}>
                        <MinusCircleTwoTone twoToneColor="red"/>
                     </Tooltip>
                 </Popconfirm>
         ) : null,
    },
    {
        title: intl.formatMessage({id: 'label.ActiveStationsTable.station_name'}),
        dataIndex: 'station_name',
        key: 'station_name',
        align: 'center',
        width: '5%',
        fixed: true,
        sorter: (a, b) => a.station_name - b.station_name,
        sortDirections: ['ascend', 'descend'],
        filterMultiple: false,
        ...columnSearchProps('station_name'),
    },
    {
        title: intl.formatMessage({id: 'label.ActiveStationsTable.station_id'}),
        dataIndex: 'station_id',
        key: 'station_id',
        align: 'center',
        width: '5%',
        fixed: false,
        sorter: (a, b) => a.station_id - b.station_id,
        sortDirections: ['ascend', 'descend'],
        filterMultiple: false,
        ...columnSearchProps('station_id'),
    },
    {
        title: intl.formatMessage({id: 'label.ActiveStationsTable.name'}),
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: '5%',
        fixed: false,
        sorter: (a, b) => a.name - b.name,
        sortDirections: ['ascend', 'descend'],
        filterMultiple: false,
        ...columnSearchProps('name'),
    },
    {
    title: intl.formatMessage({id: 'label.ActiveStationsTable.altitude'}),
    dataIndex: 'altitude',
    key: 'altitude',
    align: 'center',
    width: '5%',
    fixed: false,
    sorter: (a, b) => a.altitude - b.altitude,
    sortDirections: ['ascend', 'descend'],
    filterMultiple: false,
    ...columnSearchProps('altitude'),
    },
    {
        title: intl.formatMessage({id: 'label.ActiveStationsTable.code'}),
        dataIndex: 'code',
        key: 'code',
        align: 'center',
        width: '5%',
        fixed: false,
        sorter: (a, b) => a.code - b.code,
        sortDirections: ['ascend', 'descend'],
        filterMultiple: false,
        ...columnSearchProps('code'),
    },
    {
     title: intl.formatMessage({id: 'label.ActiveStationsTable.code_iso'}),
     dataIndex: 'code_iso',
     key: 'code_iso',
     align: 'center',
     width: '5%',
     fixed: false,
     sorter: (a, b) => a.code_iso - b.code_iso,
     sortDirections: ['ascend', 'descend'],
     filterMultiple: false,
     ...columnSearchProps('code_iso'),
    }
  ];

  const [pagination, setPagination] = useState(null);
      useEffect(() => {

          if (offset === undefined || max === undefined || count === undefined) return;

          setPagination({
           current: offset / max + 1,
           showLessItems: true,
           pageSizeOptions: [10, 20, 50, 100],
           pageSize: max,
           defaultPageSize: max,
           total: count,
           showSizeChanger: true,
           showTotal: ((total, range) => intl.formatMessage({
             id: 'label.range-total'
           }, {one: range[0], two: range[1], total: total}))
         })

        //eslint-disable-next-line
      }, [offset, max, count, props.dataSourceConfigurations])

  const handleChange = (pagination, filters, sorter) => {
      setPagination(pagination)
  };

  //jsx
  return(
    <div>
      <h1>{intl.formatMessage({id: "ActiveStationsTable.title"})}</h1>
      <Table
        bordered={true}
        rowKey={record => record.id}
        responsive={true}
        pagination={pagination}
        size={'default'}
        showHeader
        columns={columns}
        dataSource={data}
        scroll={{y: '65vh', x: '100vw'}}
        onChange={handleChange}
        />
    </div>
  )
};

export default ActiveStationsTable;
