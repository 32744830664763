import {REPORT_PARAMETER_ACTION_TYPES} from "../typesAction";


const initialState = {
    reportParameters: [],
    current: null,
    loading: false,
    error: null
}

const reportParameterReducer = (state = initialState, action) => {
    switch (action.type) {
        case REPORT_PARAMETER_ACTION_TYPES.LOAD_REPORT_PARAMETER:
            return {
                ...state, loading: true
            };
        case REPORT_PARAMETER_ACTION_TYPES.CLEAR_LOAD_REPORT_PARAMETER:
            return {
                ...state, loading: false
            };
        case REPORT_PARAMETER_ACTION_TYPES.ERROR_REPORT_PARAMETER:
            return {
                ...state, error: action.payload, loading: false, current: null
            };
        case REPORT_PARAMETER_ACTION_TYPES.CLEAR_REPORT_PARAMETER_ERRORS:
            return {
                ...state, error: null, loading: false
            };
        case REPORT_PARAMETER_ACTION_TYPES.LIST_REPORT_PARAMETER:
            return {
                ...state, reportParameters: action.payload, loading: false, current: null
            };
        case REPORT_PARAMETER_ACTION_TYPES.ADD_REPORT_PARAMETER:
            return {
                ...state, loading: false
            };
        case REPORT_PARAMETER_ACTION_TYPES.SHOW_REPORT_PARAMETER:
            return {
                ...state, current: action.payload, loading: false
            };
        case REPORT_PARAMETER_ACTION_TYPES.EDIT_REPORT_PARAMETER:
            return {
                ...state, current: action.payload, loading: false
            };
        case REPORT_PARAMETER_ACTION_TYPES.DELETE_REPORT_PARAMETER:
            const updatedReportParameters = state.reportParameters.reportParameters.filter(reportParameter =>
                action.payload !== reportParameter.id);
            const updatedCount = state.reportParameters.reportParameters.count - 1;
            return {
                ...state, reportParameters: {...state.reportParameters,
                    reportParameters: updatedReportParameters, count: updatedCount},
                loading: false
            };
        default:
            return state;
    }

};

export default reportParameterReducer;