import {Form, Input, message, Breadcrumb, Layout, Card, Button} from 'antd';
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    setLoading,
    clearLoading,
    getCurrentUser,
    clearUserError, updateProfile, updatePassword
} from "../../redux/user/userActions";
import {HomeOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import {selectError, selectLoading, selectCurrentUser} from "../../redux/user/userSelector";


const {Content, Footer} = Layout;


const UserForm = (props) => {

    const intl = useIntl();
    const dispatch = useDispatch();

    const [userCreated, setUserCreated] = useState(false);
    const [passwordUpdated, setPasswordUpdated] = useState(false);

    const [form] = Form.useForm();
    const [formPassword] = Form.useForm();

    const onFinish = (values) => {
        props.updateProfile(props.history, values, setUserCreated);
    };
    const onFinishPassword = (values) => {
        props.updatePassword(props.history, values, setUserCreated);
    };

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    useEffect(() => {
        if (props.error) {
            const error = () => {
                if (typeof props.error == "string") {
                    message.error(props.error).then(props.clearUserError());
                } else if (props.error.hasOwnProperty('message')) {
                    message.error(props.error.message).then(props.clearFormDataError());
                } else {
                    props.error.errors.map(errorMessage =>
                        (message.error(errorMessage.message).then(props.clearUserError())));
                }

            };
            error();
        }

        if (props.user === undefined || props.user == null) {
            dispatch(setLoading());
            props.getCurrentUser(props.history);
        } else {
            const data = props.user;
            form.setFieldsValue({
                'firstName': data.firstName,
                'lastName': data.lastName,
                'email': data.email,
                'phone': data.phone,
                'job': data.job,
                'company': data.company,
            });
            dispatch(clearLoading());
        }


        if (userCreated) {
            message.info(intl.formatMessage({id: 'label.profile-updated'}));
            setUserCreated(false);
        }

        if (passwordUpdated) {
            message.info(intl.formatMessage({id: 'label.password-updated'}));
            setPasswordUpdated(false);
        }
        // eslint-disable-next-line
    }, [props.error, userCreated, props.user])

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage
                            id="menu.profile" defaultMessage="Profile"/></span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Card type="inner"
                          title={intl.formatMessage({id: 'label.edit-profile'})}>
                        <Form layout="horizontal" name="user_form" size="large"
                              labelCol={{span: 3,}} wrapperCol={{span: 14,}}
                              initialValues={{active: true}} form={form}
                              onFinish={onFinish} onFinishFailed={onFinishFailed}>
                            <Form.Item
                                name="firstName"
                                label={intl.formatMessage({id: 'label.first-name'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}

                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="lastName"
                                label={intl.formatMessage({id: 'label.last-name'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}

                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label={intl.formatMessage({id: 'label.email'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}

                            >
                                <Input type='email'/>
                            </Form.Item>
                            <Form.Item
                                name="job"
                                label={intl.formatMessage({id: 'label.job'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}

                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="company"
                                label={intl.formatMessage({id: 'label.company'})}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                label={intl.formatMessage({id: 'label.phone'})}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={props.loading}>
                                    <FormattedMessage id="label.update" defaultMessage="Update"/>
                                </Button>
                            </Form.Item>

                        </Form>
                    </Card>

                    <Card type="inner"
                          title={intl.formatMessage({id: 'label.update-password'})}>
                        <Form layout="horizontal" name="user_form_password" size="large"
                              labelCol={{span: 3,}} wrapperCol={{span: 14,}}
                              initialValues={{active: true}} form={formPassword}
                              onFinish={onFinishPassword} onFinishFailed={onFinishFailed}>
                            <Form.Item
                                label={<FormattedMessage id="label.password" defaultMessage="Password"/>}
                                name="password"
                                rules={[{
                                    required: true,
                                    message: intl.formatMessage({id: 'msg.input-required'})
                                }]}
                            >
                                <Input.Password/>
                            </Form.Item>

                            <Form.Item
                                label={<FormattedMessage id="label.confirm-password"
                                                         defaultMessage="Confirm password"/>}
                                name="password2"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage({id: 'msg.input-required'}),
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(intl.formatMessage({id: 'label.mismatch-password'})));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password/>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={props.loading}>
                                    <FormattedMessage id="label.update-password" defaultMessage="Update password"/>
                                </Button>
                            </Form.Item>

                        </Form>
                    </Card>
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    user: selectCurrentUser(state)
});

export default connect(mapStateToProps, {getCurrentUser, clearUserError, updateProfile, updatePassword})(UserForm);