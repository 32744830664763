import { Breadcrumb, Layout, message, Table, Popconfirm, Button } from "antd";
import {
  HomeOutlined,
  DeleteTwoTone,
  EditTwoTone,
  EyeTwoTone,
} from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import columnSearchProps from "../columnSearchProps";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  selectLoading,
  selectError,
  selectDataAnalysis,
} from "../../redux/dataAnalysis/dataAnalysisSelector";
import {
  listDataAnalysis,
  deleteDataAnalysis,
  clearDataAnalysisError,
} from "../../redux/dataAnalysis/dataAnalysisActions";
import { Link } from "react-router-dom";
import { listProjects } from "../../redux/project/projectActions";
import { selectProjects } from "../../redux/project/projectSelector";

const { Content, Footer } = Layout;

const DataAnalysis = (props) => {
  const intl = useIntl();
  const { dataAnalysis: dataSource, count, max, offset } = props.dataAnalysis;
  const { projects: currentUserProjects } = props.projects;

  const [isConfig, setIsConfig] = useState(
    props.location.pathname.includes("configuration")
  );
  const [isTimeSeries, setIsTimeSeries] = useState(
    props.location.pathname.includes("time-series")
  );
  const [isBarPlots, setIsBarPlots] = useState(
    props.location.pathname.includes("bar-plots")
  );
  const [isHeatMaps, setIsHeatMaps] = useState(
    props.location.pathname.includes("heat-maps")
  );
  const [isPointMaps, setIsPointMaps] = useState(
    props.location.pathname.includes("point-maps")
  );
  const [isChoroplethMaps, setIsChoroplethMaps] = useState(
    props.location.pathname.includes("choropleth-maps")
  );
  const [isBubbleMaps, setIsBubbleMaps] = useState(
    props.location.pathname.includes("bubble-maps")
  );

  useEffect(() => {
    setIsConfig(props.location.pathname.includes("configuration"));
    setIsTimeSeries(props.location.pathname.includes("time-series"));
    setIsBarPlots(props.location.pathname.includes("bar-plots"));
    setIsHeatMaps(props.location.pathname.includes("heat-maps"));
    setIsPointMaps(props.location.pathname.includes("point-maps"));
    setIsChoroplethMaps(props.location.pathname.includes("choropleth-maps"));
    setIsBubbleMaps(props.location.pathname.includes("bubble-maps"));

    //eslint-disable-next-line
  }, [props.location.pathname]);

  const getProjectNamesOrderedAsString = (projects) => {
    // Assuming projects is an array of objects with 'id' and 'name' keys
    // Extract the 'name' values from each project and join them with commas
    const projectNamesOrderedAsString = projects
      .map((project) => project.name)
      .sort((a, b) => (a === b ? 0 : a < b ? -1 : 1))
      .join(", ");

    return projectNamesOrderedAsString;
  };

  const columns = [
    {
      title: intl.formatMessage({ id: "label.dataAnalysis.id" }),
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "10%",
      fixed: true,
      sorter: true,
      ...columnSearchProps("id"),
    },
    {
      title: intl.formatMessage({ id: "label.dataAnalysis.actions" }),
      dataIndex: "actions",
      align: "center",
      width: "10%",
      fixed: true,
      render: (_, record) =>
        count >= 1 ? (
          <div className="actionsIcons">
            {isConfig ? (
              <>
                <Popconfirm
                  title={intl.formatMessage({ id: "msg.confirm-delete" })}
                  onConfirm={() => handleDelete(record.id)}
                >
                  <DeleteTwoTone
                    twoToneColor="red"
                    title={
                      intl.formatMessage({ id: "title.label.actions.remove" }) +
                      " " +
                      intl.formatMessage({ id: "title.label.actions.analysis" })
                    }
                  />
                </Popconfirm>
                <Link to={`/configuration/dataAnalysis/${record.id}`}>
                  <EditTwoTone
                    title={
                      intl.formatMessage({ id: "title.label.actions.edit" }) +
                      " " +
                      intl.formatMessage({
                        id: "title.label.actions.analysis",
                      })
                    }
                  />
                </Link>
              </>
            ) : null}

            {(() => {
              let linkTo;
              if (isConfig) {
                linkTo = `/configuration/dataAnalysis/view/${record.id}`;
              }
              if (isTimeSeries) {
                linkTo = `/analysis/time-series/view/${record.id}`;
              }
              if (isBarPlots) {
                linkTo = `/analysis/bar-plots/view/${record.id}`;
              }
              if (isHeatMaps) {
                linkTo = `/analysis/heat-maps/view/${record.id}`;
              }
              if (isPointMaps) {
                linkTo = `/analysis/point-maps/view/${record.id}`;
              }
              if (isChoroplethMaps) {
                linkTo = `/analysis/choropleth-maps/view/${record.id}`;
              }
              if (isBubbleMaps) {
                linkTo = `/analysis/bubble-maps/view/${record.id}`;
              }
              return (
                <Link
                  to={{
                    pathname: linkTo,
                  }}
                >
                  <EyeTwoTone
                    twoToneColor="#52c41a"
                    title={
                      intl.formatMessage({ id: "title.label.actions.show" }) +
                      " " +
                      intl.formatMessage({
                        id: "title.label.actions.analysis",
                      }) +
                      " " +
                      intl.formatMessage({ id: "title.label.actions.details" })
                    }
                  />
                </Link>
              );
            })()}
          </div>
        ) : null,
    },
    {
      title: intl.formatMessage({ id: "label.dataAnalysis.name" }),
      dataIndex: "name",
      key: "name",
      width: "20%",
      fixed: false,
      sorter: true,
      ...columnSearchProps("name"),
    },
    {
      title: intl.formatMessage({ id: "label.dataAnalysis.description" }),
      dataIndex: "description",
      key: "description",
      width: "30%",
      fixed: false,
      sorter: true,
      ...columnSearchProps("description"),
    },
    {
      title: intl.formatMessage({ id: "label.dataAnalysis.type" }),
      dataIndex: "type",
      key: "type",
      width: "15%",
      fixed: false,
      sorter: true,
      defaultSortOrder: "ascend",
      filterMultiple: true,
      filters: (() => {
        return [
          "TimeSeries",
          "BarPlot",
          "HeatMap",
          "ChoroplethMap",
          "PointMap",
          "BubbleMap",
        ]
          .map((item) => {
            let filter;
            if (item === "PointMap") {
              filter = {
                text: intl.formatMessage({
                  id: `label.dataAnalysis.type.${item}`,
                }),
                value: item,
                selectable: false,
                children: [
                  {
                    text: intl.formatMessage({
                      id: "label.dataAnalysis.type.SimplePointMap",
                    }),
                    value: "SimplePointMap",
                  },
                  {
                    text: intl.formatMessage({
                      id: "label.dataAnalysis.type.CategoricalPointMap",
                    }),
                    value: "CategoricalPointMap",
                  },
                ].sort((a, b) =>
                  a.text === b.text ? 0 : a.text < b.text ? -1 : 1
                ),
              };
            } else {
              filter = {
                text: intl.formatMessage({
                  id: `label.dataAnalysis.type.${item}`,
                }),
                value: item,
              };
            }

            return filter;
          })
          .sort((a, b) => (a.text === b.text ? 0 : a.text < b.text ? -1 : 1));
      })(),
    },
    isConfig
      ? {
          title: intl.formatMessage({ id: "label.dataAnalysis.lastUpdated" }),
          dataIndex: "lastUpdated",
          key: "lastUpdated",
          width: "15%",
          fixed: false,
          sorter: true,
          ...columnSearchProps("lastUpdated"),
        }
      : null,
    {
      title: intl.formatMessage({ id: "label.dataAnalysis.projects" }),
      dataIndex: "projects",
      key: "projects",
      width: "15%",
      fixed: false,
      sorter: true,
      filterMultiple: true,
      filters: (() =>
        currentUserProjects
          ? currentUserProjects?.map((project) => {
              return {
                text: project.name,
                value: project.id, //filtering by project id
              };
            })
          : null)(),
      render: (projects) => {
        return getProjectNamesOrderedAsString(projects);
      },
    },
  ];

  if (isConfig) {
    columns.push({
      title: intl.formatMessage({ id: "label.status" }),
      dataIndex: "status",
      key: "status",
      width: "15%",
      fixed: false,
      sorter: true,
      filterMultiple: true,
      filters: [
        {
          text: intl.formatMessage({ id: "label.draft" }),
          value: 0,
        },
        {
          text: intl.formatMessage({ id: "label.published" }),
          value: 1,
        },
        {
          text: intl.formatMessage({ id: "label.disabled" }),
          value: 2,
        },
      ],
      render: (text) => {
        switch (text) {
          case "0":
            return intl.formatMessage({ id: "label.draft" });
          case "1":
            return intl.formatMessage({ id: "label.published" });
          case "2":
            return intl.formatMessage({ id: "label.disabled" });
          default:
            return intl.formatMessage({ id: "label.draft" });
        }
      },
    });
  }

  useEffect(() => {
    //para cargar datos

    if (props.error) {
      const error = () => {
        message.error(props.error).then(props.clearDataAnalysisError());
      };
      error();
    } else {
      let filters = {};

      if (!isConfig) {
        filters.status = ["1"];
      }

      //filtering by type of analysis in user interface
      if (isTimeSeries) {
        filters.type = ["TimeSeries"];
      }
      if (isBarPlots) {
        filters.type = ["BarPlot"];
      }
      if (isHeatMaps) {
        filters.type = ["HeatMap"];
      }
      if (isPointMaps) {
        filters.type = ["CategoricalPointMap", "SimplePointMap"];
      }
      if (isChoroplethMaps) {
        filters.type = ["ChoroplethMap"];
      }
      if (isBubbleMaps) {
        filters.type = ["BubbleMap"];
      }
      const sorter = { field: "type", order: "ascend" };
      props.listDataAnalysis(props.history, undefined, filters, sorter);

      /**
       * loading all posible projects (maxProjects: 99999) for current user.
       * this updates props.projects store state projects list is used to build
       * the options of filter property of 'projects' column of <Table /> component
       */
      props.listProjects(props.history, undefined, { maxProjects: [99999] });
    }
    // eslint-disable-next-line
  }, [
    props.error,
    isConfig,
    isTimeSeries,
    isBarPlots,
    isHeatMaps,
    isPointMaps,
    isChoroplethMaps,
    isBubbleMaps,
  ]);

  const handleChange = (pagination, filters, sorter) => {
    props.listDataAnalysis(props.history, pagination, filters, sorter);
  };

  const handleDelete = (id) => {
    props.deleteDataAnalysis(props.history, id);
  };

  return (
    <Layout className="site-layout">
      <Content style={{ margin: "0 16px" }}>
        <Breadcrumb style={{ margin: "10px 0" }}>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          {isConfig ? (
            <>
              <Breadcrumb.Item>
                <span>
                  <FormattedMessage
                    id="menu.configuration"
                    defaultMessage="Configuration"
                  />
                </span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>
                  <FormattedMessage
                    id="menu.dataAnalysis"
                    defaultMessage="Data Analysis"
                  />
                </span>
              </Breadcrumb.Item>
            </>
          ) : (
            <Breadcrumb.Item>
              <span>
                <FormattedMessage
                  id="menu.Analysis"
                  defaultMessage="Analysis"
                />
              </span>
            </Breadcrumb.Item>
          )}
          {(() => {
            let idPart, msgDefault;
            if (isTimeSeries) {
              idPart = "TimeSeries";
              msgDefault = "Time Series";
            }
            if (isBarPlots) {
              idPart = "BarPlots";
              msgDefault = "Bar Plots";
            }
            if (isHeatMaps) {
              idPart = "HeatMaps";
              msgDefault = "Heat Maps";
            }
            if (isPointMaps) {
              idPart = "PointMaps";
              msgDefault = "Point Maps";
            }
            if (isChoroplethMaps) {
              idPart = "ChoroplethMaps";
              msgDefault = "Choropleth Maps";
            }
            if (isBubbleMaps) {
              idPart = "BubbleMaps";
              msgDefault = "Bubble Maps";
            }
            if (idPart) {
              return (
                <Breadcrumb.Item>
                  <span>
                    <FormattedMessage
                      id={"menu.Analysis." + idPart}
                      defaultMessage={msgDefault}
                    />
                  </span>
                </Breadcrumb.Item>
              );
            } else {
              return null;
            }
          })()}
        </Breadcrumb>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          {isConfig ? (
            <Link to="/configuration/dataAnalysis/new">
              <Button type="primary" style={{ marginBottom: 16 }}>
                <FormattedMessage
                  id="label.dataAnalysis.add-dataAnalysis"
                  defaultMessage="Add a Data Analysis"
                />
              </Button>
            </Link>
          ) : null}

          <Table
            bordered={true}
            loading={props.loading}
            rowKey={(record) => record.id}
            responsive={true}
            pagination={{
              current: offset / max + 1,
              showLessItems: true,
              pageSizeOptions: [10, 20, 50, 100],
              pageSize: max,
              defaultPageSize: max,
              total: count,
              showSizeChanger: true,
              showTotal: (total, range) =>
                intl.formatMessage(
                  {
                    id: "label.range-total",
                  },
                  { one: range[0], two: range[1], total: total }
                ),
            }}
            size={"default"}
            showHeader
            columns={columns.filter((column) => column !== null)}
            dataSource={dataSource}
            scroll={{ y: "65vh", x: "90vw" }}
            onChange={handleChange}
          />
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        ©{new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  loading: selectLoading(state),
  error: selectError(state),
  dataAnalysis: selectDataAnalysis(state),
  projects: selectProjects(state),
});

export default connect(mapStateToProps, {
  listDataAnalysis,
  deleteDataAnalysis,
  clearDataAnalysisError,
  listProjects,
})(DataAnalysis);
