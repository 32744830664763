import {createSelector} from "reselect";

const selectJob = state => state.job;

export const selectLoading = createSelector(
    [selectJob],
    (job) => job.loading
);

export const selectError = createSelector(
    [selectJob],
    (job) => job.error
);

export const selectJobs = createSelector(
    [selectJob],
    (job) => job.jobs
);

export const selectCurrentJob = createSelector(
    [selectJob],
    (job) => job.current
);