import axiosInstance from "../../axiosInstance";
import {clearLoading, setLoading} from "../../../redux/dataAnalysis/dataAnalysisActions";

export const getConfigViewForUser = (history, id) => async dispatch => {

    dispatch(setLoading());
    try {
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).get(`/dataAnalysis/${id}/get_config_view`);
        dispatch(clearLoading());
        return data;
    } catch (err) {
        console.log(err);
        dispatch(clearLoading());
    }
};

export const updateConfigView = (history, id, dataAnalysis, setStateFunction) => async dispatch => {

    dispatch(setLoading());
    try {
        // Send request
        await axiosInstance(history, dispatch).patch(`/dataAnalysis/${id}/update_config_view`, dataAnalysis);

        if (setStateFunction) {
            setStateFunction(prevState => {
                if (prevState === null || prevState === undefined) return true;

                if (Object?.keys(prevState)?.includes('isPersisted')) {
                    return {
                        ...prevState,
                        'isPersisted': true
                    };
                } else {
                    return true;
                };
            });
        };

        dispatch(clearLoading());
    } catch (err) {
        console.log(err);
        dispatch(clearLoading());
    }
};

export const resetConfigView = (history, id) => async dispatch => {

    dispatch(setLoading());
    try {
        // Send request
        axiosInstance(history, dispatch).patch(`/dataAnalysis/${id}/reset_config_view`).then ( () => {
            dispatch(clearLoading())
        });

    } catch (err) {
        console.log(err);
        dispatch(clearLoading());
    }
};
