/**
 * helper function that generates a random string of 12 characters length
 * @returns {string}
 */
export const getRandomString = () => Math.floor(Math.random() * 1e15).toString(16);


/**
 * Returns field width style for Select, Input and TreeSelect components
 * @param plusWidth
 * @returns {number}
 */
export const getFieldWidth = (plusWidth = 0) => {
    const baseWidth = 180;
    return baseWidth + plusWidth
};

/**
 * This function transforms recursively a given (nested) array into a flat array
 * @param v
 * @returns {Array} A flat array, whose items are not arrays.
 */
export const setNestedArrayIntoFlatArray = (v) => {

    if (!v) return null;

    const still = v.some(x => Array.isArray(x));
    let nv = [];
    if(still){
        v.forEach(x =>  {
            const nx = Array.isArray(x) ? x : [x];
            nv.push(...nx)
        });
        return setNestedArrayIntoFlatArray(nv)
    } else {
        return v
    }
};

/*
    Move html element to the center of viewport, for better visualization, when it is rendered.
    @argument {anchor} The id of the html element, that is used as reference to scroll the page.
*/
export const scrollToAnchor = (anchor) => {
    const parentElement = document.getElementById(anchor);
    const yPadding = 75;
    const y = parentElement.getBoundingClientRect().top + window.pageYOffset - yPadding;
    window.scrollTo({top: y, behavior: 'auto'});
};

/**
 * Creates a new array of objects from arrObjs so that the values of keys corresponding to argKey are unique.
 * @param {Object} arrObjs The input object.
 * @param {String} argKey The key of each object whose values must be unique in the returned array.
 * @returns {Array<Object>} An array of objects where where the values of key 'argKey' are unique.
 */
export const uniqueObjs = (arrObjs, argKey) => {
    let newArr = []
    for (let i = 0; i < arrObjs.length; i++) {
        let keytemp = arrObjs[i][argKey]
        let uniq = newArr.filter(obj => obj[argKey] === keytemp)
        if (uniq.length === 0) {
            newArr.push(arrObjs[i])
        }
    }
    return newArr
};

/**
 * The below code is creating an array of unique indexes for each variable within each data series. 
 * It does this by iterating through each data series and each variable within that series, 
 * and assigning a unique index to each variable. This allows us to easily access each variable 
 * within the data series by its unique index.
 * @param {Array} dataSeries 
 * @returns {Array} Array with unique index for each variable in dataSeries.
 */
export const createVariablesUniqueIndexes = (dataSeries) => {    
    let variablesUniqueIndexes = [];
    let count = 0;    
    const numberOfDataSeries = dataSeries.length;

    for (let i = 0; i < numberOfDataSeries; i++) {
        variablesUniqueIndexes[i] = [];
        const series = dataSeries[i];
        const variables = series.variables;
        const numberOfVariables = variables.length;
        for (let j = 0; j < numberOfVariables; j++) {
            variablesUniqueIndexes[i][j] = count++;
        }
    };

    return variablesUniqueIndexes;
};

export const clg = (label, value) => {
    console.log('\n-----------------------------------------');
    console.log(label, value);
    console.log('-----------------------------------------\n');
};

/**
 * Constant to be used by custom mapbox controls
 */
export const WIDTH_PLUS = 6;

/**
 * This function starts by initializing an empty array keys. It defines a 
 * helper function traverse that iterates over the properties of the current 
 * object (node). For each property, it checks if the key is already included 
 * in the keys array. If not, it adds the key to the array. If the property value 
 * is an object itself, it recursively calls traverse on that object.
 * After the traversal is done, the function sorts the keys array in ascending order 
 * and returns it. The sort() method sorts the elements of an array in place and returns 
 * the array. By default, it converts elements to strings and compares their sequences 
 * of UTF-16 code unit values, which works fine for our case since we're dealing with 
 * keys of an object.

 * @param {*} obj 
 * @returns 
 */
export const getUniqueKeys = (obj) => {
    let keys = [];
  
    if (!obj) return keys;
    
    // Recursive function to traverse the object
    function traverse(node) {
      Object.keys(node).forEach(key => {
        if (!keys.includes(key)) {
          keys.push(key);
        }
        if (typeof node[key] === 'object' && node[key] !== null) {
          traverse(node[key]);
        }
      });
    }
  
    traverse(obj);
    return keys.sort();
  };

  /**
   * This function works by filtering arr1 to include only items that are not found 
   * in arr2, and then concatenating the result with the filtered arr2 to include only 
   * items that are not found in arr1. The concat method is used to merge the two 
   * filtered arrays into one.
   * @param {*} arr1 
   * @param {*} arr2 
   * @returns 
   */
  export const getDifferentItems = (arr1, arr2) => {
    return arr1.filter(item => !arr2.includes(item)).concat(
      arr2.filter(item => !arr1.includes(item))
    );
  };


  
  export const extractUniqueValues = (obj, keys) => {
    let results = new Set(); // Use a Set to ensure uniqueness

    // Helper function to check if the current key is one of the keys we're looking for
    function isTargetKey(key) {
        return keys.includes(key);
    }

    // Recursive function to traverse the object
    function traverse(currentObj) {
        for (let key in currentObj) {
            if (currentObj.hasOwnProperty(key)) {
                if (Array.isArray(currentObj[key])) {
                    // If the current value is an array, iterate over its elements
                    currentObj[key].forEach(item => {
                        traverse(item);
                    });
                } else if (typeof currentObj[key] === 'object' && currentObj[key] !== null) {
                    // If the current value is an object, recursively traverse it
                    traverse(currentObj[key]);
                } else if (isTargetKey(key)) {
                    // If the current key is one of the target keys, add its value to the results
                    results.add(currentObj[key]);
                }
            }
        }
    }

    // Start the traversal
    traverse(obj);

    // Convert the Set to an array and return it
    return Array.from(results);
};