import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import userReducer from "./user/userReducer";
import authReducer from "./auth/authReducer";

import storage from 'redux-persist/lib/storage';
import projectReducer from "./project/projectReducer";
import permissionReducer from "./permission/permissionReducer";
import roleReducer from "./role/roleReducer";
import surveyFormReducer from "./survey/form/surveyFormReducer";
import formFieldReducer from "./survey/formField/formFieldReducer";
import formDataReducer from "./survey/data/formDataReducer";
import pathReducer from "./path/pathReducer";

import reportReducer from "./report/reportReducer";
import reportFileReducer from "./reportFile/reportFileReducer";
import reportParameterReducer from "./reportParameter/reportParameterReducer";

import dataAnalysisReducer from "./dataAnalysis/dataAnalysisReducer";

import staffReducer from "./staff/staffReducer";
import alertContactReducer from "./alertContact/alertContactReducer";
import layerReducer from "./layer/layerReducer";
import pointsOfInterestReducer from "./pointsOfInterest/pointsOfInterestReducer";
import jobReducer from "./job/jobReducer";

import dataSourceConfigurationReducer from "./dataSourceConfiguration/dataSourceConfigurationReducer";
import subscriptionReducer from "./subscription/subscriptionReducer";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user', 'auth']
}

const rootReducer = combineReducers({
    user: userReducer,
    auth: authReducer,
    project: projectReducer,
    permission: permissionReducer,
    role: roleReducer,
    surveyForm: surveyFormReducer,
    formField: formFieldReducer,
    formData: formDataReducer,
    path: pathReducer,
    report: reportReducer,
    reportFile: reportFileReducer,
    reportParameter: reportParameterReducer,
    staff: staffReducer,
    alertContact: alertContactReducer,
    subscription: subscriptionReducer,
    dataAnalysis: dataAnalysisReducer,
    layer: layerReducer,
    pointsOfInterest: pointsOfInterestReducer,
    dataSourceConfiguration: dataSourceConfigurationReducer,
    job: jobReducer
});

export default persistReducer(persistConfig, rootReducer);
