import axiosInstance from "../../utils/axiosInstance";
import {ROLE_ACTION_TYPES} from "../typesAction";


export const setLoading = () => {
    return {type: ROLE_ACTION_TYPES.LOAD_ROLE}
};

export const clearLoading = () => {
    return {type: ROLE_ACTION_TYPES.CLEAR_LOAD_ROLE}
};

export const clearRoleError = () => {
    return {type: ROLE_ACTION_TYPES.CLEAR_ROLE_ERRORS}
};

export const listRoles = (history, pagination, filters = {}, sorter) => async dispatch => {
    try {
        dispatch(setLoading());
        // Prepare all query parameters
        let queryParameters = {}
        Object.keys(filters).map(key => (
            queryParameters[key] = filters[key] == null ? filters[key] : filters[key][0])
        );
        if (sorter !== undefined) {
            queryParameters['sort'] = sorter['field']
            queryParameters['order'] = sorter['order'] === 'descend' ? 'desc' : 'asc'
        }
        if (pagination !== undefined) {
            queryParameters['offset'] = (pagination.current - 1) * pagination.pageSize
            queryParameters['max'] = pagination.pageSize
        }
        // Spread the parameters
        const params = {
            ...queryParameters
        };
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).get('/role', {params});
        // Update state
        dispatch({
            type: ROLE_ACTION_TYPES.LIST_ROLE,
            payload: data
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: ROLE_ACTION_TYPES.ERROR_ROLE,
            payload: err.message
        });
    }
};

export const addRole = (history, role, setRoleCreated) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).post('/role', role);
        // Update state
        dispatch({
            type: ROLE_ACTION_TYPES.ADD_ROLE,
            payload: data
        });
        setRoleCreated(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: ROLE_ACTION_TYPES.ERROR_ROLE,
            payload: error
        });
    }
};

export const getRole = (history, id) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        const {data} = await axiosInstance(history, dispatch).get(`/role/${id}`);
        // Update state
        dispatch({
            type: ROLE_ACTION_TYPES.SHOW_ROLE,
            payload: data
        });
        return data;
    } catch (err) {
        console.log(err);
        dispatch({
            type: ROLE_ACTION_TYPES.ERROR_ROLE,
            payload: err.message
        });
    }
};

export const editRole = (history, id, role, setRoleCreated) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).patch(`/role/${id}`, role);
        // Update state
        dispatch({
            type: ROLE_ACTION_TYPES.EDIT_ROLE,
            payload: data
        });
        setRoleCreated(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: ROLE_ACTION_TYPES.ERROR_ROLE,
            payload: error
        });
    }
};

export const deleteRole = (history, id) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        await axiosInstance(history, dispatch).delete(`/role/${id}`);
        // Update state
        dispatch({
            type: ROLE_ACTION_TYPES.DELETE_ROLE,
            payload: id
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: ROLE_ACTION_TYPES.ERROR_ROLE,
            payload: err.message
        });
    }
};
