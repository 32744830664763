import {Form, Input, Radio, message, Breadcrumb, Layout, Card, Button} from 'antd';
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {
    addPath,
    getPath,
    editPath,
    clearPathError
} from "../../redux/path/pathActions";
import {HomeOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {selectError, selectLoading, selectCurrentPath} from "../../redux/path/pathSelector";

const {Content, Footer} = Layout;

const PathForm = (props) => {
    const intl = useIntl();

    const [pathCreated, setPathCreated] = useState(false);
    const [edit, setEdit] = useState(props.location.pathname !== '/configuration/paths/new');
    const [form] = Form.useForm();

    //helpers ***
    const [showPath, setShowPath]= useState((fieldName) => {
        return form.getFieldValue(fieldName) !== 'R auto path'
    });
    
    const [standardItemDisabled, setStandardItemDisabled] = useState(() => {
        return form.getFieldValue('type') === 'standard'});
    const [customItemDisabled, setCustomItemDisabled] = useState(() => {
        return form.getFieldValue('type') === 'customized'});

    const onFinish = (values) => {
        if (edit) {
            props.editPath(props.history, props.match.params.id, values, setPathCreated);
        } else {
            props.addPath(props.history, values, setPathCreated);
        }
    };

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    useEffect(() => {

        if (props.error) {
            const error = () => {
                if (typeof props.error == "string") {
                    message.error(props.error).then(props.clearPathError());
                } else {
                    props.error.errors.map(errorMessage =>
                        (message.error(errorMessage.message).then(props.clearPathError())));
                }

            };
            error();
        }

        if (edit && !pathCreated && props.path === null && props.error === null) {
            props.getPath(props.history, props.match.params.id).then((data) => {
                if (data) {
                    form.setFieldsValue({
                        'name': data.name,
                        'description': data.description,
                        'mode': data.mode,
                        'path': data.path,
                        'selected': data.selected,
                        'app': data.app,
                        'os': data.os,
                        'type': data.type
                    });

                    setShowPath(() => {
                        return form.getFieldValue('name') !== 'R auto path'
                    });

                    setStandardItemDisabled(() => {
                        return form.getFieldValue('type') === 'standard'
                    });
                    setCustomItemDisabled(() => {
                        return form.getFieldValue('type') === 'customized'
                    });

                } else {
                    setEdit(false);
                }
            })
        }
        if (pathCreated) {
            if (edit) {
                message.info(intl.formatMessage({id: 'label.path.path-updated'}));
            } else {
                form.resetFields();
                message.info(intl.formatMessage({id: 'label.path.path-created'}));
            }
            setPathCreated(false);
        }

        // eslint-disable-next-line
    }, [props.error, pathCreated, edit, showPath, standardItemDisabled, customItemDisabled]);

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.configuration" defaultMessage="Configuration"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.paths" defaultMessage="Paths"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>{edit ? (<FormattedMessage
                            id="label.edit" defaultMessage="Edit"/>) : (
                            <FormattedMessage id="label.new" defaultMessage="New"/>)}</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Card type="inner"
                          title={edit ? <FormattedMessage id="label.path.edit-path" defaultMessage="Edit Path"/> :
                              <FormattedMessage id="label.path.create-path" defaultMessage="Create a Path"/>}
                          extra={<Link to='/configuration/paths'><FormattedMessage id="label.return"
                                                                        defaultMessage="Return"/></Link>}>
                        <Form layout="horizontal" name="path_form" size="large"
                              labelCol={{span: 3,}} wrapperCol={{span: 14,}}
                              form={form} initialValues={{mode:'manual', type:'customized'}}
                              onFinish={onFinish} onFinishFailed={onFinishFailed}>
                            <Form.Item
                                name="name"
                                label={intl.formatMessage({id: 'label.path.name'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                            >
                                <Input disabled = {standardItemDisabled}/>
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label={intl.formatMessage({id: 'label.path.description'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                            >
                                <Input disabled = {standardItemDisabled}/>
                            </Form.Item>
                            <Form.Item
                                name="mode"
                                label={intl.formatMessage({id: 'label.path.mode'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                            >
                                <Radio.Group disabled = {standardItemDisabled || customItemDisabled || !edit}>
                                    <Radio value={'auto'} >Auto</Radio>
                                    <Radio value={'manual'}>Manual</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                name="path"
                                label={intl.formatMessage({id: 'label.path.path'})}
                                rules={[{required: showPath, message: intl.formatMessage({id: 'msg.input-required'})}]}
                            >
                                <Input value={form.getFieldValue('name')}/>
                            </Form.Item>
                            <Form.Item
                                name="selected"
                                label={intl.formatMessage({id: 'label.path.selected'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                            >
                                <Radio.Group>
                                    <Radio value={true}><FormattedMessage id="label.path.yes" defaultMessage="Yes"/></Radio>
                                    <Radio value={false}><FormattedMessage id="label.path.no" defaultMessage="No"/></Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                name="app"
                                label={intl.formatMessage({id: 'label.path.app'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                            >
                                <Radio.Group disabled = {standardItemDisabled}>
                                    <Radio value={'R'}>R</Radio>
                                    <Radio value={'Python'}>Python</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                name="os"
                                label={intl.formatMessage({id: 'label.path.os'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                            >
                                <Radio.Group disabled = {standardItemDisabled}>
                                    <Radio value={'auto'} disabled = {customItemDisabled || !edit}>Auto</Radio>
                                    <Radio value={'Windows'}>Windows</Radio>
                                    <Radio value={'Unix-like'}>Unix-like</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                name="type"
                                label={intl.formatMessage({id: 'label.path.type'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                            >
                                <Radio.Group disabled = {standardItemDisabled || customItemDisabled || !edit}>
                                    <Radio value={'standard'}><FormattedMessage id="label.path.type.standard" defaultMessage="Standard"/></Radio>
                                    <Radio value={'customized'}><FormattedMessage id="label.path.type.customized" defaultMessage="Custom"/></Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={props.loading}>
                                    {edit ? (<FormattedMessage id="label.update" defaultMessage="Update"/>) : (
                                        <FormattedMessage id="label.submit" defaultMessage="Submit"/>)}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    path: selectCurrentPath(state)
});

export default connect(mapStateToProps, {addPath, getPath, editPath, clearPathError})(PathForm);