import {createSelector} from "reselect";

const selectSubscription = state => state.subscription;

export const selectLoading = createSelector(
    [selectSubscription],
    (subscription) => subscription.loading
);

export const selectError = createSelector(
    [selectSubscription],
    (subscription) => subscription.error
);

export const selectSubscriptions = createSelector(
    [selectSubscription],
    (subscription) => subscription.subscriptions
);

export const selectCurrentSubscription = createSelector(
    [selectSubscription],
    (subscription) => subscription.current
);