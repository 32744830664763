import {createSelector} from "reselect";

const selectReportParameter = state => state.reportParameter;

export const selectLoading = createSelector(
    [selectReportParameter],
    (reportParameter) => reportParameter.loading
);

export const selectError = createSelector(
    [selectReportParameter],
    (reportParameter) => reportParameter.error
);

export const selectReportParameters = createSelector(
    [selectReportParameter],
    (reportParameter) => reportParameter.reportParameters
);

export const selectCurrentReportParameter = createSelector(
    [selectReportParameter],
    (reportParameter) => reportParameter.current
);