import {
  Form,
  Radio,
  Select,
  Tooltip,
  Image,
  Divider,
  Checkbox,
  Slider,
  InputNumber,
  Input,
  Skeleton,
} from "antd";
import { useContext, useEffect, useRef, useState, Suspense } from "react";
import { useIntl } from "react-intl";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { group_of_bars } from "./images/images_base64";

import { DataAnalysisFormContext } from "../../../components/dataAnalysis/DataAnalysisForm";

import { antDividerBorderTopColor } from "../../utilConstants";

export const fieldPathReactivePersistenceMode = [
  "configEdit",
  "barPLotOptions",
  "reactivePersistenceMode",
];

export const fieldPathMaxNumberOfBars_isPersisted = [
  "configEdit",
  "barPLotOptions",
  "maxNumberOfBars_isPersisted",
];

export const BarPlotOptions = () => {
  const intl = useIntl();

  //-------------------------------------------------------
  //variables and functions from props and context - start
  //-------------------------------------------------------
  const {
    areAllFormFieldsJustFilledByCodeRef,
    changedField,
    configEdit: configEditContext,
    configEditFieldName,
    configView: configViewContext,
    dataZources,
    edit,
    form,
    getFieldPath,
    getIsFieldChanged,
    isNew,
    maxNumberOfBarsSlider,
    plotHeight,
    rootPathConfigEdit,
    setMaxNumberOfBarsSlider,
    view,
  } = useContext(DataAnalysisFormContext);

  const configEdit = view
    ? configViewContext
      ? configViewContext
      : configEditContext
    : configEditContext;
  //-------------------------------------------------------
  //variables and functions from props and context - end
  //-------------------------------------------------------

  //-------------------------------------
  //component specific variables - start
  //-------------------------------------
  const formItemComponentName = "barPlotOptions";
  const rootPath = getFieldPath(rootPathConfigEdit, [formItemComponentName]); // ['configEdit', 'barPlotOptions']
  //-------------------------------------
  //component specific variables - end
  //-------------------------------------

  //--------------------------------------------
  //other variables and local functions - start
  //--------------------------------------------
  const wrapperColLayout = {
    span: view ? 17 : null,
    offset: view ? null : null,
  };

  const labelColLayout = { span: view ? 6 : null, offset: view ? null : 0.5 };

  const setBarPlotOptionsField = (field, value) => {
    form.setFieldValue(["configEdit", "barplotOptions", field], value);
  };

  const orientation_scroll_slider_Ref = useRef(
    (() => {
      const min = plotHeight;
      const nTicks = 4;
      const stepTicks = 1000;
      const max = Math.ceil(min + nTicks * stepTicks);
      const step = 100;
      let marks = {};
      for (let i = 0; i <= nTicks; i++) {
        const value = Math.ceil(min + i * stepTicks);
        marks[value] = `${value.toString()}`;
      }

      return {
        min: min,
        max: max,
        step: step,
        marks: marks,
      };
    })()
  );

  //x_variable [configEditFieldName, 'dataSeries', 0, 'xaxes', 0, 'variables', 0, 'variables']
  const xVariablePath = getFieldPath(rootPathConfigEdit, [
    "dataSeries",
    0,
    "xaxes",
    0,
    "variables",
    0,
    "variables",
  ]);
  const getXVariableValue = () => {
    return form.getFieldValue(xVariablePath);
  };

  const { isPersisted, max } = maxNumberOfBarsSlider;

  const getFieldPathShort = (fieldString) =>
    getFieldPath(rootPath, [fieldString]);

  const getFieldValueShort = (fieldString) =>
    form.getFieldValue(getFieldPathShort(fieldString));

  const resetComponentFields = () => {
    const fieldsToReset = [
      "orientation",
      "orientation_scroll_required",
      "orientation_scroll_required_hidden",
      "orientation_scroll_slider",
      "orientation_scroll_slider",
      "barMode",
      "orderByVariable_included",
      "orderByVariable",
      "orderByVariableOrdering",
      "orderByVariable_showToUser",
      "maxNumberOfBars_included",
      "maxNumberOfBars",
      "maxNumberOfBars_showToUser",
      "maxNumberOfBars_min",
      "maxNumberOfBars_max",
      "maxNumberOfBars_step",
      "maxNumberOfBars_marks",
      "maxNumberOfBars_isPersisted",
      "reactivePersistenceMode",
      "tickLabelDisplayLength_included",
      "tickLabelDisplayLength",
      "tickLabelDisplayLength_showToUser",
    ];

    const fieldsToResetPaths = fieldsToReset.map((field) =>
      getFieldPath(rootPath, [field])
    );

    form.resetFields(fieldsToResetPaths);
  };

  //--------------------------------------------
  //other variables and local functions - end
  //--------------------------------------------

  //----------------
  //states - start
  //----------------
  const [maxNumberOfBarsIncluded, setMaxNumberOfBarsIncluded] = useState(
    getFieldValueShort("maxNumberOfBars_included")
  );
  const [maxNumberOfBarsShowToUser, setMaxNumberOfBarsShowToUser] = useState(
    getFieldValueShort("maxNumberOfBars_showToUser")
  );
  const [maxNumberOfBars, setMaxNumberOfBars] = useState(
    getFieldValueShort("maxNumberOfBars")
  );

  //setMaxNumberOfBars_recommendedValue
  const [maxNumberOfBars_recommendedValue] = useState(15);

  //msg_informationTip_FormItem_maxNumberOfBars_label
  const [
    msg_informationTip_FormItem_maxNumberOfBars_label,
    setMsg_informationTip_FormItem_maxNumberOfBars_label,
  ] = useState(null);

  //setMsg_informationTip_input_maxNumberOfBars
  const [
    msg_informationTip_input_maxNumberOfBars,
    setMsg_informationTip_input_maxNumberOfBars,
  ] = useState(null);

  const [variableOptions, setVariableOptions] = useState(null);

  const [is_x_variable_locations, setIs_x_variable_locations] = useState(() => {
    if (edit && !view) {
      return getXVariableValue() === "locations";
    }
    if (isNew) return undefined;
    if (view) {
      const x_variable =
        configEdit.dataSeries[0]?.xaxes[0]?.variables[0]?.variables;
      return x_variable === "locations";
    }
  });

  const [
    are_maxNumberOfBarsSlider_fields_ready_to_persist,
    setAre_maxNumberOfBarsSlider_fields_ready_to_persist,
  ] = useState(false);
  //----------------
  //states - end
  //----------------

  //-----------------
  //effects - start
  //-----------------

  useEffect(
    function useEffect_initializing_fields() {
      //initializing fields

      setBarPlotOptionsField("maxNumberOfBars_isPersisted", false);
      setBarPlotOptionsField("reactivePersistenceMode", false);

      setMsg_informationTip_FormItem_maxNumberOfBars_label(() => {
        return (
          <span>
            <Image src={`${group_of_bars}`} width={150} />
            <p>
              {intl.formatMessage({
                id: "BarPlotOptions.maxNumberOfBars.FormItem_label.InformationTip.msg",
              })}
            </p>
          </span>
        );
      });
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    function ue_setMsg_informationTip_input_maxNumberOfBars() {
      if (!maxNumberOfBars_recommendedValue) return;

      setMsg_informationTip_input_maxNumberOfBars(
        intl.formatMessage(
          { id: "BarPlotOptions.maxNumberOfBars.InformationTip.msg" },
          [maxNumberOfBars_recommendedValue + 1]
        )
      );
    },
    //eslint-disable-next-line
    [maxNumberOfBars_recommendedValue]
  );

  useEffect(
    function ue_setVariableOptions_view_edit() {
      if ((view || (edit && !view)) && configEdit) {
        setVariableOptions(() => {
          return getVariablesOptionsForBarPlot(configEdit?.dataSeries);
        });
      }
    },
    //eslint-disable-next-line
    [configEdit]
  );

  //when is_x_variable_locations changes in edit and isNew
  useEffect(() => {
    if (is_x_variable_locations === undefined) return;

    if (isNew) {
      [
        "orderByVariable_included",
        "maxNumberOfBars_included",
        "orderByVariable_showToUser",
        "maxNumberOfBars_showToUser",
      ].forEach((field) =>
        setBarPlotOptionsField(field, is_x_variable_locations)
      );
    }

    if (edit && !view && is_x_variable_locations) {
      ["orderByVariable_included", "maxNumberOfBars_included"].forEach(
        (field) => setBarPlotOptionsField(field, is_x_variable_locations)
      );
      setMaxNumberOfBarsIncluded(is_x_variable_locations);
    }

    //eslint-disable-next-line
  }, [isNew, edit, view, is_x_variable_locations]);

  useEffect(() => {
    if (!max) {
      return;
    }

    //updating fields
    ["min", "max", "step", "marks", "isPersisted"].forEach((fieldPart) => {
      const field = "maxNumberOfBars_" + fieldPart;

      let value;
      switch (fieldPart) {
        case "marks":
          value = JSON.stringify(maxNumberOfBarsSlider[fieldPart]);
          break;
        case "isPersisted":
          value = true;
          break;
        default:
          value = maxNumberOfBarsSlider[fieldPart];
      }

      setBarPlotOptionsField(field, value);
    });

    setAre_maxNumberOfBarsSlider_fields_ready_to_persist(true);

    //eslint-disable-next-line
  }, [max]);

  useEffect(() => {
    if (are_maxNumberOfBarsSlider_fields_ready_to_persist) {
      //persisting these changes in configView
      if (view && !isNew) {
        form.submit();
      }
    }

    //eslint-disable-next-line
  }, [are_maxNumberOfBarsSlider_fields_ready_to_persist]);

  useEffect(() => {
    if (isPersisted) {
      setMaxNumberOfBarsSlider((prevState) => {
        return {
          ...prevState,
          isPersisted: false,
        };
      });
    }

    setBarPlotOptionsField("maxNumberOfBars_isPersisted", false);
    setAre_maxNumberOfBarsSlider_fields_ready_to_persist(false);

    //eslint-disable-next-line
  }, [isPersisted]);

  useEffect(
    function useEffect_changedField_cases() {
      if (!changedField) return;

      let fieldPath, isFieldChanged, configEdit_;

      //CASE: 'variables'
      //setVariableOptions    ---
      //if 'yaxes' changed and it has an undefined item
      //for instance, when new yaxis is added
      fieldPath = getFieldPath(rootPathConfigEdit, [
        "dataSeries",
        "[0-9]{1,3}",
        "yaxes",
        "[0-9]{1,3}",
        "variables",
        "[0-9]{1,3}",
        "variables",
      ]);
      isFieldChanged = getIsFieldChanged(fieldPath, changedField.name);

      if (isFieldChanged) {
        if (isNew || (edit && !view)) {
          configEdit_ = form.getFieldValue("configEdit");
          if (configEdit_?.dataSeries?.length > 0) {
            setVariableOptions(() => {
              const variableOptions_ = getVariablesOptionsForBarPlot(
                configEdit_.dataSeries
              );
              const isUndefined =
                variableOptions_[0].label === undefined ||
                variableOptions_[0].value === undefined;
              return isUndefined ? null : variableOptions_;
            });
          }
          if (configEdit_.barPlotOptions.orderByVariable_included) {
            configEdit_.barPlotOptions.orderByVariable = undefined;
            form.setFieldValue([configEditFieldName], configEdit_);
          }
        }

        return; //quit useEffect
      }

      //CASE: 'alias'
      fieldPath = getFieldPath(rootPathConfigEdit, [
        "dataSeries",
        "[0-9]{1,3}",
        "yaxes",
        "[0-9]{1,3}",
        "variables",
        "[0-9]{1,3}",
        "alias",
      ]);
      isFieldChanged = getIsFieldChanged(fieldPath, changedField.name);

      if (isFieldChanged) {
        configEdit_ = form.getFieldValue("configEdit");
        if (configEdit_?.dataSeries?.length > 0) {
          setVariableOptions(() => {
            const variableOptions_ = getVariablesOptionsForBarPlot(
              configEdit_.dataSeries
            );
            const isUndefined =
              variableOptions_[0].label === undefined ||
              variableOptions_[0].value === undefined;
            return isUndefined ? null : variableOptions_;
          });
        }
        if (configEdit_.barPlotOptions.orderByVariable_included) {
          configEdit_.barPlotOptions.orderByVariable = undefined;
          form.setFieldValue([configEditFieldName], configEdit_);
        }

        return; //quit useEffect
      }

      //CASE: xVariablePath
      fieldPath = xVariablePath;
      isFieldChanged = getIsFieldChanged(fieldPath, changedField.name);

      if (isFieldChanged) {
        setIs_x_variable_locations(changedField.value === "locations");

        return; //quit useEffect
      }

      //other cases
      const fieldCases = [
        { field: "orderByVariable_included", func: setBarPlotOptionsField },
        { field: "maxNumberOfBars_included", func: setMaxNumberOfBarsIncluded },
        {
          field: "maxNumberOfBars_showToUser",
          func: setMaxNumberOfBarsShowToUser,
        },
        { field: "maxNumberOfBars", func: setMaxNumberOfBars },
      ];

      for (const fieldCase of fieldCases) {
        fieldPath = getFieldPath(rootPath, [fieldCase["field"]]);
        isFieldChanged = getIsFieldChanged(fieldPath, changedField.name);

        if (isFieldChanged) {
          if (fieldCase["field"] === "orderByVariable_included") {
            if (changedField.value) {
              fieldCase["func"]("orderByVariable", variableOptions[0].value);
            }
          } else {
            fieldCase["func"](changedField.value);
          }

          return; //quit useEffect
        }
      }

      //reset component when type and project changes
      fieldPath = ["(type|project)"];
      isFieldChanged = getIsFieldChanged(fieldPath, changedField.name);

      if (isFieldChanged) {
        if (areAllFormFieldsJustFilledByCodeRef.current) return;

        resetComponentFields();

        return;
      }
    },
    //eslint-disable-next-line
    [changedField]
  );

  useEffect(
    () => {
      if (areAllFormFieldsJustFilledByCodeRef.current) return;

      resetComponentFields();
    },
    //eslint-disable-next-line
    [dataZources]
  );
  //-----------------
  //effects - end
  //-----------------

  //jsx
  return (
    <Suspense fallback={<Skeleton active={true} />}>
      <div id={"barPlotOptions"}>
        {view ? null : (
          <Form.Item wrapperCol={{ span: 17 }}>
            <Divider
              style={
                antDividerBorderTopColor
                  ? { borderTopColor: antDividerBorderTopColor }
                  : null
              }
              orientation={"left"}
            >
              {intl.formatMessage({
                id: "label.FormItems.BarPlotOptions.divider",
              })}
            </Divider>
          </Form.Item>
        )}
        <Form.Item
          wrapperCol={wrapperColLayout}
          labelCol={labelColLayout}
          label={intl.formatMessage({ id: "label.BarPLotOptions.Orientation" })}
          name={getFieldPathShort("orientation")}
          initialValue={is_x_variable_locations ? "h" : "v"}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "msg.input-required.BarPLotOptions.Orientation",
              }),
            },
          ]}
        >
          <Radio.Group>
            <Radio value={"v"}>
              {intl.formatMessage({
                id: "BarPLotOptions.Orientation.Radio.vertical",
              })}
            </Radio>
            <Radio value={"h"}>
              <>
                {intl.formatMessage({
                  id: "BarPLotOptions.Orientation.Radio.horizontal",
                })}
                <span style={{ leftMargin: "10px" }}>&ensp;</span>
                <span
                  style={{
                    display:
                      getFieldValueShort("orientation") === "v" ? "none" : null,
                  }}
                >
                  <Form.Item
                    noStyle
                    name={getFieldPathShort("orientation_scroll_required")}
                    valuePropName={"checked"}
                    initialValue={
                      getFieldValueShort("orientation") === "h"
                        ? false
                        : undefined
                    }
                    dependencies={[
                      [
                        configEditFieldName,
                        formItemComponentName,
                        "orientation_scroll_required_hidden",
                      ],
                    ]}
                    hidden={
                      view &&
                      getFieldValueShort("orientation_scroll_required_hidden")
                    }
                  >
                    <Checkbox>
                      {intl.formatMessage({
                        id: "BarPLotOptions.Orientation.orientation_scroll_required",
                      })}
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name={getFieldPathShort(
                      "orientation_scroll_required_hidden"
                    )}
                    valuePropName={"checked"}
                    initialValue={false}
                    hidden
                  >
                    <Checkbox />
                  </Form.Item>
                  <span
                    style={{
                      display: !getFieldValueShort(
                        "orientation_scroll_required"
                      )
                        ? "none"
                        : "block",
                    }}
                  >
                    <Form.Item
                      noStyle
                      name={getFieldPathShort("orientation_scroll_slider_min")}
                      valuePropName={"checked"}
                      initialValue={orientation_scroll_slider_Ref.current.min}
                      hidden
                    >
                      <InputNumber />
                    </Form.Item>
                    <Form.Item
                      noStyle
                      name={getFieldPathShort("orientation_scroll_slider")}
                      dependencies={[
                        getFieldPath(rootPath, [
                          "orientation_scroll_slider_min",
                        ]),
                      ]}
                    >
                      {(() => {
                        const sliderMin = getFieldValueShort(
                          "orientation_scroll_slider_min"
                        );
                        orientation_scroll_slider_Ref.current.min = sliderMin;

                        return (
                          <Slider
                            min={orientation_scroll_slider_Ref.current.min}
                            max={orientation_scroll_slider_Ref.current.max}
                            step={orientation_scroll_slider_Ref.current.step}
                            marks={orientation_scroll_slider_Ref.current.marks}
                            included={true}
                            tooltip={{ formatter: (value) => `${value} px` }}
                            id={"orientation_scroll_slider"}
                            style={{ width: "400px" }}
                          />
                        );
                      })()}
                    </Form.Item>
                    <span style={{ margin: "auto" }}>
                      {intl.formatMessage({
                        id: "label.BarPlotOptions.plotHeight",
                      })}
                    </span>
                  </span>
                </span>
              </>
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          wrapperCol={wrapperColLayout}
          labelCol={labelColLayout}
          label={intl.formatMessage({ id: "label.BarPLotOptions.BarMode" })}
          name={getFieldPathShort("barMode")}
          initialValue={"group"}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "msg.input-required.BarPLotOptions.BarMode",
              }),
            },
          ]}
          hidden={variableOptions?.length === 1}
        >
          <Radio.Group disabled={variableOptions?.length === 1}>
            <Radio value={"group"}>
              {intl.formatMessage({ id: "label.BarPLotOptions.BarMode.group" })}
            </Radio>
            <Radio value={"stack"}>
              {intl.formatMessage({ id: "label.BarPLotOptions.BarMode.stack" })}
            </Radio>
          </Radio.Group>
        </Form.Item>
        {getXVariableValue() === "date_created" ? null : (
          <>
            <Form.Item
              wrapperCol={wrapperColLayout}
              labelCol={labelColLayout}
              label={
                <>
                  <Form.Item
                    name={getFieldPathShort("orderByVariable_included")}
                    noStyle
                    valuePropName={"checked"}
                    initialValue={is_x_variable_locations}
                    hidden={view}
                  >
                    <Checkbox
                      disabled={
                        variableOptions === null ||
                        variableOptions?.length === 0 ||
                        is_x_variable_locations
                      }
                    />
                  </Form.Item>
                  <span
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    {view
                      ? intl.formatMessage({
                          id: "label.BarPLotOptions.orderByVariable",
                        })
                      : intl.formatMessage({
                          id: "label.BarPLotOptions.orderByVariable.default",
                        })}
                  </span>
                </>
              }
              colon={!!getFieldValueShort("orderByVariable_included")}
              hidden={view && !getFieldValueShort("orderByVariable_showToUser")}
              style={{
                display:
                  view &&
                  (!getFieldValueShort("orderByVariable_included") ||
                    !is_x_variable_locations)
                    ? "none"
                    : null,
              }}
            >
              <Form.Item
                name={getFieldPathShort("orderByVariable")}
                rules={[
                  {
                    required: getFieldValueShort("orderByVariable_included"),
                    message: intl.formatMessage({
                      id: "msg.input-required.BarPLotOptions.orderByVariable",
                    }),
                  },
                ]}
                noStyle
                dependencies={[
                  getFieldPath(rootPath, ["orderByVariable_included"]),
                ]}
                initialValue={
                  isNew
                    ? variableOptions
                      ? variableOptions[0].value
                      : undefined
                    : undefined
                }
              >
                <Select
                  style={{
                    width: "30%",
                    display: !getFieldValueShort("orderByVariable_included")
                      ? "none"
                      : null,
                  }}
                  placeholder={
                    view
                      ? intl.formatMessage({
                          id: "select.BarPLotOptions.orderByVariable.placeholder",
                        })
                      : intl.formatMessage({
                          id: "select.BarPLotOptions.orderByVariable.placeholder.default",
                        })
                  }
                  options={variableOptions}
                  disabled={
                    (!view && !variableOptions) ||
                    (view && variableOptions?.length === 1)
                  }
                  dropdownMatchSelectWidth={190}
                />
              </Form.Item>
              <Form.Item
                name={getFieldPathShort("orderByVariableOrdering")}
                rules={[
                  {
                    required: getFieldValueShort("orderByVariable_included"),
                    message: intl.formatMessage({
                      id: "msg.input-required.BarPLotOptions.orderByVariableOrdering",
                    }),
                  },
                ]}
                noStyle
                dependencies={[
                  [
                    configEditFieldName,
                    formItemComponentName,
                    "orderByVariable_included",
                  ],
                ]}
                initialValue={"ascending"}
              >
                <Select
                  style={{
                    width: view ? "20%" : "15%",
                    display: !getFieldValueShort("orderByVariable_included")
                      ? "none"
                      : null,
                  }}
                  options={[
                    {
                      label: intl.formatMessage({
                        id: "BarplotOptions.orderByVariableOrdering.ascending",
                      }),
                      value: "ascending",
                    },
                    {
                      label: intl.formatMessage({
                        id: "BarplotOptions.orderByVariableOrdering.descending",
                      }),
                      value: "descending",
                    },
                  ]}
                  disabled={!view && !variableOptions}
                />
              </Form.Item>
              <span
                style={{
                  marginLeft: "10px",
                  display: !getFieldValueShort("orderByVariable_included")
                    ? "none"
                    : null,
                }}
                hidden={view}
              >
                <span style={{ marginRight: "10px" }} hidden={view}>
                  {intl.formatMessage({
                    id: "label.BarPLotOptions.showToUser",
                  })}
                </span>
                <Form.Item
                  name={getFieldPathShort("orderByVariable_showToUser")}
                  noStyle
                  hidden={view}
                  valuePropName={"checked"}
                  initialValue={is_x_variable_locations}
                  dependencies={[
                    getFieldPath(rootPath, ["orderByVariable_included"]),
                  ]}
                >
                  <Checkbox />
                </Form.Item>
              </span>
            </Form.Item>
            <span
              style={{
                display: view && !maxNumberOfBarsIncluded ? "none" : "block",
                height: view && !maxNumberOfBarsShowToUser ? 0 : null,
              }}
            >
              <Form.Item
                label={
                  <>
                    <Form.Item
                      name={getFieldPathShort("maxNumberOfBars_included")}
                      noStyle
                      valuePropName={"checked"}
                      initialValue={is_x_variable_locations}
                      hidden={view}
                    >
                      <Checkbox disabled={!!is_x_variable_locations} />
                    </Form.Item>
                    <span hidden={view && !maxNumberOfBarsShowToUser}>
                      <span style={{ marginLeft: "10px" }}>
                        {intl.formatMessage({
                          id: "label.BarPLotOptions.maxNumberOfBars",
                        })}
                      </span>
                      <span
                        id={"informationTip_input_maxNumberOfBars"}
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        <Tooltip
                          title={
                            msg_informationTip_FormItem_maxNumberOfBars_label
                          }
                          placement={"topRight"}
                        >
                          <InfoCircleTwoTone />
                        </Tooltip>
                      </span>
                      <span
                        id={"informationTip_input_maxNumberOfBars"}
                        style={{
                          display:
                            maxNumberOfBars > maxNumberOfBars_recommendedValue
                              ? "inline"
                              : "none",
                          marginLeft: "10px",
                        }}
                      >
                        <Tooltip
                          title={msg_informationTip_input_maxNumberOfBars}
                          placement={"topRight"}
                        >
                          <InfoCircleTwoTone twoToneColor={"orange"} />
                        </Tooltip>
                      </span>
                    </span>
                  </>
                }
                wrapperCol={wrapperColLayout}
                labelCol={labelColLayout}
                colon={!view && getFieldValueShort("maxNumberOfBars_included")}
              >
                <span
                  style={{
                    display:
                      !view && !maxNumberOfBarsIncluded ? "none" : "block",
                  }}
                  hidden={view && !maxNumberOfBarsShowToUser}
                >
                  <Form.Item
                    name={getFieldPathShort("maxNumberOfBars")}
                    initialValue={maxNumberOfBars_recommendedValue}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: "msg.input-required.BarPLotOptions.maxNumberOfBars",
                        }),
                      },
                    ]}
                    noStyle
                  >
                    <Slider
                      min={maxNumberOfBarsSlider.min}
                      max={maxNumberOfBarsSlider.max}
                      step={maxNumberOfBarsSlider.step}
                      marks={maxNumberOfBarsSlider.marks}
                      included={true}
                      tooltip={{
                        formatter: (value) =>
                          `${value} ${intl.formatMessage({
                            id: "label.BarPlotOptions.bars",
                          })}`,
                      }}
                      style={{ width: "50%" }}
                    />
                  </Form.Item>
                  <span style={{ marginLeft: "10px" }} hidden={view}>
                    <span style={{ marginRight: "10px" }}>
                      {intl.formatMessage({
                        id: "label.BarPLotOptions.showToUser",
                      })}
                    </span>
                    <Form.Item
                      name={getFieldPathShort("maxNumberOfBars_showToUser")}
                      noStyle
                      valuePropName={"checked"}
                      initialValue={is_x_variable_locations}
                    >
                      <Checkbox />
                    </Form.Item>
                  </span>
                </span>
                <Form.Item
                  label={intl.formatMessage({
                    id: "label.BarPlotOptions.maxNumberOfBars_slider",
                  })}
                  style={{ height: view ? "0px" : undefined }}
                  hidden
                >
                  <Form.Item
                    label={intl.formatMessage({
                      id: "label.BarPlotOptions.maxNumberOfBars_min",
                    })}
                    name={getFieldPathShort("maxNumberOfBars_min")}
                    initialValue={maxNumberOfBarsSlider.min}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: "msg.input-required.BarPLotOptions.maxNumberOfBars_min",
                        }),
                      },
                    ]}
                  >
                    <InputNumber disabled />
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({
                      id: "label.BarPlotOptions.maxNumberOfBars_max",
                    })}
                    name={getFieldPathShort("maxNumberOfBars_max")}
                    initialValue={maxNumberOfBarsSlider.max}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: "msg.input-required.BarPLotOptions.maxNumberOfBars_max",
                        }),
                      },
                    ]}
                  >
                    <InputNumber disabled />
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({
                      id: "label.BarPlotOptions.maxNumberOfBars_step",
                    })}
                    name={getFieldPathShort("maxNumberOfBars_step")}
                    initialValue={maxNumberOfBarsSlider.step}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: "msg.input-required.BarPLotOptions.maxNumberOfBars_step",
                        }),
                      },
                    ]}
                  >
                    <InputNumber disabled />
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({
                      id: "label.BarPlotOptions.maxNumberOfBars_marks",
                    })}
                    name={getFieldPathShort("maxNumberOfBars_marks")}
                    initialValue={
                      maxNumberOfBarsSlider?.marks
                        ? JSON.stringify(maxNumberOfBarsSlider.marks)
                        : ""
                    }
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: "msg.input-required.BarPLotOptions.maxNumberOfBars_marks",
                        }),
                      },
                    ]}
                  >
                    <Input style={{ width: "25%" }} disabled />
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({
                      id: "label.BarPlotOptions.maxNumberOfBars_isPersisted",
                    })}
                    name={getFieldPathShort("maxNumberOfBars_isPersisted")}
                    initialValue={false}
                    valuePropName={"checked"}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: "msg.input-required.BarPLotOptions.maxNumberOfBars_isPersisted",
                        }),
                      },
                    ]}
                  >
                    <Checkbox disabled />
                  </Form.Item>
                </Form.Item>
              </Form.Item>
            </span>
          </>
        )}
        <Form.Item
          label={intl.formatMessage({
            id: "BarPLotOptions.reactivePersistenceMode.label",
          })}
          hidden
          wrapperCol={wrapperColLayout}
          labelCol={labelColLayout}
        >
          <Form.Item
            noStyle
            name={getFieldPathShort("reactivePersistenceMode")}
            initialValue={false}
          >
            <Select
              style={{ width: "250px" }}
              options={[
                {
                  label: intl.formatMessage({
                    id: "BarPLotOptions.reactivePersistenceMode.persist",
                  }),
                  value: true,
                },
                {
                  label: intl.formatMessage({
                    id: "BarPLotOptions.reactivePersistenceMode.do_not_persist",
                  }),
                  value: false,
                },
              ]}
              disabled={true}
            />
          </Form.Item>
          <span style={{ paddingLeft: "10px" }}>
            {intl.formatMessage({
              id: "BarPLotOptions.reactivePersistenceMode.info",
            })}
          </span>
        </Form.Item>
        {["date_created"].includes(form.getFieldValue(xVariablePath)) ? null : (
          <Form.Item
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 12 }}
            label={
              <>
                <Form.Item
                  name={getFieldPathShort("tickLabelDisplayLength_included")}
                  noStyle
                  valuePropName={"checked"}
                  initialValue={false}
                  hidden={view}
                >
                  <Checkbox />
                </Form.Item>
                <span
                  style={{
                    marginLeft: view ? "10px" : "0px",
                  }}
                >
                  {view
                    ? intl.formatMessage({
                        id: "BarPLotOptions.tickLabelDisplayLength.label",
                      })
                    : intl.formatMessage({
                        id: "BarPLotOptions.tickLabelDisplayLength.label.default",
                      })}
                </span>
              </>
            }
            hidden={
              view
                ? !getFieldValueShort("tickLabelDisplayLength_included") ||
                  !getFieldValueShort("tickLabelDisplayLength_showToUser")
                : view
            }
            colon={form.getFieldValue([
              configEditFieldName,
              formItemComponentName,
              "tickLabelDisplayLength_included",
            ])}
          >
            <span
              style={{
                display: getFieldValueShort("tickLabelDisplayLength_included")
                  ? "block"
                  : "none",
              }}
            >
              <Form.Item
                name={getFieldPathShort("tickLabelDisplayLength")}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "msg.input-required.BarPLotOptions.tickLabelDisplayLength",
                    }),
                  },
                ]}
                initialValue={3}
                noStyle
                hidden={
                  view
                    ? !getFieldValueShort("tickLabelDisplayLength_showToUser")
                    : view
                }
              >
                <InputNumber
                  style={{ width: "200px" }}
                  placeholder={intl.formatMessage({
                    id: "BarPLotOptions.tickLabelDisplayLength.input.placeholder",
                  })}
                  min={1}
                />
              </Form.Item>
              <span style={{ marginLeft: "10px" }} hidden={view}>
                <span style={{ marginRight: "10px" }}>
                  {intl.formatMessage({
                    id: "label.BarPLotOptions.showToUser",
                  })}
                </span>
                <Form.Item
                  name={getFieldPathShort("tickLabelDisplayLength_showToUser")}
                  noStyle
                  valuePropName={"checked"}
                  initialValue={false}
                >
                  <Checkbox />
                </Form.Item>
              </span>
            </span>
          </Form.Item>
        )}
      </div>
    </Suspense>
  );
};

export const getVariablesOptionsForBarPlot = (dataSeries) => {
  return dataSeries
    ?.map((series) => {
      return series?.yaxes?.map((yaxis) => {
        if (yaxis === undefined || yaxis?.type !== "bar") {
          return null;
        } else {
          return yaxis?.variables?.map((item) => {
            return {
              label: item?.alias,
              value: Array.isArray(item?.variables)
                ? JSON.stringify({
                    variables: item?.variables.join("+"),
                    alias: item?.alias,
                  })
                : JSON.stringify({
                    variables: item?.variables,
                    alias: item?.alias,
                  }),
            };
          });
        }
      });
    })
    .flat(3)
    .filter((option) => option !== null);
};

export default BarPlotOptions;
