import axiosInstance from "../../utils/axiosInstance";
import {SUBSCRIPTION_ACTION_TYPES} from "../typesAction";


export const setLoading = () => {
    return {type: SUBSCRIPTION_ACTION_TYPES.LOAD_SUBSCRIPTION}
};

export const clearLoading = () => {
    return {type: SUBSCRIPTION_ACTION_TYPES.CLEAR_LOAD_SUBSCRIPTION}
};

export const clearSubscriptionError = () => {
    return {type: SUBSCRIPTION_ACTION_TYPES.CLEAR_SUBSCRIPTION_ERRORS}
};

export const listSubscriptions = (history, pagination, filters = {}, sorter) => async dispatch => {
    try {
        dispatch(setLoading());
        // Prepare all query parameters
        let queryParameters = {}
        Object.keys(filters).map(key => (
            queryParameters[key] = filters[key] == null ? filters[key] : filters[key][0])
        );
        if (sorter !== undefined) {
            queryParameters['sort'] = sorter['field']
            queryParameters['order'] = sorter['order'] === 'descend' ? 'desc' : 'asc'
        }
        if (pagination !== undefined) {
            queryParameters['offset'] = (pagination.current - 1) * pagination.pageSize
            queryParameters['max'] = pagination.pageSize
        }
        // Spread the parameters
        const params = {
            ...queryParameters
        };
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).get('/subscription', {params});
        // Update state
        dispatch({
            type: SUBSCRIPTION_ACTION_TYPES.LIST_SUBSCRIPTION,
            payload: data
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: SUBSCRIPTION_ACTION_TYPES.ERROR_SUBSCRIPTION,
            payload: err.message
        });
    }
};

export const addSubscription = (history, subscription, setSubscriptionCreated) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).post('/subscription', subscription);
        // Update state
        dispatch({
            type: SUBSCRIPTION_ACTION_TYPES.ADD_SUBSCRIPTION,
            payload: data
        });
        setSubscriptionCreated(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: SUBSCRIPTION_ACTION_TYPES.ERROR_SUBSCRIPTION,
            payload: error
        });
    }
};

export const getSubscription = (history, id) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        const {data} = await axiosInstance(history, dispatch).get(`/subscription/${id}`);
        // Update state
        dispatch({
            type: SUBSCRIPTION_ACTION_TYPES.SHOW_SUBSCRIPTION,
            payload: data
        });
        return data;
    } catch (err) {
        console.log(err);
        dispatch({
            type: SUBSCRIPTION_ACTION_TYPES.ERROR_SUBSCRIPTION,
            payload: err.message
        });
    }
};

export const editSubscription = (history, id, subscription, setSubscriptionCreated) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).patch(`/subscription/${id}`, subscription);
        // Update state
        dispatch({
            type: SUBSCRIPTION_ACTION_TYPES.EDIT_SUBSCRIPTION,
            payload: data
        });
        setSubscriptionCreated(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: SUBSCRIPTION_ACTION_TYPES.ERROR_SUBSCRIPTION,
            payload: error
        });
    }
};

export const deleteSubscription = (history, id) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        await axiosInstance(history, dispatch).delete(`/subscription/${id}`);
        // Update state
        dispatch({
            type: SUBSCRIPTION_ACTION_TYPES.DELETE_SUBSCRIPTION,
            payload: id
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: SUBSCRIPTION_ACTION_TYPES.ERROR_SUBSCRIPTION,
            payload: err.message
        });
    }
};
export const testSubscription = (history, id) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        const {data} = await axiosInstance(history, dispatch).get(`/subscription/${id}/test`);
        dispatch(clearLoading())
        return data
    } catch (err) {
        console.log(err);
        dispatch({
            type: SUBSCRIPTION_ACTION_TYPES.ERROR_SUBSCRIPTION,
            payload: err.message
        });
    }
};
