/*
VA-Team
 */
import "./StylesControlByRadio.css";
import {Button} from "../Button/Button";
import ReactDOM from "react-dom";
import {MAPBOX_STYLES} from "./MAPBOX_STYLES";
import React, {useState} from "react";
import {Radio} from "antd";
import { WIDTH_PLUS } from "../../../utils/utils";


/**
 * A Mapbox control that uses a button and a custom radio group react element to change the styles of a
 * Mapbox map.
 */
class StylesControlByRadio {
    mapStyles;
    mapStyle;
    setLastControlEvent;
    maxHeight;

    constructor (mapStyles, mapStyle, setLastControlEvent, maxHeight){
        this.mapStyles = mapStyles;
        this.mapStyle = mapStyle;
        this.setLastControlEvent = setLastControlEvent;
        this.maxHeight = maxHeight;
    };

    onAdd(map){
        //setting map
        this.map = map;

        //setting container element
        this.containerElement = document.createElement('div');
        this.containerElement.classList.add('mapboxgl-ctrl', 'div-ctrl');

        //setting button element
        this.buttonElement = Button('map-styles');
        this.buttonElement.title = "Select Map Style";
        this.buttonElement.type = "button";
        //appending button element to container element
        this.containerElement.appendChild(this.buttonElement);

        //creating container for Radio.Group
        this.divRadioGroupElement = document.createElement('div');
        this.divRadioGroupElement.classList.add('map-control-div-radio-group');
        this.divRadioGroupElement.setAttribute('id','custom-styles-control-radio-group-container');
        //css style rules of divRadioGroupElement
        const maxNumChars = Math.max(...(MAPBOX_STYLES.filter(style =>
            this.mapStyles.includes(style.value)).map(style =>
            style.label.length)));
        const widthPlus = WIDTH_PLUS;
        const width = (maxNumChars+widthPlus)+'ch';
        const height = 'auto';
        const heightTop = '40px';
        this.divRadioGroupElement.style.display = 'none';
        this.divRadioGroupElement.style.width = width;
        this.divRadioGroupElement.style.height = height;
        this.divRadioGroupElement.style.borderRadius = '6px';
        this.divRadioGroupElement.style.backgroundColor = '#fff';
        this.divRadioGroupElement.style.position = 'relative';
        this.divRadioGroupElement.style.right = width;
        this.divRadioGroupElement.style.top = '-'+heightTop;
        if (this.maxHeight !== null) {
          this.divRadioGroupElement.style.maxHeight = this.maxHeight+'px';
          this.divRadioGroupElement.style.overflowY = 'auto';  
        }

        ReactDOM.render(
          <RadioGroupForStylesControl
            mapStyles={this.mapStyles}
            mapStyle={this.mapStyle}
            setLastControlEvent={this.setLastControlEvent}
            map={this.map}
            />,
          this.divRadioGroupElement);

        this.containerElement.appendChild(this.divRadioGroupElement);

        //setting button element's behavior on click event
        this.buttonElement.addEventListener('click', () => {
            //displaying select element
            this.divRadioGroupElement.style.display = 'block';

            let layersControlRadioGroupContainerElement = document.getElementById(
                'custom-layers-control-radio-group-container');
            if (layersControlRadioGroupContainerElement) {
                layersControlRadioGroupContainerElement.style.display = 'none';
            }

            let layersControlCheckboxGroupContainerElement = document.getElementById(
                'custom-layers-control-checkbox-group-container');
            if (layersControlCheckboxGroupContainerElement) {
                layersControlCheckboxGroupContainerElement.style.display = 'none';
            }

            let colorSchemesControlRadioGroupContainerElement = document.getElementById(
                'custom-color-schemes-control-radio-group-container');
            if (colorSchemesControlRadioGroupContainerElement) {
                colorSchemesControlRadioGroupContainerElement.style.display = 'none'
            }
        });

        //setting divRadioGroupElement element's behavior on mouse leave event
        this.divRadioGroupElement.addEventListener('mouseleave', () => {
            //no displaying select element
            this.divRadioGroupElement.style.display = 'none';
        });

        return this.containerElement;
    }

    onRemove(){
        this.containerElement.parentNode?.removeChild(this.containerElement);
        this.map = undefined;
    }

}

export default StylesControlByRadio;


const RadioGroupForStylesControl = (props) => {
  const {
    mapStyles: mapStylesValues,
    mapStyle,
    setLastControlEvent,
    map
  } = props;

  const options = mapStylesValues.map(mapStyleValue => {
    const mapStyle_ = MAPBOX_STYLES.find(style => style.value === mapStyleValue);
    return {label: mapStyle_.label, value: mapStyle_.value}
  });

  const [value, setValue] = useState(mapStyle.value);

  const handleChange = (e) => {
    if (setLastControlEvent !== null) setLastControlEvent('stylesControl');
    const styleValue = e.target.value;
    const newMapStyle = MAPBOX_STYLES.find(style => style.value === styleValue);
    map.setStyle(newMapStyle.url, {diff: true});
    setValue(newMapStyle.value)
  };

  return (
    <Radio.Group
      id={'custom-control-radio-group-id'}
      style={{margin: '5px 5px 5px 5px'}}
      onChange={handleChange}
      value={value}
      options={options}
    />
  )
}
