import axios from "axios";
import {AUTH_ACTION_TYPES} from "../redux/typesAction";


// eslint-disable-next-line import/no-anonymous-default-export
export default (history = null, dispatch, sharedToken) => {

    const baseURL = process.env.REACT_APP_API_ENDPOINT;

    let headers = {
        'Content-Type': 'application/json;charset=UTF-8'
    }

    if (localStorage.token) {
        headers['X-Auth-Token'] = localStorage.token;
    }
    if (sharedToken){
        headers['X-Auth-Token'] = sharedToken
    }

    const axiosInstance = axios.create({
        baseURL: baseURL,
        headers,
        params: {lang: localStorage.language === 'es' ? 'es' : 'en'}
    });

    axiosInstance.interceptors.response.use(
        (response) =>
            new Promise((resolve, reject) => {
                resolve(response);
            }),
        (error) => {
            if (!error.response) {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }

            if (error.response.status === 401 || error.response.status === 403) {
                localStorage.removeItem("token");

                if(dispatch){
                    dispatch({
                        type: AUTH_ACTION_TYPES.LOGIN_FAIL,
                        payload: error.response
                    });
                }

                if (history) {
                    history.push("/login");
                } else {
                    window.location = "/login";
                }
                return Promise.reject(error);
            } else {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
        }
    );
    return axiosInstance;
};



