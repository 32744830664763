import {DATA_SOURCE_CONFIGURATION_ACTION_TYPES} from "../typesAction";


const initialState = {
    dataSourceConfigurations: [],
    current: null,
    loading: false,
    error: null,
    apiForms: [],
    loadingApiForms: false,
    errorApiForms: null
}

const dataSourceConfigurationReducer = (state = initialState, action) => {
    switch (action.type) {
        case DATA_SOURCE_CONFIGURATION_ACTION_TYPES.LOAD_DATA_SOURCE_CONFIGURATION:
            return {
                ...state, loading: true
            }
        case DATA_SOURCE_CONFIGURATION_ACTION_TYPES.CLEAR_LOAD_DATA_SOURCE_CONFIGURATION:
            return {
                ...state, loading: false
            }
        case DATA_SOURCE_CONFIGURATION_ACTION_TYPES.ERROR_DATA_SOURCE_CONFIGURATION:
            return {
                ...state, error: action.payload, loading: false, current: null
            }
        case DATA_SOURCE_CONFIGURATION_ACTION_TYPES.CLEAR_DATA_SOURCE_CONFIGURATION_ERRORS:
            return {
                ...state,
                error: null, loading: false
            }
        case DATA_SOURCE_CONFIGURATION_ACTION_TYPES.LIST_DATA_SOURCE_CONFIGURATION:
            return {
                ...state, dataSourceConfigurations: action.payload, loading: false, current: null
            }
        case DATA_SOURCE_CONFIGURATION_ACTION_TYPES.ADD_DATA_SOURCE_CONFIGURATION:
            return {
                ...state,
                loading: false
            }
        case DATA_SOURCE_CONFIGURATION_ACTION_TYPES.SHOW_DATA_SOURCE_CONFIGURATION:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case DATA_SOURCE_CONFIGURATION_ACTION_TYPES.EDIT_DATA_SOURCE_CONFIGURATION:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case DATA_SOURCE_CONFIGURATION_ACTION_TYPES.DELETE_DATA_SOURCE_CONFIGURATION:
            const updatedDataSourceConfigurations = state.dataSourceConfigurations.dataSourceConfigurations.filter(dataSourceConfiguration => action.payload !== dataSourceConfiguration.id)
            const updatedCount = state.dataSourceConfigurations.count - 1;
            return {
                ...state, dataSourceConfigurations: {...state.dataSourceConfigurations, dataSourceConfigurations: updatedDataSourceConfigurations, count: updatedCount}, loading: false
            }
        case DATA_SOURCE_CONFIGURATION_ACTION_TYPES.LOAD_API_FORMS:
            return {
                ...state, loadingApiForms: true
            }
        case DATA_SOURCE_CONFIGURATION_ACTION_TYPES.CLEAR_LOAD_API_FORMS:
            return {
                ...state, loadingApiForms: false
            }
        case DATA_SOURCE_CONFIGURATION_ACTION_TYPES.ERROR_API_FORMS:
            return {
                ...state, errorApiForms: action.payload, loadingApiForms: false, apiForms: []
            }
        case DATA_SOURCE_CONFIGURATION_ACTION_TYPES.CLEAR_API_FORMS_ERRORS:
            return {
                ...state,
                errorApiForms: null, loadingApiForms: false
            }
        case DATA_SOURCE_CONFIGURATION_ACTION_TYPES.LIST_API_FORMS:
            return {
                ...state, apiForms: action.payload, loadingApiForms: false
            }
        default:
            return state;
    }

};

export default dataSourceConfigurationReducer;