import {Breadcrumb, Layout, message, Card, Button, Space} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
import {connect} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {editReportCode, getReportCode, clearReportError} from "../../redux/report/reportActions";
import {selectError, selectCode, selectLoading} from "../../redux/report/reportSelector";
import './ReportView.css'
import {Link} from "react-router-dom";
import ReactQuill from 'react-quill'

const modules = {
  toolbar: null,
  clipboard: {
    matchVisual: false,
  },
}

const {Content, Footer} = Layout;

const ReportCode = (props) => {

    //local const
    const intl = useIntl();
    const editorReactQuill = useRef(null);

    //local states
    const [value, setValue] = useState('');
    const [reportCodeUpdated, setReportCodeUpdated] = useState(false);
    const [hiddenErrors, setHiddenErrors] = useState(true);
    const [cardLoading, setCardLoading] = useState(true);

    //Handlers
    //---------
    const handleUpdate = () => {
        const formData = new FormData();
        const reportCode = editorReactQuill.current.getEditor().getText();
        formData.append('code', reportCode);
        props.editReportCode(props.history, props.match.params.id, formData, setReportCodeUpdated)
    };

    //Effects
    //-------

    //setting editor spellcheck to false
    useEffect(() => {
        editorReactQuill.current.getEditor().root.setAttribute('spellcheck', false)
    },[]);

    //to load data
    useEffect(() => {
        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearReportError());
            };
            error();
        } else {
            if (!reportCodeUpdated) {
                props.getReportCode(props.history, props.match.params.id).then( data => {
                    if (data) {
                        //checking if new line is at the end of document. if it is not present, then it is added.
                        const code = data.code;
                        const editorDelta = { //inserting a Delta as value to show text with format
                            "ops": [
                                {
                                    "insert": code
                                }
                            ]
                        };
                        setValue(editorDelta)
                    }
                });
            }

            if (reportCodeUpdated && props.code.error_message === null) {
                message.info(intl.formatMessage({ id: 'label.report.reportFile.report-code-updated' }));
                props.history.push('/configuration/reports')
            }
        }
        // eslint-disable-next-line
    }, [props.error, reportCodeUpdated]);

    //showing/hiding errors
    useEffect(() => {
        if (props.code) {
            if (props.code.error_message === null) {
                setHiddenErrors(true)
            } else {
                setHiddenErrors(false)
            }
            setCardLoading(false);
        }
    }, [props.code]);

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.configuration" defaultMessage="Configuration"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.reports" defaultMessage="Reports"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.reports.edit-code" defaultMessage="Edit code"/></span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Card
                    title={intl.formatMessage({id:'label.report.reportFile.code.loading'})}
                    hidden={!cardLoading}
                />
                <Card
                    title={intl.formatMessage({id:"label.report.reportFile"}) + ": "+props.code?.filename }
                    extra={
                        <Link  to={'/configuration/reports'}>
                            <FormattedMessage id="label.return" defaultMessage="Return" />
                        </Link>
                    }
                    hidden={cardLoading}
                >
                    <Card
                        type={"inner"}
                        title={intl.formatMessage({id: 'label.report.code-errors'})}
                        hidden={hiddenErrors}
                    >
                        <label className="error">
                            {props.code?.error_message}
                        </label>
                    </Card>
                    <div
                        id={'div-editor-and-button'}
                        hidden={props.code?.error_message !== null}
                    >
                        <Space direction={"vertical"}>
                            <ReactQuill
                                theme="snow"
                                id={'editor'}
                                value={value}
                                onChange={setValue}
                                spellCheck={false}
                                modules={modules}
                                format={null}
                                style={{width: '100%' }}
                                ref={editorReactQuill}
                            />
                            <Button type="primary" loading={props.loading}
                                    onClick={handleUpdate}
                            >
                                <FormattedMessage id="label.report.reportFile.update.code"
                                                  defaultMessage="Update code"/>
                            </Button>
                        </Space>
                    </div>
                </Card>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    code: selectCode(state)
});

export default connect(mapStateToProps,
    {editReportCode, getReportCode, clearReportError})(ReportCode);