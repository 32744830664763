/*
VA-Team
 */

import './Button.css';

/**
 * Returns a HTML button element and its styles. It must be used inside a Mapbox control class.
 * the button icon. The url to image must be accessible from the Control class definition,
 * where the button is called.
 * @param MapBoxControlName String. Used to define the button class. This class is used in
 * CSS file to set background-image property
 * @returns {HTMLButtonElement}
 * @constructor
 */
export const Button = (MapBoxControlName) => {

    //setting the button element
    const buttonElement = document.createElement('button');
    buttonElement.classList.add('mapbox-ctrl','button');

    //setting the span element to define the button icon
    let spanElement = document.createElement('span');
    spanElement.classList.add('mapbox-ctrl-icon', 'span', MapBoxControlName);

    //change button background color on mouse over
    buttonElement.addEventListener('mouseover', () => {
       buttonElement.style.background = '#eee';
       buttonElement.style.cursor = 'pointer';
    });

    //change button background color on mouse leave
    buttonElement.addEventListener('mouseleave', () => {
        buttonElement.style.background = '#fff';
    });

    //appending span element to button element
    buttonElement.appendChild(spanElement);

    return buttonElement
};