/*
VA-Team
 */
import "./ActiveLayerLabelControl.css";

/**
 * A Mapbox control that shows the name of active layer in map.
 *
 * @param {String} label The label of the layer.
 * @param {String} activeLayer The active layer name.
 */
class ActiveLayerLabelControl {
    label; //short text to show before variable name, for instance: Active Layer
    activeLayerLabel; //active layer variable name or variable alias


    constructor (label, activeLayerLabel) {
        this.activeLayerLabel = activeLayerLabel;
        this.label = label;
    };

    onAdd(map){
        //setting map
        this.map = map;

        //setting container element
        this.containerElement = document.createElement('div');
        this.containerElement.classList.add('mapboxgl-ctrl-activeLayerLabel');

        //label container element
        this.labelContainerElement = document.createElement('p');
        this.labelContainerElement.classList.add('labelContainer');

        //setting activeLayer label element
        this.activeLayerLabelElement = document.createElement('span');
        this.activeLayerLabelElement.classList.add('activeLayerLabelElement');
        this.activeLayerLabelElement.innerText = this.label;

        //setting activeLayer variableAlias element
        this.activeLayerVariableAliasElement = document.createElement('span');
        this.activeLayerVariableAliasElement.classList.add('activeLayerVariableAliasElement');
        this.activeLayerVariableAliasElement.innerText = `: ${this.activeLayerLabel}`;

        this.labelContainerElement.appendChild(this.activeLayerLabelElement);
        this.labelContainerElement.appendChild(this.activeLayerVariableAliasElement);
        this.containerElement.appendChild(this.labelContainerElement);

        //when there is no label defined, do not display the control
        if (this.activeLayerLabel === undefined) {
            this.containerElement.style.display = 'none'
        } else {
            this.containerElement.style.display = 'block'
        }

        return this.containerElement;
    }

    onRemove(){
        this.containerElement.parentNode?.removeChild(this.containerElement);
        this.map = undefined;
    }

    update(activeLayerLabel, label) {
        if (activeLayerLabel === undefined || activeLayerLabel === '') {
            this.containerElement.style.display = 'none'
        } else {
            this.containerElement.style.display = 'block'
        }
        this.activeLayerVariableAliasElement.innerText = `: ${activeLayerLabel}`;

        if (label) {
            this.activeLayerLabelElement.innerText = label;
        }
    }

}

export default ActiveLayerLabelControl;
