import { useIntl} from "react-intl";
import {connect, useDispatch} from "react-redux";
import { useState} from "react";
import Filters from "./Filters";
import {DATEFORMAT, getStatsPlots} from "../../utils/utilData";
import moment from "moment/moment";
import Plot from "react-plotly.js";




const Plots = (props) => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const [formRecordsStats, setFormRecordsStats] = useState([]);
    const [searchLoad, setSearchLoad] = useState(false);

    const onFinish = (values) => {
        setSearchLoad(true)
        let filterSearch = {};
        if (
            "dateRange" in values &&
            values.dateRange !== undefined &&
            values.dateRange !== null
        ) {
            filterSearch.dateStart = values.dateRange[0].format(DATEFORMAT);
            filterSearch.dateEnd = values.dateRange[1].format(DATEFORMAT);
        }
        if (values.regionCode) {
            const regionValues = values.regionCode?.split(';')
            filterSearch.idLevel = regionValues[0]
            filterSearch.regionCode = regionValues[1]
        }
        filterSearch.form = values.form
        dispatch(getStatsPlots(filterSearch)).then(data => {
            if (data) {
                const formData = []
                const formRecords = data['formRecords']
                for (let i = 0; i < data['formRecords'].length; i++) {
                    const seriesValue = {}
                    seriesValue['name'] = formRecords[i]['id']
                    seriesValue['x'] = []
                    seriesValue['y'] = []
                    seriesValue['text'] = []
                    seriesValue['mode'] = 'lines+markers'
                    seriesValue['hovertemplate'] = `%{text}: %{y}<extra></extra>`
                    seriesValue['type'] = props.type
                    seriesValue['line'] = {shape: 'spline'}
                    const seriesData = formRecords[i]['data']
                    for (let j = 0; j < seriesData.length; j++) {
                        seriesValue['x'].push(moment(seriesData[j]['x']).format('MMM-DD'))
                        seriesValue['y'].push(seriesData[j]['y'])
                        seriesValue['text'].push(formRecords[i]['id'])
                    }
                    formData.push(seriesValue)
                }
                setSearchLoad(false)
                setFormRecordsStats(formData)
            }

        })
    }

    return (

        <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
            <Filters onFinish={onFinish} searchLoad={searchLoad}/>

            <Plot style={{width: "100%", height: "100%"}}
                  config={{responsive: true, displaylogo: false}}
                  data={formRecordsStats}
                  layout={{
                      autosize: true,
                      showlegend: false,
                      hovermode: 'x unified',
                      xaxis: {
                          title: {
                              text: intl.formatMessage({id: 'dashboard.dates'})
                          },
                          ticks: 'outside',
                          autorange: true,
                          showgrid: true,
                          automargin: true,
                          autotick: true
                      },
                      yaxis: {
                          title: {
                              text: intl.formatMessage({id: 'dashboard.number-records'})
                          },
                          ticks: 'outside',
                          autorange: true,
                          showgrid: true,
                          automargin: true,
                          autotick: true,
                      }
                  }}
            />
        </div>
    );
}


const mapStateToProps = state => ({});

export default connect(mapStateToProps, {})(Plots);