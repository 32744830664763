import {createSelector} from "reselect";

const selectReportFile = state => state.reportFile;

export const selectLoading = createSelector(
    [selectReportFile],
    (reportFile) => reportFile.loading
);

export const selectError = createSelector(
    [selectReportFile],
    (reportFile) => reportFile.error
);

export const selectReportFiles = createSelector(
    [selectReportFile],
    (reportFile) => reportFile.reportFiles
);

export const selectCurrentReportFile = createSelector(
    [selectReportFile],
    (reportFile) => reportFile.current
);
