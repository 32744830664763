import {Form, Input, Select, message, Breadcrumb, Layout, Card, Button, Tag, Radio} from 'antd';
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Cron} from 'react-js-cron';
import 'react-js-cron/dist/styles.css';
import './SubscriptionForm.css';
import cronstrue from 'cronstrue';
import {
    setLoading,
    clearLoading,
    addSubscription,
    getSubscription,
    editSubscription,
    clearSubscriptionError
} from "../../redux/subscription/subscriptionActions";
import {HomeOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {selectError, selectLoading, selectCurrentSubscription} from "../../redux/subscription/subscriptionSelector";
import {selectSubscriptions} from "../../redux/subscription/subscriptionSelector";
import {selectProjects} from "../../redux/project/projectSelector";
import {listProjects} from "../../redux/project/projectActions";
import {getAlertContactByProject} from "../../utils/utilData";
import {selectReports} from "../../redux/report/reportSelector";
import {listReports} from "../../redux/report/reportActions";

const {Content, Footer} = Layout;


const SubscriptionForm = (props) => {

    const intl = useIntl();
    const dispatch = useDispatch();

    const [subscriptionCreated, setSubscriptionCreated] = useState(false);
    const [projectLoading, setProjectLoading] = useState(false);
    const [initialOpen, setInitialOpen] = useState(true);
    const [edit, setEdit] = useState(props.location.pathname !== '/subscriptions/new');
    const alertContactEmptyList = [{label: intl.formatMessage({id: 'label.all'}), value: 'All'}]
    const [alertContactList, setAlertContactList] = useState(alertContactEmptyList)
    const [frequency, setFrequency] = useState('0 1 * * *')
    const [projectList, setProjectList] = useState([{label: intl.formatMessage({id: 'label.select'}), value: null}])
    const [reportList, setReportList] = useState([{label: intl.formatMessage({id: 'label.select'}), value: null}])

    const [form] = Form.useForm();

    const onFinish = (values) => {
        if (edit) {
            values = {...values, frequency: frequency}
            props.editSubscription(props.history, props.match.params.id, values, setSubscriptionCreated);
        } else {
            values = {...values, frequency: frequency}
            props.addSubscription(props.history, values, setSubscriptionCreated);
        }

    };

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    const onProjectSelected = async (selected) => {
        setProjectLoading(true);
        let pagination = {}
        pagination['current'] = 1;
        pagination['pageSize'] = 2000;
        let sorter = {}
        sorter['field'] = 'name';
        let filter = {}
        filter['project'] = selected;
        if (selected !== null) {
            const alertContactRecords = await dispatch(getAlertContactByProject(props.history, selected, 'subscriptions'))
            const loadedAlertContact = [...alertContactEmptyList];
            alertContactRecords?.map(alertContact => loadedAlertContact.push({
                label: alertContact.name,
                value: alertContact.email
            }))
            setAlertContactList(loadedAlertContact)
        } else {
            setAlertContactList(alertContactEmptyList)
        }
        setProjectLoading(false)
    }


    useEffect(() => {
        if (projectList.length === 1) {
            dispatch(setLoading());
            if (props.project === null || props.project.length === 0) {
                let pagination = {}
                pagination['current'] = 1;
                pagination['pageSize'] = 9999999;
                let sorter = {}
                sorter['field'] = 'name';
                dispatch(listProjects(props.history, pagination, {}, sorter));
            } else {
                const loadedProjects = [...projectList];
                props.project?.projects?.map(project => loadedProjects.push({label: project.name, value: project.id}))
                setProjectList(loadedProjects);
                dispatch(clearLoading());
            }
        }
        if (reportList.length === 1) {
            dispatch(setLoading());
            if (props.report === null || props.report.length === 0 || initialOpen) {
                let pagination = {}
                pagination['current'] = 1;
                pagination['pageSize'] = 9999999;
                let sorter = {}
                sorter['field'] = 'name';
                let filter = {}
                filter['subscription'] = 'yes';
                dispatch(listReports(props.history, pagination, filter, sorter));
                setInitialOpen(false)
            } else {
                const loadedReports = [...reportList];
                props.report?.reports?.map(project => loadedReports.push({label: project.name, value: project.id}))
                setReportList(loadedReports);
                dispatch(clearLoading());

            }
        }
        if (props.error) {
            const error = () => {
                if (typeof props.error == "string") {
                    message.error(props.error).then(props.clearSubscriptionError());
                } else if (props.error.hasOwnProperty('message')) {
                    message.error(props.error.message).then(props.clearFormDataError());
                } else {
                    props.error.errors.map(errorMessage =>
                        (message.error(errorMessage.message).then(props.clearSubscriptionError())));
                }

            };
            error();
        }

        if (edit && !subscriptionCreated && props.error === null) {
            props.getSubscription(props.history, props.match.params.id).then((data) => {
                if (data) {
                    form.setFieldsValue({
                        'name': data.name,
                        'active': data.active,
                        'project': data.project,
                        'report': data.report,
                    });
                    setFrequency(data.frequency)
                    onProjectSelected(data.project).then(() => {
                        form.setFieldsValue(
                            {
                                'subscribers': data.subscribers
                            }
                        )
                    })
                } else {
                    setEdit(false);
                }
            })
        }

        if (subscriptionCreated) {
            if (edit) {
                message.info(intl.formatMessage({id: 'label.subscription-updated'}));
            } else {
                form.resetFields();
                message.info(intl.formatMessage({id: 'label.subscription-created'}));
            }
            setSubscriptionCreated(false);
        }
        // eslint-disable-next-line
    }, [props.error, subscriptionCreated, edit, props.project, props.report])

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="label.contacts" defaultMessage="Contacts"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.subscriptions" defaultMessage="Subscriptions"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>{edit ? (<FormattedMessage
                            id="label.edit" defaultMessage="Edit"/>) : (
                            <FormattedMessage id="label.new" defaultMessage="New"/>)}</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Card type="inner"
                          title={edit ?
                              <FormattedMessage id="label.edit-subscription" defaultMessage="Edit Subscription"/> :
                              <FormattedMessage id="label.create-subscription" defaultMessage="Add Subscription"/>}
                          extra={<Link to='/subscriptions'><FormattedMessage id="label.return"
                                                                             defaultMessage="Return"/></Link>}>
                        <Form layout="horizontal" name="subscription_form" size="large"
                              labelCol={{span: 3,}} wrapperCol={{span: 14,}}
                              initialValues={{active: true}} form={form}
                              onFinish={onFinish} onFinishFailed={onFinishFailed}>
                            <Form.Item
                                name="name"
                                label={intl.formatMessage({id: 'label.name'})}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item name="active" label={intl.formatMessage({id: 'label.active'})}
                                       rules={[{
                                           required: true,
                                           message: intl.formatMessage({id: 'msg.input-required'})
                                       }]}
                            >
                                <Radio.Group>
                                    <Radio value={true}><FormattedMessage id="label.yes" defaultMessage="Yes"/></Radio>
                                    <Radio value={false}><FormattedMessage id="label.no" defaultMessage="No"/></Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item name="project" label={intl.formatMessage({id: 'label.project'})}
                                       rules={[{
                                           required: true,
                                           message: intl.formatMessage({id: 'msg.input-required'})
                                       }]}>
                                <Select options={projectList} onSelect={onProjectSelected}>

                                </Select>
                            </Form.Item>
                            <Form.Item key={"subscribers"} name="subscribers"
                                       label={intl.formatMessage({id: 'label.contacts'})}
                                       rules={[{
                                           required: true, message: intl.formatMessage({id: 'msg.input-required'})
                                       }]}>

                                <Select options={alertContactList} allowClear={true} mode={'multiple'}
                                        loading={projectLoading}>

                                </Select>
                            </Form.Item>
                            <Form.Item name="report" label={intl.formatMessage({id: 'label.report'})}
                                       rules={[{
                                           required: true,
                                           message: intl.formatMessage({id: 'msg.input-required'})
                                       }]}>
                                <Select options={reportList}>

                                </Select>
                            </Form.Item>
                            <Form.Item key={"frequency"} label={intl.formatMessage({id: 'label.frequency'})}
                            >

                                <Cron value={frequency} setValue={setFrequency} humanizeLabels={true}
                                      humanizeValue={false} mode={'single'} clockFormat={'24-hour-clock'}
                                      clearButton={true} defaultPeriod={'day'} allowEmpty={'for-default-value'}
                                />
                                <Tag>{intl.formatMessage({id: 'label.description'})}: {cronstrue.toString(frequency)}</Tag>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={props.loading || projectLoading}>
                                    {edit ? (<FormattedMessage id="label.update" defaultMessage="Update"/>) : (
                                        <FormattedMessage id="label.submit" defaultMessage="Submit"/>)}

                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    subscription: selectCurrentSubscription(state),
    subscriptions: selectSubscriptions(state),
    project: selectProjects(state),
    report: selectReports(state)
});

export default connect(mapStateToProps, {
    addSubscription,
    getSubscription,
    editSubscription,
    clearSubscriptionError
})(SubscriptionForm);