import {JOB_ACTION_TYPES} from "../typesAction";


const initialState = {
    jobs: [],
    loading: false,
    error: null
}

const jobReducer = (state = initialState, action) => {
    switch (action.type) {
        case JOB_ACTION_TYPES.LOAD_JOB:
            return {
                ...state, loading: true
            }
        case JOB_ACTION_TYPES.CLEAR_LOAD_JOB:
            return {
                ...state, loading: false
            }
        case JOB_ACTION_TYPES.ERROR_JOB:
            return {
                ...state, error: action.payload, loading: false, current: null
            }
        case JOB_ACTION_TYPES.CLEAR_JOB_ERRORS:
            return {
                ...state,
                error: null, loading: false
            }
        case JOB_ACTION_TYPES.SHOW_JOB:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case JOB_ACTION_TYPES.LIST_JOB:
            return {
                ...state, jobs: action.payload, loading: false, current: null
            }
        default:
            return state;
    }

};

export default jobReducer;