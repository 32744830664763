import {Form, Input, Select, message, Breadcrumb, Layout, Card, Button, Radio} from 'antd';
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    setLoading,
    clearLoading,
    addAlertContact,
    getAlertContact,
    editAlertContact,
    clearAlertContactError
} from "../../redux/alertContact/alertContactActions";
import {HomeOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {selectError, selectLoading, selectCurrentAlertContact} from "../../redux/alertContact/alertContactSelector";
import {listProjects} from "../../redux/project/projectActions";
import {selectProjects} from "../../redux/project/projectSelector";

const {Content, Footer} = Layout;


const AlertContactForm = (props) => {

    const intl = useIntl();
    const dispatch = useDispatch();

    const [alertContactCreated, setAlertContactCreated] = useState(false);
    const [edit, setEdit] = useState(props.location.pathname !== '/contacts/new');

    const [form] = Form.useForm();

    const onFinish = (values) => {
        if (edit) {
            props.editAlertContact(props.history, props.match.params.id, values, setAlertContactCreated);
        } else {
            props.addAlertContact(props.history, values, setAlertContactCreated);
        }

    };

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    const [projectList, setProjectList] = useState([{label: intl.formatMessage({id: 'label.select'}), value: null}])

    useEffect(() => {
        if (projectList.length === 1) {
            dispatch(setLoading());
            if (props.project === null || props.project.length === 0) {
                let pagination = {}
                pagination['current'] = 1;
                pagination['pageSize'] = 9999999;
                let sorter = {}
                sorter['field'] = 'name';
                dispatch(listProjects(props.history, pagination, {}, sorter));
            } else {
                const loadedProjects = [...projectList];
                props.project?.projects?.map(project => loadedProjects.push({label: project.name, value: project.id}))
                setProjectList(loadedProjects);
                dispatch(clearLoading());
            }
        }
        if (props.error) {
            const error = () => {
                if (typeof props.error == "string") {
                    message.error(props.error).then(props.clearAlertContactError());
                } else if (props.error.hasOwnProperty('message')) {
                    message.error(props.error.message).then(props.clearFormDataError());
                } else {
                    props.error.errors.map(errorMessage =>
                        (message.error(errorMessage.message).then(props.clearAlertContactError())));
                }

            };
            error();
        }

        if (edit && !alertContactCreated && props.error === null) {
            props.getAlertContact(props.history, props.match.params.id).then((data) => {
                if (data) {
                    form.setFieldsValue({
                        'name': data.name,
                        'project': data.project,
                        'email': data.email,
                        'phone': data.phone,
                        'notifications': data.notifications,
                        'subscriptions': data.subscriptions,
                    });
                } else {
                    setEdit(false);
                }
            })
        }

        if (alertContactCreated) {
            if (edit) {
                message.info(intl.formatMessage({id: 'label.contact-updated'}));
            } else {
                form.resetFields();
                message.info(intl.formatMessage({id: 'label.contact-created'}));
            }
            setAlertContactCreated(false);
        }
        // eslint-disable-next-line
    }, [props.error, alertContactCreated, edit, props.project])

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="label.contact" defaultMessage="Contact"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>{edit ? (<FormattedMessage
                            id="label.edit" defaultMessage="Edit"/>) : (
                            <FormattedMessage id="label.new" defaultMessage="New"/>)}</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Card type="inner"
                          title={edit ? <FormattedMessage id="label.edit-contact" defaultMessage="Edit Contact"/> :
                              <FormattedMessage id="label.create-contact" defaultMessage="Add Contact"/>}
                          extra={<Link to='/contacts'><FormattedMessage id="label.return"
                                                                     defaultMessage="Return"/></Link>}>
                        <Form layout="horizontal" name="alertContact_form" size="large"
                              labelCol={{span: 3,}} wrapperCol={{span: 14,}}
                              initialValues={{active: true}} form={form}
                              onFinish={onFinish} onFinishFailed={onFinishFailed}>
                            <Form.Item name="project" label={intl.formatMessage({id: 'label.project'})}
                                       rules={[{
                                           required: true,
                                           message: intl.formatMessage({id: 'msg.input-required'})
                                       }]}>
                                <Select options={projectList}>

                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="name"
                                label={intl.formatMessage({id: 'label.name'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}

                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label={intl.formatMessage({id: 'label.email'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}

                            >
                                <Input type='email'/>
                            </Form.Item>

                            <Form.Item
                                name="phone"
                                label={intl.formatMessage({id: 'label.phone'})}
                            >
                                <Input type='tel'/>
                            </Form.Item>

                            <Form.Item name="notifications" label={intl.formatMessage({id: 'label.notifications'})}>
                                <Radio.Group>
                                    <Radio value={true}><FormattedMessage id="label.yes" defaultMessage="Yes"/></Radio>
                                    <Radio value={false}><FormattedMessage id="label.no" defaultMessage="No"/></Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item name="subscriptions" label={intl.formatMessage({id: 'label.subscriptions'})}>
                                <Radio.Group>
                                    <Radio value={true}><FormattedMessage id="label.yes" defaultMessage="Yes"/></Radio>
                                    <Radio value={false}><FormattedMessage id="label.no" defaultMessage="No"/></Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={props.loading}>
                                    {edit ? (<FormattedMessage id="label.update" defaultMessage="Update"/>) : (
                                        <FormattedMessage id="label.submit" defaultMessage="Submit"/>)}

                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    alertContact: selectCurrentAlertContact(state),
    project: selectProjects(state)
});

export default connect(mapStateToProps, {addAlertContact, getAlertContact, editAlertContact, clearAlertContactError})(AlertContactForm);
